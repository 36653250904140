import React, { useEffect } from "react";

import LegalPrivacyView from "../views/LegalPrivacyView";
import { useNavigate } from "react-router-dom";

const LegalPrivacyScreen = (props) => {

  const navigate = useNavigate();
  return <LegalPrivacyView
    navigate={navigate}
  />;
};

export default LegalPrivacyScreen;
