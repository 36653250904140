import React from "react";
import { SafeAreaView, ScrollView, Text } from "react-native";


import { PRIVACY } from "../../utils/constants";

import style from "./style";

const LegalPrivacyView = (props) => {
  return (
    <SafeAreaView style={style.screen}>
      <ScrollView>
        <Text style={style.text}>{PRIVACY}</Text>
      </ScrollView>
    </SafeAreaView>
  );
};

LegalPrivacyView.propTypes = {};

export default LegalPrivacyView;
