import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const CreateManageRow = (props) => (
    <TouchableOpacity
        onPress={() => props.onClick()}
        style={{...style.container, backgroundColor: props.backgroundColor}}
    >
        <Text style={{...style.text, color: props.textColor}}>{props.text}</Text>
    </TouchableOpacity>
);

CreateManageRow.propTypes = {
    refCode: PropTypes.string.isRequired,
};

export default React.memo(CreateManageRow);
