import React from "react";
import { Image } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const getStyle = (size, ratio, imageStyle) => ({
    ...style.image,
    ...imageStyle,
    height: size,
    aspectRatio: ratio,
});

const RatioImage = (props) => (
    <Image
        source={props.image}
        style={getStyle(props.size, props.ratio, props.style)}
    />
);

RatioImage.defaultProps = {
    style: {},
};

RatioImage.propTypes = {
    image: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
    style: PropTypes.object,
};

export default React.memo(RatioImage);
