import React from "react";
import { Modal, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const PopupModal = (props) => (
    <Modal transparent={true} visible={props.displayState}>
        <TouchableOpacity
            style={[style.overlay, props.theme === "dark" && style.overlayDark]}
            onPress={() => props.setDisplayState(false)}
        >
            <TouchableOpacity
                style={[style.modal, props.theme === "dark" && style.modalDark]}
                activeOpacity={1}
            >
                {props.children}
            </TouchableOpacity>
        </TouchableOpacity>
    </Modal>
);

PopupModal.defaultProps = {
    displayState: false,
};

PopupModal.propTypes = {
    displayState: PropTypes.bool.isRequired,
    setDisplayState: PropTypes.func.isRequired,
    theme: PropTypes.string.isRequired,
};

export default React.memo(PopupModal);