import React from "react";
import PropTypes from "prop-types";

import style from "./style";

import { DefaultEvent } from "../../utils/images";
import { View } from "react-native-web";

const HeaderImage = (props) => (
    <View style={style.container}>
        <img
            src={props.image ? props.image : DefaultEvent}
            style={style.image}
        />
    </View>
);

HeaderImage.defaultProps = {
    image: null,
};

HeaderImage.propTypes = {
    image: PropTypes.string,
};

export default React.memo(HeaderImage);
