import text from "../../../../styles/text";
import { steel } from "../../../../utils/color";
import { white } from "../../../../utils/color";
import { Dimensions } from "react-native";

export default {
    body: {
        backgroundColor: "black",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    wrapper: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
    },
    profileImg: {
        marginRight: 12,
        height: 72,
        width: 72,
        borderRadius: 36,
    },
    subtitle: {
        ...text.subtitleBold,
        color: white,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginTop: 20,
    },
    emoji: {
        fontSize: 32,
        marginBottom: 12,
        marginLeft: 10,
        marginTop: 10,
    },
    flatlist: {
        marginLeft: 15,
        marginBottom: 0,
        alignSelf: "center",
    },
    flatlistContainer: {
        maxHeight: 100, // Adjust as needed
        width: '100%',
        marginLeft: 20,
        marginRight: 30,
        marginBottom: 10,
        marginTop: 5,
        alignSelf: "center",
        overflow: 'hidden',
        flexDirection: 'row',
    },
    flatlistContent: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    host: {
        height: 75,
        width: 75,
        borderRadius: 75 / 2,
        borderWidth: 2,
        borderColor: white,
        marginRight: 2, // Add some spacing between items
    },
    icon: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 30,
        height: 30,
    },
};