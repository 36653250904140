import React, { useEffect, useState } from "react";
import { View, SafeAreaView, Text, Alert } from "react-native";
import PropTypes from "prop-types";

import { MenuItem, Select } from "@mui/material";
import NavHeader from "../../../../../components/custom/NavHeader";
import CircleButton from "../../../../../components/buttons/CircleButton";
import NewDateTimeField from "../../../../../components/fields/NewDateTimeField";


import { processDate, processEndDate, processEnd, processStart } from "../../../../../utils/time";

import { Check } from "../../../../../utils/images";
import { snow, charcoal, darkThemeBackground } from "../../../../../utils/color";
import Swal from "sweetalert2";

import style from "./style";

const toggleFocus = (focused, setFocused, type) => {
  if (focused === type) {
    setFocused("");
  } else {
    setFocused(type);
  }
};

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date) && date > new Date();
}

const EventTimeView = (props) => {
  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);
  const [recurringInterval, setRecurringInterval] = useState(props.recurringInterval);
  const [focused, setFocused] = useState("");

  useEffect(() => {
    if ((focused === "DATE" || focused === "DATE END") && !start) {
      const ms = 1000 * 60 * 15;
      const date = new Date();
      setStart(new Date(Math.ceil(date.getTime() / ms) * ms));
      if (!end || end - new Date() <= 0)
        setEnd(new Date(Math.ceil(new Date(new Date().getTime() + 3600 * 1000) / ms) * ms));
    }
    if (focused === "START" && !start) {
      const ms = 1000 * 60 * 15;
      const date = new Date();
      setStart(new Date(Math.ceil(date.getTime() / ms) * ms));
      if (!end) {
        setEnd(new Date(Math.ceil(new Date(new Date().getTime() + 3600 * 1000) / ms) * ms));
      }
    }
    if (focused === "END" && !end) {
      const ms = 1000 * 60 * 15;
      const date = new Date();
      props.setEnd(new Date(Math.ceil(date.getTime() / ms) * ms));
    }
  }, [focused]);

    // if event is over 6 days but set to recurring, shorten event
    useEffect(() => {
      if (recurringInterval === "Weekly") {
        const diffTime = end - start;
        const differenceInDays = diffTime / (1000 * 3600 * 24);
  
        if (differenceInDays > 6) {
          const newEnd = new Date(start);
          newEnd.setDate(newEnd.getDate() + 6);
          setEnd(newEnd);
  
          Swal.fire({
            icon: 'error',
            title: 'Multi-Day Recurring Events',
            text: 'At this time, Avenu does not facilitate multi-day events longer than 6 days. If you would like week-long or longer recurring events, please create multiple recurring events. Thank you!',
          })
        }
      }
    }, [recurringInterval, start, end]);
  

  return (
      <SafeAreaView style={style.screen}>
         <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
        <View style={style.container}>
          <View style={style.wrapper}> 
          <Text style={style.header}>When is your event?</Text>
          <View>
          <View style={style.row}>
            <View>
            <NewDateTimeField
              value={start}
              placeholder="Start Date"
              onChangeValue={(date) => processDate(date, start, end, setStart, setEnd)}
              focused={focused === "DATE"}
              setFocused={() => toggleFocus(focused, setFocused, "DATE")}
              mode="date"
              minDate={new Date()}
            />
            <NewDateTimeField
              value={end}
              placeholder="End Date"
              onChangeValue={(date) => processEndDate(date, start, end, setStart, setEnd, recurringInterval)}
              focused={focused === "DATE END"}
              setFocused={() => toggleFocus(focused, setFocused, "DATE END")}
              mode="date"
              minDate={new Date()}
            />   
            </View>
          </View>
          <View style={style.row}>
            <NewDateTimeField
              value={start}
              placeholder="Start time"
              onChangeValue={(start) => processStart(start, end, setStart, setEnd)}
              focused={focused === "START"}
              setFocused={() => toggleFocus(focused, setFocused, "START")}
              mode="time"
            />
            <View style={style.pad} />
              <NewDateTimeField
                value={end}
                placeholder="End time"
                onChangeValue={(end) => processEnd(start, end, setEnd)}
                focused={focused === "END"}
                setFocused={() => toggleFocus(focused, setFocused, "END")}
                mode="time"
              />
          </View>
          <Select 
            style={style.select}
            sx={{
              color: "white",
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: "white !important",
              },
            }}
            inputProps={{
              MenuProps: {
                  MenuListProps: {
                      sx: {
                          backgroundColor: darkThemeBackground,
                      }
                  }
              }
          }}
            value={recurringInterval}
            onChange={(event) => setRecurringInterval(event.target.value)}
          >
            <MenuItem 
              style={style.item}
              value="Not recurring"
            >
              Not Recurring
            </MenuItem>
            <MenuItem 
              style={style.item}
              value="Weekly"
              >
                Weekly
            </MenuItem>
          </Select>
          </View>
          </View>
          <View style={style.spacer} />
          <View style={style.footer}>
            <CircleButton
              icon={Check}
              onPress={() => {
                props.setStart(start);
                props.setEnd(end);
                props.setRecurringInterval(recurringInterval)
                props.navigate("/event/create")
              }}
              disabled={!isValidDate(start) || !isValidDate(end)}
            />
          </View>
        </View>
      </SafeAreaView>
  );
};

EventTimeView.defaultProps = {
  start: null,
  end: null,
};

EventTimeView.propTypes = {
  start: PropTypes.object,
  end: PropTypes.object,
  setDetails: PropTypes.func.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default EventTimeView;
