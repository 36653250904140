import React from "react";
import PropTypes from "prop-types";

import style from "./style";
import { TouchableOpacity, View, Image, Text } from "react-native";
import IconButton from "../../../../components/buttons/IconButton";
import { CopyIcon, MapMarker } from "../../../../utils/icons";
import GoogleMapReact from 'google-map-react'

import { MAPS_API_KEY } from "../../../../googleMapsConfig";

const LocationRow = (props) => (
    <TouchableOpacity 
    onPress={() => navigator.clipboard.writeText(`${props.address}`)}
    style={style.container}
    >
        <View style={style.locationIcon}>
            <Text 
            adjustsFontSizeToFit
            style={style.body}
            >
                {props.address}
            </Text>
            <IconButton
                style={style.item}
                icon={CopyIcon}
                theme={"dark"}
                size={25}
                onPress={() => navigator.clipboard.writeText(`${props.address}`)}
            />
        </View>
        <View style={style.map}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: MAPS_API_KEY }}
                defaultCenter={{lat: props.coordinates.latitude, lng: props.coordinates.longitude}}
                defaultZoom={12}
            >
                <Image 
                style={{width: 32, height: 32}}
                source={MapMarker}
                lat={props.coordinates.latitude}
                lng={props.coordinates.longitude}
                />
            </GoogleMapReact>
        </View>
    </TouchableOpacity>
);

LocationRow.propTypes = {
    address: PropTypes.string.isRequired,
};

export default React.memo(LocationRow);
