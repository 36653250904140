import { StyleSheet } from "react-native";

import style from "../../../../styles/viewStyles";
import { darkThemeBackground } from "../../../../utils/color";

export default StyleSheet.create({
  container: {
    width: "100%",
    marginTop: 16,
  },

  screen: {
    ...style.screen,
    backgroundColor: darkThemeBackground
  },
});
