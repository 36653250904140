import React from "react";
import {
    View,
    FlatList,
    Text,
    TouchableOpacity,
    Image,
} from "react-native-web";
import PropTypes from "prop-types";

import { DefaultProfileImage, BlueCheckmark } from "../../utils/images";

import style from "./style";

const renderItem = (item) => {
    let user = item.item;
    return (
        <TouchableOpacity
            onPress={() =>
                window.location.replace(
                    "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                )
            }
            style={style.host}
        >
            <img
                key={user.userPhone}
                src={
                    user.userMedia.length !== 0
                        ? user.userMedia[0]
                        : DefaultProfileImage
                }
                style={style.profileImg}
            />
            {user.userVerified ? (
                <Image style={style.icon} source={BlueCheckmark} />
            ) : null}
        </TouchableOpacity>
    );
};

const HostsRow = (props) => (
    <View style={style.body}>
        <View style={style.wrapper}>
            <img
                src={require("../../../../assets/icons/icon-host.png")}
                style={{
                    ...style.image,
                    width: "35px",
                    height: "35px",
                    marginTop: "15px",
                    marginLeft: "15px",
                }}
            />
            <Text style={style.subtitle}>Hosts</Text>
        </View>
        <View style={style.flatlistContainer}>
            <FlatList
                style={style.flatlist}
                contentContainerStyle={style.flatlistContent}
                horizontal={true}
                data={props.eventHostsUsers}
                renderItem={(item) => renderItem(item)}
                keyExtractor={(item, index) => index.toString()}
                showsHorizontalScrollIndicator={false} // Add this line to hide the scroll bar
            />
        </View>
    </View>
);

export default React.memo(HostsRow);