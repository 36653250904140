import React, { useEffect, useRef } from "react";
import {
    Dimensions,
    TouchableWithoutFeedback,
    View,
    Text,
    Image,
    Animated,
    Easing,
} from "react-native";
import PropTypes from "prop-types";

import { QRCode } from "react-qrcode-logo";

import { AvenuArrowReverse } from "../../utils/images";
import { DefaultProfileImage } from "../../../../utils/images";

import style from "./style";
import { color, purple, brightBlue } from "../../../../utils/color";

const size = Math.min(500, Dimensions.get("window").width * 0.8);

const TicketModal = (props) => {
    const colorAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(colorAnim, {
                    toValue: 3,
                    duration: 2750,
                    easing: Easing.linear,
                    useNativeDriver: false,
                }),
            ])
        ).start();
    }, [colorAnim]);

    const iconColor = colorAnim.interpolate({
        inputRange: [0, 1, 2, 3],
        outputRange: [color, brightBlue, purple, color],
    });

    return (
        <TouchableWithoutFeedback onPress={props.onDismiss}>
            <View style={style.modal}>
                <View style={style.container}>
                    <View style={style.qrContainer}>
                        <QRCode
                            size={size}
                            value={JSON.stringify(props.ticketData)}
                            logoImage={AvenuArrowReverse}
                            logoWidth={48}
                            bgColor="white"
                            logoOpacity={1}
                            removeQrCodeBehindLogo={true}
                        />
                        <View style={style.iconWrapper}>
                            <Animated.Image
                                source={AvenuArrowReverse}
                                style={[
                                    style.icon,
                                    { backgroundColor: iconColor },
                                ]}
                            />
                        </View>
                    </View>
                    <View style={style.user}>
                        <img
                            key={props.user.userPhone}
                            src={
                                props.user.userMedia.length !== 0
                                    ? props.user.userMedia[0]
                                    : DefaultProfileImage
                            }
                            style={style.profileImg}
                        />
                        <Text style={style.userText}>
                            {props.user.userFirst} {props.user.userLast}
                        </Text>
                    </View>
                    <Text style={style.details}>
                        {props.ticketQuantity}x {props.ticketType}
                    </Text>
                    <Text style={style.details}>{props.ticketTitle}</Text>
                    <Text style={style.details}>{props.eventRefCode}</Text>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};

TicketModal.propTypes = {
    ticketData: PropTypes.object.isRequired,
    onDismiss: PropTypes.func.isRequired,
};

export default React.memo(TicketModal);
