import { color } from "../../../../utils/color";
import { Dimensions } from "react-native";

const { width } = Dimensions.get("window");

export default {
    icon: {
        paddingHorizontal: 12,
    },
    row: {
        position: width < 768 ? "fixed" : "relative",
        left: width < 768 ? 12 : 0,
        right: width < 768 ? 12 : 0,
        bottom: width < 768 ? 10 : 0,
        flexDirection: "row",
        justifyContent: "space-between",
    },

    ticketButton: {
        paddingHorizontal: 12,
        paddingVertical: 16,
        backgroundColor: color,
        borderRadius: "12px",
        color: "white",
        paddingRight: "12px",
        paddingLeft: "12px",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flex: 0.48,
        borderWidth: 2,
        borderColor: "white",
    },

    text: {
        fontWeight: 700,
        fontSize: "14px",
    },

    downloadbutton: {
        flexDirection: "row",
        justifyContent: "right",
        flex:
            width < 500 ? 0.42 : width < 768 ? 0.3 : width < 1000 ? 0.29 : 0.23,
    },
    image: {
        width: "90%",
        height: "90%",
    },
};
