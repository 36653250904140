import React, {useContext, useEffect} from "react";
import EventCreateView from "../views/EventCreateView"
import { EventContext } from "../../EventContext";
import { CreateEventContext } from "../CreateEventContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/AuthContext";

const EventCreateScreen = (props) => {
    const { currEventId } = useContext(EventContext);
    const {
        eventId,
        eventRef,
        recurringEventId, 
        media, 
        setMedia, 
        name, 
        description, 
        start, end, 
        recurringInterval,
        address, coordinates, 
        personnel, artists, ticketScanners,
        tickets, link,
        eventOtherLinks,
        invitedGuests,
        uiCompleted,
        privacy, onChangePrivacy,
        canInvite, setCanInvite,
        hideGuests, setHideGuests,
        hideHosts, setHideHosts,
        announce, onChangeAnnounce,
        gradientIndex, setGradientIndex,
        createEvent,
        addEventCodeToUser,
        clear,
        setEvent,
        editEventSet,
        updateEvent
    } = useContext(CreateEventContext);

    const { setCurrEventId, readEvent, eventHostsUsers, eventArtistsUsers, eventTicketScanners, eventInvitedUsers } = useContext(EventContext);

    const { user, userAuth, readUserIfPresent } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    let editEvent = null;
    if (location.state) {
         editEvent  = location.state.editEvent;
    }
    
    useEffect(() => {
        if (editEvent && !editEventSet) {
            setEvent(editEvent, eventHostsUsers, eventArtistsUsers, eventInvitedUsers, eventTicketScanners,);
        }
    }, [editEvent])

    useEffect(() => {
        if (userAuth && !user) {
            console.log("fired EventCreateScreen");
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth])

    // if user data is not fetched, do not display 
    if (!user) {
        return <></>
    }

    return (
    <EventCreateView
        eventId={eventId}
        eventRef={eventRef}
        recurringEventId={recurringEventId}
        user={user}
        userId={userAuth.user.uid}
        currEventId={currEventId}
        media={media}
        setMedia={setMedia}
        name={name}
        description={description}
        start={start}
        end={end}
        recurringInterval={recurringInterval}
        address={address}
        coordinates={coordinates}
        personnel={personnel}
        artists={artists}
        ticketScanners={ticketScanners}
        tickets={tickets}
        link={link}
        eventOtherLinks={eventOtherLinks}
        invitedGuests={invitedGuests}
        uiCompleted={uiCompleted}
        privacy={privacy}
        onChangePrivacy={onChangePrivacy}
        canInvite={canInvite}
        setCanInvite={setCanInvite}
        hideGuests={hideGuests}
        setHideGuests={setHideGuests}
        hideHosts={hideHosts}
        setHideHosts={setHideHosts}
        announce={announce}
        onChangeAnnounce={onChangeAnnounce}
        gradientIndex={gradientIndex}
        setGradientIndex={setGradientIndex}
        createEvent={createEvent}
        addEventCodeToUser={addEventCodeToUser}
        setCurrEventId={setCurrEventId}
        clear={clear}
        navigate={navigate}
        location={location}
        original={editEvent}
        updateEvent={updateEvent}
        readEvent={readEvent}
    />
    );
}

export default EventCreateScreen;