import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import { black, white } from "../../../../utils/color";

export default StyleSheet.create({
    body: {
        backgroundColor: "black",
        borderRadius: 20,
        overflow: "hidden",
        width: "100%",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    container: {
        justifyContent: "center",
        // alignItems: 'center',
        flex: 1,
    },
    itemContainer: {
        alignItems: "center",
        borderRadius: 12,
        marginBottom: 12,
    },
    scrollContainer: {
        paddingHorizontal: 4,
        marginTop: 0,
        flexDirection: "row",
        alignContent: "flex-end",
    },
    wrapper: {
        flexDirection: "row",
        marginBottom: 10,
        alignItems: "center",
    },
    textWrapper: {
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        marginTop: 15,
    },
    subtitle: {
        ...text.subtitleBold,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 5,
        color: white,
    },
    emoji: {
        fontSize: 24,
        marginBottom: 12,
        marginLeft: 10,
    },
    pill: {
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        borderRadius: 12,
        padding: 5,
        maxWidth: 200,
        bottom: 2,
        left: 8,
        zIndex: 1,
    },
    linkCaption: {
        color: "white",
        fontSize: 12,
    },
    imageContainer: {
        position: "relative",
        width: 120,
        height: 120,
        marginHorizontal: 4,
    },
    linkPic: {
        marginHorizontal: 4,
        width: 120,
        height: 120,
        borderRadius: 12,
        backgroundColor: "grey",
    },
    iconStyle: {
        width: 24,
        height: 24,
    },
});
