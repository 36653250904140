import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import views from "../../../../styles/views";
import { darkThemeBackground, white, sky } from "../../../../utils/color";

export default StyleSheet.create({
  cardPayButton: {
    backgroundColor: sky,
    borderRadius: 4,
    width: "100%",
    alignSelf: "center",
    marginBottom: 40,
    marginTop: 10,
  },
  cardPayButtonText: {
    fontSize: 20,
    alignSelf: "center",
    margin: 8,
    color: darkThemeBackground,
  },
  cardField: { backgroundColor: "white", borderRadius: 4, padding: 10 },
}); 
