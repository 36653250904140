import text from "../../../../styles/text";
import { black, steel, color, snow, white } from "../../../../utils/color";

export default {
    container: {
        backgroundColor: black,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        flexDirection: "row",
        width: "100%",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    wrapper: {
        width: "60%",
    },
    body: {
        ...text.body,
        color: steel,
        marginLeft: 25,
        fontSize: 21,
        marginBottom: 12,
        fontWeight: "300",
    },
    title: {
        color: snow,
        fontWeight: "550",
        fontSize: 30,
        letterSpacing: 0.6,
        marginBottom: 11,
        marginLeft: 25,
        marginTop: 16,
    },
    left: {
        flexDirection: "column",
        width: "36%",
        alignItems: "flex-end",
    },
    month: {
        color: white,
        fontSize: 22,
        fontWeight: "300",
        marginBottom: 10,
    },
    date: {
        color: white,
        fontSize: 40,
        fontWeight: "300",
        marginTop: 15,
        marginBottom: 2,
    },
};
