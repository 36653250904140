import { darkThemeBackground } from "../../../../../utils/color";
import { header } from "../../../../../utils/font";
import { color } from "../../../../../utils/color";
import { Dimensions } from "react-native";

export default {
    container: {
        background: darkThemeBackground,
        color: "white",
        height: "100%",
    },

    line: {
        width: "100%",
        height: 1,
        backgroundColor: "gray",
        alignSelf: "flex-start",
    },

    body: {
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "flex-start",
        flexWrap: "wrap",
        
        marginTop: 20,
    },

    header: {
        color: "white",
        fontWeight: "bold",
        fontSize: 24,
        marginTop: 40,
        alignSelf: "center",
    },

    card: {
        justifyContent: "center"
    },
    
    box: {
        backgroundColor: darkThemeBackground,
        borderRadius: 20,
    },

    buttonStyle: {
        position: "fixed",
        width: "50%",
        left: "25%",
        bottom: 10,
      },

    modal: {
        width: "100%",
        height: "100%",
    },

    gradientContainer: {
        width: "97%",
        alignSelf: "center",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "10px",
        paddingTop: 20,
        paddingLeft: 20,
        paddingBottom: 20,
      },

    gradientImage: {
        width: "calc(3rem + 6vw)",
        height: "calc(3rem + 6vw)",
      },

    gradientBox: {
        backgroundColor: darkThemeBackground,
        position: "absolute",
        width: "70%",
        top: "30%",
        left: "15%",
        borderRadius: 20,
    },

    camera: {
        width: "95%",
        left: "2.5%",
        top: "5%",
        overflow: "hidden",
        borderRadius: 20,
    },

    capture: {
        width: "80px",
        height: "80px",
        backgroundColor: "white",
        borderRadius: "40px",
        marginTop: "8px",
        left: "47%",
        alignItems: "center",
        justifyContent: "center",
    },

    select: {
        width: "80px",
        height: "80px",
        backgroundColor: "white",
        borderRadius: "40px",
        marginTop: "8px",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 5,
    },

    tooltipButton: {
        paddingHorizontal: 8,
        paddingVertical: 6,
      },
};
