import { StyleSheet } from "react-native";

import text from "../../../../styles/text";


export default StyleSheet.create({
    header: {
        ...text.header,
        marginBottom: 10,
        textAlign: "center",
    },

    statusColumn: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minWidth: 100,
    },

    collection: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
    },
    text: {
        ...text.body,
        textAlign: "center",
    },
});