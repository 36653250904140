import React from "react";
import { View, Text } from "react-native";
import PropTypes from "prop-types";
import style from "./style";

import { charcoal, white, black } from "../../../../utils/color";

import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";

const PrivateEventTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>Private Event</Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      Private events are only visible to guests & contacts invited in-app, with event links, or with
      the event code. Non-private events are visible to all friends of the cohosts. {"\n\n"}
      For verified users, non-private events are public to all nearby Avenu users.
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

PrivateEventTooltip.defaultProps = {};

PrivateEventTooltip.propTypes = {
  theme: PropTypes.string,
};

export default React.memo(PrivateEventTooltip);
