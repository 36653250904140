import React, { useEffect, useContext, useState } from "react";
import { View, Linking, Text, Image } from "react-native";
import { Modal, Box } from "@mui/material";
import LinearGradient from "react-native-web-linear-gradient";

import HeaderImage from "../../components/HeaderImage";
import TitleRow from "../../components/TitleRow";
import DescriptionRow from "../../components/DescriptionRow";
import LocationRow from "../../components/LocationRow";
import TaggedPhotoPreview from "../../components/TaggedPhotoPreview";
import PurchaseRow from "../../components/PurchaseRow";
import NoTicketRow from "../../components/NoTicketRow";
import LinksRow from "../../components/LinksRow";
import HostsRow from "../../components/HostsRow";
import ArtistsRow from "../../components/ArtistsRow";
import GuestListRow from "../../components/GuestListRow";
import EventRefRow from "../../components/EventRefRow";
import CreateManageRow from "../../components/CreateManageRow";
import DiscoverEventsRow from "../../components/DiscoverEventsRow";
import NavHeader from "../../../../components/custom/NavHeader";
import { isMoreThan24HoursApart } from "../../../../utils/time";
import IconButton from "../../../../components/buttons/IconButton";
import { Cancel } from "../../../../utils/icons";
import { AppStoreDownload } from "../../utils/images";

import { Dimensions, TouchableOpacity } from "react-native-web";

import style from "./style";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../EventContext";
import { AuthContext } from "../../../auth/AuthContext";
import { UiContext } from "../../../../uiContext";
import { PaymentsContext } from "../../../payments/PaymentsContext";
import ExternalLinkRow from "../../components/ExternalLinkRow";
import {
    black,
    charcoal,
    color,
    darkThemeBackground,
    steel,
} from "../../../../utils/color";

const { width } = Dimensions.get("window");

function iOS() {
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}

const checkIfAllTicketLimitReached = (event) => {
    let eventTickets = event["eventTicketTypes"];
    let allLimitReached = true;
    eventTickets.forEach((eventTicket) => {
        if (
            !eventTicket["ticketLimit"] ||
            !eventTicket["ticketsPurchased"] ||
            (eventTicket["ticketsPurchased"] &&
                eventTicket["ticketsPurchased"] < eventTicket["ticketLimit"])
        ) {
            allLimitReached = false;
        }
    });

    return allLimitReached;
};

const Event = () => {
    const [forceReRender, setForceReRender] = useState(0);
    const [hasClickedLink, setHasClickedLink] = useState(false);
    const [limitModalOpen, setLimitModalOpen] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [numEventUsers, setNumEventUsers] = useState(0);
    const [privateModalOpen, setPrivateModalOpen] = useState(false);

    const clickedLink = () => {
        if (!hasClickedLink) {
            setHasClickedLink(true);
        }
    };

    const {
        event,
        eventHostsUsers,
        eventArtistsUsers,
        eventGoingUsers,
        eventInvitedUsers,
        buyFreeTicket,
        eventGuestsUsers,
        eventContacts,
        fetchEvent,
        updateGoingUsers,
        addEventCodeToUser,
        fetchTicketSkuCounts,
    } = useContext(EventContext);

    const { setUrl, setFuncOnReturn } = useContext(UiContext);

    const { userAuth, change, setOnLoginCallbackObj, userFound, user } =
        useContext(AuthContext);

    const { referralCode, setReferralCode } = useContext(PaymentsContext);

    // useEffect(() => {
    //     if (event && userAuth && userAuth.user && userAuth.user.uid) {
    //         fetchTicketSkuCounts(event["eventTicketTypes"], userAuth.user.uid);
    //     }
    // }, [event]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let retrievedRreferralCode = queryParams.get("referralCode");
        setReferralCode(retrievedRreferralCode);

        const interval = setInterval(() => {
            setForceReRender((forceReRender) => forceReRender + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log("DEBUG forceReRender", forceReRender);
    }, [forceReRender]);

    useEffect(() => {
        if (userAuth && event["eventGoingUsers"].includes(userAuth.user.uid)) {
            setDownloadModalOpen(true);
        }
    }, [event]);

    useEffect(() => {
        if (event) {
            setNumEventUsers(
                event["eventGoingUsers"].length +
                    event["eventInvitedUsers"].length
            );
        }
    }, [event]);

    const navigate = useNavigate();

    return (
        <LinearGradient
            colors={[black, charcoal, black]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={style.container}
        >
            <NavHeader
                onPress={() => {
                    if (userAuth && userAuth.user.uid && userFound) {
                        navigate("/event/host");
                    } else {
                        setOnLoginCallbackObj({
                            func: () => {
                                return "/event/host";
                            },
                            name: "navToHost",
                        });
                        navigate("/auth/home");
                    }
                }}
            />
            {event && eventHostsUsers && eventGuestsUsers ? (
                <View style={style.body}>
                    <View style={style.wrapper}>
                        <View style={style.left}>
                            <HeaderImage
                                image={event["eventMedia"][0]}
                                gradientIndex={event.eventGradientIndex}
                            />
                            <View
                                style={{
                                    marginTop: "6px",
                                    marginBottom: "6px",
                                }}
                            />
                            <TitleRow
                                title={event.eventTitle}
                                start={event.eventStart}
                                end={event.eventEnd}
                                moreThan24Hours={isMoreThan24HoursApart(
                                    event.eventStart,
                                    event.eventEnd
                                )}
                                coordinates={
                                    event.eventLocation.locationCoordinates
                                }
                            />
                            <View
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                            {!event.eventHideGuests ? (
                                <GuestListRow
                                    eventGuestsUsers={eventGuestsUsers}
                                    eventContacts={eventContacts}
                                />
                            ) : null}
                            <View
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        </View>
                        <View style={style.right}>
                            {/* {!event.eventHideGuests ? (
                                <View
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                            ) : null} */}
                            <LocationRow
                                address={event.eventLocation.locationAddress}
                                coordinates={
                                    event.eventLocation.locationCoordinates ?? {
                                        latitude: 37.4275,
                                        longitude: -122.1697,
                                    }
                                }
                            />
                            <View
                                style={{
                                    marginTop: "10px",
                                }}
                            />
                            <TaggedPhotoPreview eventId={event.id} />
                            <View
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                            <DescriptionRow
                                description={event.eventDescription}
                            />
                            {eventArtistsUsers &&
                            eventArtistsUsers.length > 0 ? (
                                <>
                                    <View
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    <ArtistsRow
                                        eventArtistsUsers={eventArtistsUsers}
                                    />
                                </>
                            ) : null}
                            {/* <View
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            /> */}
                            {!event.eventHideHosts ||
                            event.eventHosts.includes(
                                userAuth ? userAuth.user.uid : null
                            ) ? (
                                <>
                                    <View
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    {eventHostsUsers ? (
                                        <HostsRow
                                            eventHostsUsers={eventHostsUsers}
                                        />
                                    ) : null}
                                </>
                            ) : null}
                            {event.eventOtherLinks &&
                            event.eventOtherLinks.length > 0 ? (
                                <>
                                    <View
                                        style={{
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    />
                                    <LinksRow links={event.eventOtherLinks} />
                                </>
                            ) : null}
                            {width >= 768 ? (
                                <View
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                            ) : null}
                            {event.eventExternalLink &&
                            event.eventExternalLink !== "" ? (
                                <ExternalLinkRow
                                    event={event}
                                    userId={userAuth ? userAuth.user.uid : null}
                                    onPress={() => {
                                        // if user is logged in, mark them as going
                                        if (
                                            userAuth &&
                                            userAuth.user.uid &&
                                            !event["eventGoingUsers"].includes(
                                                userAuth.user.uid
                                            )
                                        ) {
                                            updateGoingUsers(
                                                userAuth.user.uid,
                                                event
                                            );
                                            addEventCodeToUser(
                                                userAuth.user.uid,
                                                event
                                            );
                                        }
                                        Linking.openURL(
                                            event.eventExternalLink
                                        );
                                        // if (
                                        //     userAuth &&
                                        //     userAuth.user.uid &&
                                        //     event["eventGoingUsers"].includes(
                                        //         userAuth.user.uid
                                        //     )
                                        // ) {
                                        //     // User is logged in and already going, still redirect
                                        // } else if (
                                        //     userAuth &&
                                        //     userAuth.user.uid &&
                                        //     !event["eventGoingUsers"].includes(
                                        //         userAuth.user.uid
                                        //     )
                                        // ) {
                                        //     // User is logged in but not yet going. add event code to the user and mark them as going. Then redirect
                                        // } else {
                                        //     // User is not logged in, make them log in and then bring them back
                                        // }
                                    }}
                                    eventExternalLink={event.eventExternalLink}
                                />
                            ) : event.eventTicketTypes.length == 0 ? (
                                <>
                                    <NoTicketRow
                                        userId={
                                            userAuth ? userAuth.user.uid : null
                                        }
                                        event={event}
                                        onPress={() => {
                                            if (
                                                userAuth &&
                                                userAuth.user.uid &&
                                                event[
                                                    "eventGoingUsers"
                                                ].includes(userAuth.user.uid)
                                            ) {
                                                window.location.replace(
                                                    "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                                                );
                                            } else if (
                                                userAuth &&
                                                userAuth.user.uid &&
                                                userFound
                                            ) {
                                                updateGoingUsers(
                                                    userAuth.user.uid,
                                                    event
                                                );
                                                addEventCodeToUser(
                                                    userAuth.user.uid,
                                                    event
                                                );
                                            } else {
                                                setUrl(
                                                    window.location.pathname +
                                                        window.location.search
                                                );
                                                setOnLoginCallbackObj({
                                                    updateGoingUsers:
                                                        updateGoingUsers,
                                                    addEventCodeToUser:
                                                        addEventCodeToUser,
                                                });
                                                navigate("/auth/home");
                                            }
                                        }}
                                    />
                                    <Modal
                                        open={downloadModalOpen}
                                        onClose={() =>
                                            setDownloadModalOpen(false)
                                        }
                                        style={style.modal}
                                    >
                                        <Box style={style.modalCard}>
                                            <IconButton
                                                icon={Cancel}
                                                tintColor={"white"}
                                                size={20}
                                                style={style.modalClose}
                                                onPress={() =>
                                                    setDownloadModalOpen(false)
                                                }
                                            />
                                            <Text style={style.modalTitle}>
                                                Avenu App
                                            </Text>
                                            <Text style={style.modalText}>
                                                Open the event page in the Avenu
                                                app to view guest list, Meet new
                                                people, receive event
                                                notifications, add tickets to
                                                Apple Wallet, post content, and
                                                more!
                                            </Text>
                                            <TouchableOpacity
                                                onPress={() =>
                                                    window.location.replace(
                                                        "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                                                    )
                                                }
                                            >
                                                <Image
                                                    source={AppStoreDownload}
                                                    style={style.image}
                                                />
                                            </TouchableOpacity>
                                        </Box>
                                    </Modal>
                                </>
                            ) : (
                                <>
                                    <PurchaseRow
                                        allTicketLimitReached={checkIfAllTicketLimitReached(
                                            event
                                        )}
                                        userId={
                                            userAuth ? userAuth.user.uid : null
                                        }
                                        event={event}
                                        limitReached={numEventUsers >= 9999}
                                        eventOnClick={() => {
                                            if (
                                                checkIfAllTicketLimitReached(
                                                    event
                                                )
                                            ) {
                                                // ticket limit reached
                                                return;
                                            }
                                            if (
                                                userAuth &&
                                                userAuth.user.uid &&
                                                event[
                                                    "eventGoingUsers"
                                                ].includes(userAuth.user.uid)
                                            ) {
                                                // user already on going list, so navigate to tickets
                                                navigate(
                                                    "/tickets?eventId=" +
                                                        event.id
                                                );
                                            } else if (numEventUsers >= 9999) {
                                                // event has 9999 users, open modal
                                                setLimitModalOpen(true);
                                            } else if (
                                                event.eventTicketTypes.length ==
                                                    1 &&
                                                event["eventTicketTypes"][0][
                                                    "ticketPrice"
                                                ] == 0
                                            ) {
                                                // only one ticket type which is free
                                                if (
                                                    userAuth &&
                                                    userFound &&
                                                    userAuth.user.uid
                                                ) {
                                                    // if (
                                                    //     !event.eventPrivacy ||
                                                    //     event[
                                                    //         "eventInvitedUsers"
                                                    //     ].includes(
                                                    //         userAuth.user.uid
                                                    //     )
                                                    //     // ||
                                                    //     // (event.eventContacts &&
                                                    //     //     event.eventContacts
                                                    //     //         .map(
                                                    //     //             (contact) =>
                                                    //     //                 contact.phoneNumber
                                                    //     //         )
                                                    //     //         .includes(
                                                    //     //             user.userPhone.slice(
                                                    //     //                 -10
                                                    //     //             )
                                                    //     //         ))
                                                    // ) {
                                                    // Event is either not private, or it is private and user is invited
                                                    // Buy free ticket
                                                    buyFreeTicket(
                                                        userAuth.user.uid,
                                                        event
                                                    );
                                                    // } else {
                                                    //     // Event is private and user is not invited
                                                    //     setPrivateModalOpen(
                                                    //         true
                                                    //     );
                                                    // }
                                                } else {
                                                    // User is not logged in
                                                    setUrl(
                                                        window.location
                                                            .pathname +
                                                            window.location
                                                                .search
                                                    );
                                                    // checked privacy in AuthContext
                                                    setOnLoginCallbackObj({
                                                        func: buyFreeTicket,
                                                    });
                                                    navigate("/auth/home");
                                                }
                                            } else {
                                                // more than one ticket or ticket cost money
                                                if (userAuth && userFound) {
                                                    // user found
                                                    // if (
                                                    //     !event.eventPrivacy ||
                                                    //     event[
                                                    //         "eventInvitedUsers"
                                                    //     ].includes(
                                                    //         userAuth.user.uid
                                                    //     )
                                                    //     // ||
                                                    //     // (event.eventContacts &&
                                                    //     //     event.eventContacts
                                                    //     //         .map(
                                                    //     //             (contact) =>
                                                    //     //                 contact.phoneNumber
                                                    //     //         )
                                                    //     //         .includes(
                                                    //     //             user.userPhone.slice(
                                                    //     //                 -10
                                                    //     //             )
                                                    //     //         ))
                                                    // ) {
                                                    // event is not private or user in invited list
                                                    navigate(
                                                        "/event/tickets" +
                                                            window.location
                                                                .search
                                                    );
                                                    // } else {
                                                    //     // event is private and user is not on invited list
                                                    //     setPrivateModalOpen(
                                                    //         true
                                                    //     );
                                                    // }
                                                } else {
                                                    // user not found
                                                    const queryParams =
                                                        new URLSearchParams(
                                                            window.location.search
                                                        );
                                                    const eventId =
                                                        queryParams.get(
                                                            "eventId"
                                                        );
                                                    setOnLoginCallbackObj({
                                                        func: (
                                                            userId,
                                                            event,
                                                            eventId,
                                                            user
                                                        ) => {
                                                            if (
                                                                userId &&
                                                                event[
                                                                    "eventGoingUsers"
                                                                ].includes(
                                                                    userId
                                                                )
                                                                // ||
                                                                //     (event.eventPrivacy &&
                                                                //         !event.eventInvitedUsers.includes(
                                                                //             userId
                                                                //         ))
                                                                //         &&
                                                                // !event.eventContacts
                                                                //     .map(
                                                                //         (
                                                                //             contact
                                                                //         ) =>
                                                                //             contact.phoneNumber
                                                                //     )
                                                                //     .includes(
                                                                //         user.userPhone.slice(
                                                                //             -10
                                                                //         )
                                                                //     )
                                                            ) {
                                                                // user logs in and is already on going list
                                                                // OR the event is private and user is not invited and their contact is not in contacts
                                                                // then go back to event detail screen
                                                                console.log(
                                                                    "DEBUG nav path pased for eventId",
                                                                    eventId
                                                                );
                                                                return (
                                                                    "/event?eventId=" +
                                                                    eventId
                                                                );
                                                            } else {
                                                                // event is not on going list and user can rsvp
                                                                console.log(
                                                                    "DEBUG nav path passed for tickets",
                                                                    eventId
                                                                );
                                                                return (
                                                                    "/event/tickets?eventId=" +
                                                                    eventId
                                                                );
                                                            }
                                                        },
                                                        name: "navToEventTicketBuy",
                                                        eventId: eventId,
                                                    });
                                                    navigate("/auth/home");
                                                }
                                            }
                                        }}
                                        ticketOnClick={() => {
                                            if (userAuth && userFound) {
                                                navigate("/tickets");
                                            } else {
                                                setOnLoginCallbackObj({
                                                    func: () => {
                                                        return "/tickets";
                                                    },
                                                    name: "navToTickets",
                                                });
                                                navigate("/auth/home");
                                            }
                                        }}
                                    />
                                    <Modal
                                        open={privateModalOpen}
                                        onClose={() =>
                                            setPrivateModalOpen(false)
                                        }
                                        style={style.modal}
                                    >
                                        <Box style={style.modalCard}>
                                            <IconButton
                                                icon={Cancel}
                                                tintColor={"white"}
                                                size={20}
                                                style={style.modalClose}
                                                onPress={() =>
                                                    setPrivateModalOpen(false)
                                                }
                                            />
                                            <Text style={style.modalTitle}>
                                                Private Event
                                            </Text>
                                            <Text style={style.modalText}>
                                                This is a private event. Only
                                                guests invited directly by
                                                co-hosts can join. Contact a
                                                host to be invited.
                                            </Text>
                                        </Box>
                                    </Modal>
                                    <Modal
                                        open={limitModalOpen}
                                        onClose={() => setLimitModalOpen(false)}
                                        style={style.modal}
                                    >
                                        <Box style={style.modalCard}>
                                            <IconButton
                                                icon={Cancel}
                                                tintColor={"white"}
                                                size={20}
                                                style={style.modalClose}
                                                onPress={() =>
                                                    setLimitModalOpen(false)
                                                }
                                            />
                                            <Text style={style.modalTitle}>
                                                Event at Capacity
                                            </Text>
                                            <Text style={style.modalText}>
                                                {userAuth &&
                                                event.eventHosts.includes(
                                                    userAuth.user.uid
                                                )
                                                    ? "This event has 9,999 guests and is therefore at its limit. Create a second, concurrent event to handle larger event capacities."
                                                    : "This event has 9,999 guests and is therefore at its limit. Contact the event host to create a second, concurrent event to handle larger event capacities."}
                                            </Text>
                                        </Box>
                                    </Modal>
                                    <Modal
                                        open={downloadModalOpen}
                                        onClose={() =>
                                            setDownloadModalOpen(false)
                                        }
                                        style={style.modal}
                                    >
                                        <Box style={style.modalCard}>
                                            <IconButton
                                                icon={Cancel}
                                                tintColor={"white"}
                                                size={20}
                                                style={style.modalClose}
                                                onPress={() =>
                                                    setDownloadModalOpen(false)
                                                }
                                            />
                                            <Text style={style.modalTitle}>
                                                Avenu App
                                            </Text>
                                            <Text style={style.modalText}>
                                                Open the event page in the Avenu
                                                app to view guest list, Meet new
                                                people, receive event
                                                notifications, add tickets to
                                                Apple Wallet, post content, and
                                                more!
                                            </Text>
                                            <TouchableOpacity
                                                onPress={() =>
                                                    window.location.replace(
                                                        "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                                                    )
                                                }
                                            >
                                                <Image
                                                    source={AppStoreDownload}
                                                    style={style.image}
                                                />
                                            </TouchableOpacity>
                                        </Box>
                                    </Modal>
                                </>
                            )}
                            <>
                                <View
                                    style={{
                                        marginTop: "5px",
                                    }}
                                />
                                <DiscoverEventsRow
                                    locationAddress={
                                        event.eventLocation.locationAddress
                                    }
                                />
                                <View
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                                <EventRefRow refCode={event.eventRefCode} />
                            </>
                            {width < 768 ? (
                                <View
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                            ) : null}
                        </View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <CreateManageRow
                            text={"create your event"}
                            backgroundColor={"black"}
                            textColor={"white"}
                            onClick={() => {
                                if (
                                    userAuth &&
                                    userAuth.user.uid &&
                                    userFound
                                ) {
                                    navigate("/event/create");
                                } else {
                                    setOnLoginCallbackObj({
                                        func: () => {
                                            return "/event/create";
                                        },
                                        name: "navToCreate",
                                    });
                                    navigate("/auth/home");
                                }
                            }}
                        />
                        <View style={{ width: 10 }} />
                        {userAuth &&
                            userAuth.user.uid &&
                            userFound &&
                            event.eventHosts.includes(userAuth.user.uid) && (
                                <CreateManageRow
                                    text={"edit event"}
                                    backgroundColor={color}
                                    textColor={"black"}
                                    onClick={() => {
                                        if (
                                            userAuth &&
                                            userAuth.user.uid &&
                                            userFound
                                        ) {
                                            navigate("/event/edit", {
                                                state: { editEvent: event },
                                            });
                                        } else {
                                            setOnLoginCallbackObj({
                                                func: () => {
                                                    return {
                                                        route: "/event/edit",
                                                        params: {
                                                            state: {
                                                                editEvent:
                                                                    event,
                                                            },
                                                        },
                                                    };
                                                },
                                                name: "navToEdit",
                                            });
                                            navigate("/auth/home");
                                        }
                                    }}
                                />
                            )}
                    </View>
                    {width < 768 ? (
                        <View
                            style={{
                                marginTop: "40px",
                                marginBottom: "60px",
                            }}
                        />
                    ) : null}
                </View>
            ) : null}
        </LinearGradient>
    );
};

export default Event;
