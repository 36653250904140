import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";
import style from "./style";

const TitleRow = (props) => (
    <View style={style.container}>
        <Text style={style.title}>{props.title}</Text>
    </View>
);

TitleRow.propTypes = {
    title: PropTypes.string.isRequired,
};

export default React.memo(TitleRow);
