import React, {
    useEffect,
    useContext,
    useState,
    useMemo,
    useCallback,
} from "react";
import {
    View,
    Text,
    FlatList,
    TouchableOpacity,
    TextInput,
    ScrollView,
    Image,
} from "react-native";
import TicketRow from "../../components/TicketRow";

import style from "./style";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../EventContext";
import { PaymentsContext } from "../../../payments/PaymentsContext";
import BubbleButton from "../../../../components/buttons/BubbleButton";
import { AppStoreDownload } from "../../utils/images";
import NavHeader from "../../../../components/custom/NavHeader";

import { convertIntegerAmountToDollarString } from "../../../../utils/text";
import { AuthContext } from "../../../auth/AuthContext";

const getRealPrice = (listedPrice) => {
    return (listedPrice + 100) / 0.95;
};

const EventTicketView = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { userAuth, setOnLoginCallbackObj } = useContext(AuthContext);

    const { event, perGuestTicketCounts, fetchTicketSkuCounts } =
        useContext(EventContext);

    const {
        setPaymentTotal,
        paymentTotal,
        unlockedTickets,
        setUnlockedTickets,
        setTicketInfo,
    } = useContext(PaymentsContext);

    useEffect(() => {
        if (event && userAuth && userAuth.user && userAuth.user.uid) {
            fetchTicketSkuCounts(event["eventTicketTypes"], userAuth.user.uid);
        }
    }, [event]);

    const [forceReRender, setForceReRender] = useState(0);
    const [hiddenTicketCode, setHiddenTicketCode] = useState("");
    const [displayedHiddenTickets, setDisplayedHiddenTickets] = useState([]);
    const [isInputFocused, setInputFocused] = useState(false);

    const navigate = useNavigate();

    const [refreshKey, setRefreshKey] = useState(0);

    const forceRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    const findTicketIndex = useCallback((event, ticketSku) => {
        if (
            !event["eventTicketTypes"] ||
            !Array.isArray(event["eventTicketTypes"])
        ) {
            return -1; // Ensure that event["eventTicketTypes"] exists and is an array
        }
        return event["eventTicketTypes"].findIndex(
            (ticket) => ticket.ticketSku === ticketSku
        );
    }, []);

    const renderItem = (item, index, offset = 0) => {
        if (item.ticketDeleted) return;
        if (item.ticketLimit && item.ticketsPurchased >= item.ticketLimit) {
            return (
                <TicketRow
                    ticketName={item.ticketName}
                    ticketPrice={item.ticketPrice}
                    feesText={
                        item.ticketPrice > 0 &&
                        `($${getRealPrice(
                            convertIntegerAmountToDollarString(item.ticketPrice)
                        )} with fees)`
                    }
                    ticketDescription={item.ticketDescription ?? ""}
                    isTicketLocked={item.isLockedTicket}
                    soldOut={true}
                    display={false}
                    index={findTicketIndex(event, item.ticketSku)}
                    isScheduledTicket={item.isScheduledTicket ?? false}
                    releaseTime={
                        item.isScheduledTicket ? item.releaseTime : undefined
                    }
                    isTicketHidden={
                        item.ticketCode ? item.ticketCode !== "" : false
                    }
                />
            );
        } else {
            return (
                <TicketRow
                    ticketName={item.ticketName}
                    ticketPrice={item.ticketPrice}
                    feesText={
                        item.ticketPrice > 0 &&
                        `($${convertIntegerAmountToDollarString(
                            getRealPrice(item.ticketPrice)
                        )} with fees)`
                    }
                    ticketDescription={item.ticketDescription ?? ""}
                    setPaymentTotal={setPaymentTotal}
                    paymentTotal={paymentTotal}
                    index={findTicketIndex(event, item.ticketSku)}
                    isTicketLocked={item.isLockedTicket}
                    isScheduledTicket={item.isScheduledTicket ?? false}
                    releaseTime={
                        item.isScheduledTicket ? item.releaseTime : undefined
                    }
                    isTicketHidden={
                        item.ticketCode ? item.ticketCode !== "" : false
                    }
                    maxTicketsPerGuest={
                        item.ticketPerGuestLimit != null &&
                        event.eventTicketTypes[index].ticketLimit
                            ? Math.min(
                                  item.ticketPerGuestLimit -
                                      (perGuestTicketCounts[item.ticketSku] ||
                                          0),
                                  event.eventTicketTypes[index].ticketLimit -
                                      (event.eventTicketTypes[index]
                                          .ticketsPurchased ?? 0)
                              )
                            : item.ticketPerGuestLimit -
                              (perGuestTicketCounts[item.ticketSku] || 0)
                    }
                />
            );
        }
    };

    const handleCodeChange = (code) => {
        // Remove special characters and convert to uppercase
        const sanitizedCode = code.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
        setHiddenTicketCode(sanitizedCode);
    };

    const handleUnlock = () => {
        const matchingTickets = event.eventTicketTypes.filter(
            (type) => type.ticketCode === hiddenTicketCode
        );
        if (matchingTickets.length > 0) {
            setUnlockedTickets([...matchingTickets, ...unlockedTickets]);
            forceRefresh();
        } else {
            // handle error
            window.alert(
                `The ticket code "${hiddenTicketCode}" you entered is invalid or expired.`
            );
        }
    };

    const handleCodeFocus = () => {
        setInputFocused(true); // Set the input focus state to true when focused
    };

    const handleCodeBlur = () => {
        setInputFocused(false); // Set the input focus state to false when blurred
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setForceReRender((forceReRender) => forceReRender + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const ticketTypes = useMemo(() => {
        return event
            ? event["eventTicketTypes"].filter((type) => !type.ticketCode)
            : [];
    }, [event]);

    const unlockedTicketTypes = useMemo(() => {
        return unlockedTickets.length > 0
            ? event["eventTicketTypes"].filter((type) =>
                  unlockedTickets.includes(type)
              )
            : [];
    }, [event, unlockedTickets]);

    const sortedData = useMemo(() => {
        const combinedData = [...ticketTypes, ...unlockedTicketTypes];

        combinedData.sort((a, b) => {
            const aUnlocked = unlockedTickets.includes(a);
            const bUnlocked = unlockedTickets.includes(b);

            // Check if tickets are sold out
            const aSoldOut =
                a.ticketLimit && a.ticketsPurchased >= a.ticketLimit;
            const bSoldOut =
                b.ticketLimit && b.ticketsPurchased >= b.ticketLimit;

            // Prioritize unlocked tickets that are not sold out
            if (aUnlocked && !aSoldOut && (!bUnlocked || bSoldOut)) return -1;
            if (bUnlocked && !bSoldOut && (!aUnlocked || aSoldOut)) return 1;

            // If both tickets are the same type (both unlocked or both not unlocked), sort by sold out status
            if (aSoldOut && !bSoldOut) return 1;
            if (bSoldOut && !aSoldOut) return -1;

            return 0;
        });

        return combinedData;
    }, [ticketTypes, unlockedTicketTypes]);

    const updateMapping = useCallback(() => {
        const newTicketInfo = {};

        sortedData.forEach((item, index) => {
            newTicketInfo[index] = item.ticketName;
        });

        setTicketInfo(newTicketInfo);
    }, [sortedData, setTicketInfo]);

    return (
        <View style={style.container} key={refreshKey}>
            <NavHeader
                onPress={() => {
                    navigate(`/event?eventId=${event.id}`);
                }}
            />
            <Text style={style.header}>Select Passes and Items</Text>
            {/* <Text style={style.subheader}>Select a ticket</Text> */}
            <View style={style.contentContainer}>
                <ScrollView style={style.nonHiddenTicketsContainer}>
                    <View style={style.inputContainer}>
                        <TextInput
                            style={style.input}
                            value={hiddenTicketCode}
                            placeholder={
                                isInputFocused ? "" : "Hidden Code"
                            } // Show the placeholder conditionally
                            onFocus={handleCodeFocus}
                            onBlur={handleCodeBlur}
                            onChangeText={handleCodeChange}
                        />
                        <BubbleButton
                            bubbleStyle={style.unlockButton}
                            title="Unlock Hidden Passes or Items"
                            onPress={handleUnlock}
                            disabled={!hiddenTicketCode}
                        />
                    </View>

                    {event ? (
                        // &&
                        // eventTicketSkuCounts &&
                        // Object.keys(eventTicketSkuCounts).length > 0
                        <FlatList
                            style={style.list}
                            data={sortedData}
                            renderItem={({ item, index }) =>
                                renderItem(item, index, ticketTypes.length)
                            }
                        />
                    ) : null}
                    <View style={style.appTextContainer}>
                        {" "}
                        {/* New container to center the text and image */}
                        <Text style={style.appText}>
                            Download Avenu on App Store to receive notifications
                            about the event & ticket releases, add friends, meet
                            people, and post pictures!
                        </Text>
                        <TouchableOpacity
                            onPress={() =>
                                window.location.replace(
                                    "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                                )
                            }
                            style={style.downloadButton}
                        >
                            <Image
                                source={AppStoreDownload}
                                style={style.image}
                            />
                        </TouchableOpacity>
                    </View>
                </ScrollView>
                <View style={style.rightContainer}>
                    <View
                        style={[
                            style.unlockTicketContainer,
                            !unlockedTickets.length > 0 && { marginBottom: 90 },
                        ]}
                    ></View>
                </View>
            </View>

            <View style={style.footer}>
                <Text style={style.bottom}>
                    Total Payment: ${(paymentTotal / 100).toFixed(2)}
                </Text>
                {event && userAuth && userAuth.user && userAuth.user.uid ? (
                    <TouchableOpacity
                        style={style.confirm}
                        onPress={() => {
                            updateMapping();
                            navigate("/stripe" + window.location.search);
                        }}
                    >
                        <Text style={style.confirmText}>Confirm</Text>
                    </TouchableOpacity>
                ) : (
                    <TouchableOpacity
                        style={style.confirm}
                        onPress={() => {
                            setOnLoginCallbackObj({
                                func: () => {
                                    return "/stripe" + window.location.search;
                                },
                                name: "navToStripe",
                            });
                            navigate("/auth/home");
                        }}
                    >
                        <Text style={style.confirmText}>
                            Login / Register to purchase
                        </Text>
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
};

export default EventTicketView;
