import React from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, Text} from "react-native";
import { isToday } from "../../../../utils/time";

import style from "./style";
import IconButton from "../../../../components/buttons/IconButton";
import { PlusIcon } from "../../../../utils/icons";
import { black } from "../../../../utils/color";

const GoingRow = (props) => (
    <TouchableOpacity style={style.row} onPress={props.onClick}>
        <IconButton
                icon={PlusIcon}
                style={style.icon}
                theme={"dark"}
                onPress={props.onClick}
                tintColor={black}
            />
        <Text style={style.text}>{props.venue["venueGoing"][props.userId] && isToday(props.venue["venueGoing"][props.userId]) ? "Joined!" : "Join"}</Text>
    </TouchableOpacity>
);

GoingRow.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default React.memo(GoingRow);
