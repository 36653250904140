import React, { useEffect } from "react";

import LegalTermsView from "../views/LegalTermsView";

import { useNavigate } from "react-router-dom";
const LegalTermsScreen = (props) => {
  const navigate = useNavigate();

  return <LegalTermsView
    navigate={navigate}
  />;
};

export default LegalTermsScreen;
