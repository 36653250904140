import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    paddingLeft: 16,
    paddingRight: 16,
    margin: 16,
  },

  icon: {
    width: 20,
    height: 20,
  },

  text: { color: "white" },
});
