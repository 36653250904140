import React from "react";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";
import EventNameView from "../views/EventNameView";
import { useNavigate } from "react-router-dom";

const EventNameScreen = (props) => {
    const { name, onChangeName } = useContext(CreateEventContext);
    const navigate = useNavigate();

    return (
    <EventNameView
        name={name}
        onChangeName={onChangeName}
        navigate={navigate}
    />
    );
};

export default EventNameScreen;