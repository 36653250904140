import { StyleSheet, Platform } from "react-native";

import { color, darkThemeBackground } from "../../../../utils/color";
import style from "../../../../styles/viewStyles";
import views from "../../../../styles/views";

export default StyleSheet.create({
  buttonBubble: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 24,
    marginBottom: 24,

    backgroundColor: color,
  },

  buttonTitle: {
    color: "black",
  },

  // container: {
  //   width: "100%",
  //   backgroundColor: "whitesmoke",
  // },

  container: {
    flex: 1,
    width: "100%",

    paddingLeft: 16,
    paddingRight: 16,
  },

  empty: {
    ...style.empty,
    justifyContent: "center",
    alignItems: "center",
  },

  list: {
    ...style.list,
  },

  screen: {
    backgroundColor: darkThemeBackground,
    minHeight: "100vh"
  },

  textfield: {
    backgroundColor: "white",
    height: 46,
  },

  icon: {
    position: "fixed",
    bottom: 20,
    right: 20,
    ...Platform.select({
      ios: {
        shadowColor: "black",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 10,
      },
      android: {
        elevation: 50,
        backgroundColor: "transparent",
      },
    }),
  },
});
