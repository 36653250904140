import { StyleSheet } from "react-native";

import style from "../../../../styles/viewStyles";
import { darkThemeBackground } from "../../../../utils/color";

export default StyleSheet.create({
  screen: {
    ...style.screen,
    backgroundColor: darkThemeBackground
  },

  text: {
    margin: 32,
    color: "white",
  },
});
