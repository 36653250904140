import React, { useEffect, useState } from "react";
import {
    View,
    FlatList,
    Text,
    TouchableOpacity,
    ActivityIndicator,
} from "react-native-web";

import {
    collection,
    getDocs,
    limit,
    query,
    startAfter,
} from "firebase/firestore";
import db from "../../../../firebaseConfig.js";

import style from "./style";

const renderItem = (item) => {
    let post = item.item;
    return (
        <TouchableOpacity
            onPress={() =>
                window.location.replace(
                    "https://apps.apple.com/us/app/avenu-events-with-friends/id1487333983"
                )
            }
            style={style.post}
        >
            <img
                src={post.postMedia}
                style={style.postImg}
                alt=""
            />
        </TouchableOpacity>
    );
};

const DiscoverPostsRow = (props) => {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const eventId = props.eventId;

    useEffect(() => {
        const fetchPosts = async () => {
            setIsLoading(true);
            const postsRef = collection(db, "posts");
            let q = query(postsRef, limit(100)); // Fetch only 3 posts initially
    
            const postsFetch = [];
            let lastDoc;
    
            while (postsFetch.length < 5) {
                if (lastDoc) {
                    q = query(postsRef, startAfter(lastDoc), limit(100));
                }
    
                const querySnapshot = await getDocs(q);
    
                if (querySnapshot.empty) {
                    break;
                }
    
                lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    
                const filteredPosts = querySnapshot.docs
                    .filter((doc) => {
                        const post = doc.data();
                        return post.postEvent === eventId;
                    })
                    .slice(0, 5 - postsFetch.length); // Only add up to needed amount
    
                postsFetch.push(...filteredPosts.map((doc) => doc.data()));
    
                if (postsFetch.length >= 5) {
                    break;
                }
            }
    
            setPosts(postsFetch);
            setIsLoading(false);
        };
    
        fetchPosts();
    }, [eventId]);

    return (
        isLoading ? (
            <View style={{ marginTop: 20, marginBottom: 10 }}>
                <ActivityIndicator size="large" color="#1ffdba" />
            </View>
        ) : (
            posts.length > 0 && (
                <TouchableOpacity
                    onPress={() =>
                        window.location.replace(
                            "https://apps.apple.com/us/app/avenu-posts-with-friends/id1487333983"
                        )
                    }
                >
                    <View style={style.body}>
                        <View style={style.wrapper}>
                            <img
                                src={require("../../../../assets/icons/icon-tagged.png")}
                                style={{
                                    ...style.image,
                                    width: "35px",
                                    height: "35px",
                                    marginLeft: "20px",
                                    marginTop: "11px",
                                }}
                                alt="Tagged Photos Icon"
                            />
                            <Text style={style.subtitle}>Tagged Photos</Text>
                        </View>
                        <View style={style.flatlistContainer}>
                          <FlatList
                              style={style.flatlist}
                              contentContainerStyle={style.flatlistContent}
                              horizontal={true}
                              data={posts}
                              renderItem={(item) => renderItem(item)}
                              keyExtractor={(item, index) => index.toString()}
                              showsHorizontalScrollIndicator={false} // Add this line to hide the scroll bar
                          />
                      </View>
                    </View>
                </TouchableOpacity>
            )
        )
    );  
};

export default React.memo(DiscoverPostsRow);