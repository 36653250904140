import React from "react";
import {
    View,
    FlatList,
    Text,
    TouchableOpacity,
    Image,
} from "react-native-web";
import PropTypes from "prop-types";

import { DefaultProfileImage, BlueCheckmark } from "../../utils/images";

import style from "./style";

const renderItem = (item) => {
    let user = item.item;
    console.log(user.userVerified);
    return (
        <TouchableOpacity
            onPress={() =>
                window.location.replace(
                    "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                )
            }
            style={style.host}
        >
            <img
                key={user.userPhone}
                src={
                    user.userMedia.length !== 0
                        ? user.userMedia[0]
                        : DefaultProfileImage
                }
                style={style.profileImg}
            />
            {user.userVerified ? (
                <Image style={style.icon} source={BlueCheckmark} />
            ) : null}
        </TouchableOpacity>
    );
};

const ArtistsRow = (props) => (
    <View style={style.body}>
        <View style={style.wrapper}>
            <img src={require("../../../../assets/icons/icon-artists.png")} style={{...style.image, width: '30px', height: '30px', marginTop: '15px', marginLeft: '15px'}} />
            <Text style={style.subtitle}>Artists & Performers</Text>
        </View>
        <View style={style.list}>
            <FlatList
                style={style.flatlist}
                contentContainerStyle={style.wrapper}
                horizontal={true}
                data={props.eventArtistsUsers}
                renderItem={(item) => renderItem(item)}
            />
        </View>
    </View>
);

export default React.memo(ArtistsRow);
