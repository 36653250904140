import { StyleSheet } from "react-native-web";
import { white } from "../../../../utils/color";
import text from "../../../../styles/text";
import { Dimensions } from "react-native";

export default StyleSheet.create({
    container: {
        backgroundColor: "black",
        borderRadius: 20,
        width: "100%",
        overflow: "hidden",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    guest: {
        height: 75,
        width: 75,
        borderRadius: 75 / 2,
        borderWidth: 2,
        borderColor: white,
        marginRight: -7,
        justifyContent: 'center',
        alignItems: 'center',
    },

    extra: {
        height: 75,
        width: 75,
        borderRadius: 75 / 2,
        borderWidth: 2,
        borderColor: white,
        marginRight: -7,
        backgroundColor: "#8C52FF",
        justifyContent: "center",
        alignItems: "center",
    },

    extraText: {
        fontSize: 20,
        fontWeight: "bold",
    },

    flatlist: {
        marginLeft: 15,
        marginBottom: 10,
        marginRight: 10,
    },

    subtitle: {
        ...text.subtitleBold,
        color: white,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginRight: 15,
        marginTop: 15,
    },

    wrapper: {
        flexDirection: "row",
        width: "100%",
    },

    profileImg: {
        height: 72,
        width: 72,
        borderRadius: 36,
    },

    emoji: {
        fontSize: 32,
        marginBottom: 12,
        marginLeft: 10,
        // marginRight: 10,
        marginTop: 10,
    },

    contactText: {
        ...text.subtitleBold,
        color: "white",
        fontSize: 25,
    },

    tooltipButton: {
        marginTop: 3,
        marginRight: 7,
    },
});
