// import React from "react";
// import { Switch, Text, View } from "react-native";
// import PropTypes from "prop-types";

// import { green } from "../../../utils/color";

// import style from "./style";

// const getTitleStyle = (disabled) => (disabled ? [style.title, style.titleDisabled] : style.title);

// const SwitchRow = (props) => {
//   const tooltipIconStyle = props.theme === "dark" ? { tintColor: "white" } : null;

//   return (
//     <View style={style.row}>
//       <View style={style.container}>
//         <View style={{ flexDirection: "row", alignItems: "center" }}>
//           <Text style={getTitleStyle(props.disabled)}>{props.title}</Text>
//           {props.tooltip}
//         </View>
//         {props.children}

//         <Switch
//           value={props.value}
//           onValueChange={props.onChangeValue}
//           trackColor={{ true: green }}
//           disabled={props.disabled}
//         />
//       </View>
//     </View>
//   );
// };

// SwitchRow.defaultProps = {
//   image: null,
//   disabled: false,
//   tooltip: null,
//   theme: "light",
// };

// SwitchRow.propTypes = {
//   title: PropTypes.string.isRequired,
//   image: PropTypes.string,
//   value: PropTypes.bool.isRequired,
//   onChangeValue: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   tooltip: PropTypes.element,
//   theme: PropTypes.string,
// };

// export default React.memo(SwitchRow);

import React from "react";
import { Switch, Text, View } from "react-native";
import PropTypes from "prop-types";

import { black, sky, white } from "../../../utils/color";

import style from "./style";

const getTitleStyle = (disabled) =>
    disabled ? [style.title, style.titleDisabled] : style.title;

const SwitchRow = (props) => {
    const tooltipIconStyle =
        props.theme === "dark" ? { tintColor: "white" } : null;

    return (
        <View style={style.bigContainer}>
            <View style={style.smallContainer}>
                <Text style={getTitleStyle(props.disabled)}>{props.title}</Text>
                {props.tooltip}
                {props.children}
            </View>

            <Switch
                value={props.value}
                onValueChange={props.onChangeValue}
                trackColor={{ true: sky }}
                activeThumbColor={white}
                disabled={props.disabled}
            />
        </View>
    );
};

SwitchRow.defaultProps = {
    image: null,
    disabled: false,
    tooltip: null,
    theme: "dark",
};

SwitchRow.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    tooltip: PropTypes.element,
    theme: PropTypes.string,
};

export default React.memo(SwitchRow);
