import React, { useEffect } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import { MoreIcon } from "../../../../utils/icons";
import { Shadow } from "../../../../utils/images";
import { getDateTime } from "../../../../utils/time";
import { DefaultEventImage } from "../../../../utils/images";
// import GuestPreviewRow from "../GuestPreviewRow";
import { Gradient1, Gradient2, Gradient3, Gradient4, Gradient5 } from "../../utils/images";
import moment from "moment";

import style from "./style";
import IconButton from "../../../../components/buttons/IconButton";

const EventRow = (props) => {
  let gradientImage;
  switch (props.gradientIndex) {
    case 0:
      gradientImage = Gradient1;
      break;
    case 1:
      gradientImage = Gradient2;
      break;
    case 2:
      gradientImage = Gradient3;
      break;
    case 3:
      gradientImage = Gradient4;
      break;
    case 4:
      gradientImage = Gradient5;
      break;
    default:
      gradientImage = DefaultEventImage;
      break;
  }

  

  let timeInMilliseconds = props.time.seconds * 1000 + props.time.nanoseconds / 1e6;
  let momentTime = moment(timeInMilliseconds);

  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={[style.card, props.style]}>
        {props.image ? (
          <Image source={{ uri: props.image }} style={style.image} />
        ) : (
          <Image source={gradientImage} style={style.image} />
        )}
        <Image source={Shadow} style={style.overlay} />
        <View style={style.container}>
          {props.icon ? 
            <IconButton 
              theme={"dark"}
              style={props.iconStyle}
              icon={props.icon}
              onPress={props.onPressIcon}
              size={props.iconSize}
            />
          : null}
          {props.invitedBy ? (
            <View style={style.invitedByRow}>
              <Image source={props.invitedBy.userMedia[0]} style={style.hostImage} />
              <Text style={style.invitedByText}>{props.invitedBy.userFirst} invited you</Text>
            </View>
          ) : null}
          <View style={style.wrapper}>
            {/* <TouchableOpacity onPress={props.onPressIcon}>
            <Image source={MoreIcon} style={style.icon} />
          </TouchableOpacity> */}
          </View>
          <View style={style.bottom}>
            <View>
              <Text style={style.title}>{props.title}</Text>
              <Text style={style.body}>
                  {props.recurring 
                    ? `${momentTime.format('dddd')}s at ${momentTime.format('h:mm a')}`
                    : getDateTime(props.time)
                  }
              </Text>
            </View>
            {/* {Object.keys(props.eventGuests).length != 0 &&
            (!props.hideGuests || props.eventHosts.includes(props.userId)) ? (
              <View style={style.guests}>
                <GuestPreviewRow
                  guests={props.eventGuests}
                  navigation={props.navigation}
                  event={true}
                />
              </View>
            ) : null} */}
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};
EventRow.defaultProps = {
  style: {},
};

EventRow.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  time: PropTypes.object.isRequired,
  venue: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  onPressIcon: PropTypes.func.isRequired,
  style: PropTypes.object,
  eventGuests: PropTypes.object.isRequired,
  navigate: PropTypes.object.isRequired,
  dayOfWeek: PropTypes.string,
};

export default EventRow;
