import { StyleSheet } from "react-native";

import { snow, ash, charcoal, green } from "../../../utils/color";
import { family, body } from "../../../utils/font";

export default StyleSheet.create({
  bar: {
    justifyContent: "center",
    alignItems: "center",

    height: 40,

    borderRadius: 8,
    backgroundColor: green,
  },

  barDisabled: {
    backgroundColor: snow,
  },

  title: {
    width: "100%",
    textAlign: "center",

    marginLeft: 16,
    marginRight: 16,

    fontFamily: family,
    fontSize: body,

    color: charcoal,
  },

  titleDisabled: {
    color: ash,
  },
});