import React from "react";
import { Text, View } from "react-native";

import IconButton from "../../buttons/IconButton";

import { BackIcon3x, PlusIcon3x } from "../../../utils/icons";

import style from "./style";

const NavHeader = (props) => {
    return (
        <View style={style.row}>
            <IconButton
                style={style.item}
                icon={BackIcon3x}
                theme={"dark"}
                size={26}
                onPress={props.onPress}
            />
            <View style={style.titleBox}>
                <Text style={style.title}>{props.title}</Text>
            </View>
            {props.onAddTicket ? (
                <IconButton
                    style={{ ...style.item, marginRight: 10 }}
                    icon={PlusIcon3x}
                    size={26}
                    theme={"dark"}
                    onPress={props.onAddTicket}
                />
            ) : (
                <View />
            )}
        </View>
    );
};

export default React.memo(NavHeader);
