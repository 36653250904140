import { StyleSheet } from "react-native";

import style from "../../../../styles/viewStyles";
import views from "../../../../styles/views";
import { black, color, darkThemeBackground } from "../../../../utils/color";

export default StyleSheet.create({
  empty: {
    color: "white",
  },

  container: {
    flex: 1,
    width: "100%",

    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: darkThemeBackground,
  },

  list: {
    ...views.list,
  },

  pill: {
    ...views.pill,
  },

  screen: {
    ...views.screen,
  },

  sheet: {
    ...views.sheet,
  },

  bottomBorder: {
    //borderBottom: "1px solid black",
    backgroundColor: black,
  },
  
  line: {
    width: "100%",
    height: 1,
    backgroundColor: "gray",
    alignSelf: "center"
  },

  item: {
    alignSelf: "center",
    borderRadius: "5px",
    padding: 10,
    marginTop: 20,
    border: "1px solid white",
  }
});
