import React from "react";
import PropTypes from "prop-types";

import style from "./style";

import {
    DefaultEvent,
    Gradient1,
    Gradient2,
    Gradient3,
    Gradient4,
    Gradient5,
} from "../../utils/images";
import { View } from "react-native-web";

const HeaderImage = (props) => {
    let gradient;
    switch (props.gradientIndex) {
        case 0:
            gradient = Gradient1;
            break;
        case 1:
            gradient = Gradient2;
            break;
        case 2:
            gradient = Gradient3;
            break;
        case 3:
            gradient = Gradient4;
            break;
        case 4:
            gradient = Gradient5;
            break;
        default:
            gradient = DefaultEvent;
            break;
    }
    return (
        <View style={style.container}>
            <img
                src={props.image ? props.image : gradient}
                style={style.image}
            />
        </View>
    );
};

HeaderImage.defaultProps = {
    image: null,
};

HeaderImage.propTypes = {
    image: PropTypes.string,
};

export default React.memo(HeaderImage);
