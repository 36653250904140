import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";
import { white, darkThemeBackground } from "../../../../../utils/color";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",

    paddingLeft: 32,
    paddingRight: 32,
    justifyContent: "space-between"
  },

  footer: {
    position: "absolute",

    right: 32,
    bottom: 24,
  },

  header: {
    ...text.headerBold,

    marginTop: 40,
    marginBottom: 24,
    color: white
  },

  subHeader: {
    ...text.headerBold,
    fontSize: 20,
    textAlign: "center",
    marginTop: 30,
  },

  list: {
    ...views.list,
  },

  pill: {
    ...views.pill,
  },

  screen: {
    ...views.screen,
  },

  sheet: {
    ...views.sheet,

    paddingLeft: 32,
    paddingRight: 32,
  },

  tooltipButton: {
    paddingHorizontal: 8,
    paddingVertical: 6,
  },

  textfield: {
    margin: 0,
    // backgroundColor: snow,
  },

  spacer: {
    flex: 1,

  },

  linkList: {
    flexGrow: 1,
  },

  iconStyle: {
    width: 24,
    height: 24,
    color: white,
    marginRight: 10,
  },

  modalContainer: {
    position: 'fixed', // to cover the entire viewport
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center', // centers modal vertically
    justifyContent: 'center', // centers modal horizontally
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // optional: adds a semi-transparent background
},

modalCard: {
    borderRadius: 20,
    width: "80%",
    backgroundColor: darkThemeBackground,
    padding: 20, 
    boxSizing: 'border-box', 
    maxHeight: '90%',
    overflowY: 'auto'
},

pickerField: {
    backgroundColor: 'transparent',
    borderColor: 'white', // you can add this if you want a border
    borderWidth: 0.4,
    borderRadius: 10, // giving some curvature to borders
},
item: {
    color: "white",
    backgroundColor: darkThemeBackground,
  },


});
