import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";
import { snow } from "../../../utils/color";

const PickerField = (props) => (
  <TouchableOpacity onPress={props.onFocused}>
    <View style={[style.container, props.style]}>
      <Text style={{ ...style.text, color: snow }}>{props.value}</Text>
      {props.focused && (
        <View
          style={style.picker}
        >
          {props.values.map((value) => (
            <View style={style.text} onClick={() => props.onChangeValue(value)}>{value}</View>
          ))}
        </View>
      )}
    </View>
  </TouchableOpacity>
);

PickerField.defaultProps = {
  style: {},
};

PickerField.propTypes = {
  value: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  onFocused: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default React.memo(PickerField);
