import React, { useEffect, useContext, useState } from "react";
import LinearGradient from "react-native-web-linear-gradient";

import { VenueContext } from "../../VenueContext";
import HeaderImage from "../../components/HeaderImage";
import TitleRow from "../../components/TitleRow";
import LocationRow from "../../components/LocationRow";
import GoingRow from "../../components/GoingRow";
import GuestListRow from "../../components/GuestListRow";
import PhoneRow from "../../components/PhoneRow";
import HoursRow from "../../components/HoursRow";

import style from "./style";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../auth/AuthContext";

import { Dimensions } from "react-native";
import { black, charcoal } from "../../../../utils/color";

const { width } = Dimensions.get("window");

const Venue = () => {
    const [forceReRender, setForceReRender] = useState(0);

    const { venue, venueGoing, updateVenueGoingList } =
        useContext(VenueContext);

    console.log(venue);

    const { userAuth, change, setOnLoginCallbackObj, userFound } =
        useContext(AuthContext);

    useEffect(() => {
        const interval = setInterval(() => {
            setForceReRender((forceReRender) => forceReRender + 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log("DEBUG forceReRender venues", forceReRender);
    }, [forceReRender]);

    const navigate = useNavigate();
    return (
        <LinearGradient
            colors={[black, charcoal, black]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={style.container}
        >
            {venue && venueGoing ? (
                <div style={style.body}>
                    <div style={style.wrapper}>
                        <div style={style.left}>
                            <HeaderImage image={venue["venueMedia"][0]} />
                            <div
                                style={{
                                    marginTop: "6px",
                                    marginBottom: "6px",
                                }}
                            />
                            <TitleRow title={venue.venueTitle} />
                            {venueGoing ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    <GuestListRow venueGoing={venueGoing} />
                                </>
                            ) : null}
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        </div>
                        <div style={style.right}>
                            <LocationRow
                                address={venue.venueLocation}
                                coordinates={
                                    venue.venueGeolocation
                                        ? {
                                              latitude:
                                                  venue.venueGeolocation.lat,
                                              longitude:
                                                  venue.venueGeolocation.lng,
                                          }
                                        : {
                                              latitude: 37.4275,
                                              longitude: -122.1697,
                                          }
                                }
                            />
                            {venue.venueOpeningHours ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    <HoursRow
                                        venueOpeningHours={
                                            venue.venueOpeningHours
                                        }
                                    />
                                </>
                            ) : null}
                            {venue["venuePhoneNumber"] ? (
                                <>
                                    <div
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                        }}
                                    />
                                    <PhoneRow
                                        venuePhoneNumber={
                                            venue["venuePhoneNumber"]
                                        }
                                    />
                                </>
                            ) : null}
                            <div
                                style={{
                                    marginTop: "10px",
                                    marginBottom: width < 768 ? "60px" : "10px",
                                }}
                            />
                            <GoingRow
                                userId={userAuth ? userAuth.user.uid : null}
                                venue={venue}
                                onClick={() => {
                                    if (userAuth == null) {
                                        setOnLoginCallbackObj({
                                            updateVenueGoingList:
                                                updateVenueGoingList,
                                            func: () => {
                                                return (
                                                    "/venue?venueId=" + venue.id
                                                );
                                            },
                                            name: "navToVenueDetail",
                                        });
                                        navigate("/auth/home");
                                    } else {
                                        updateVenueGoingList(
                                            userAuth.user.uid,
                                            venue
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </LinearGradient>
    );
};

export default Venue;
