import { color } from "../../../../utils/color";

export default {
    container: {
        backgroundColor: color,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: "white",
        flex: 1,
        width: "60%",
        alignSelf: "center",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 0,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    text: {
        fontSize: 17,
        color: "black",
        marginTop: 15,
        marginBottom: 15,
        alignSelf: "center",
    },
};
