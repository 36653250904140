import React, { useState } from "react";
import PropTypes from "prop-types";
import GuestListAvenuMeetTooltip from "../../../events/components/GuestListAvenuMeetTooltip";
import { snow, charcoal } from "../../../../utils/color";
import PopupModal from "../../../../components/hoc/PopupModal";
import { TooltipIcon } from "../../../../utils/icons";
import IconImage from "../../../../components/images/IconImage";
import { TouchableOpacity, View, Text, FlatList} from "react-native";
import { DefaultProfileImage } from "../../utils/images";

import style from "./style";

const renderItem = (item) => {
    const user = item.item
    return (
        <TouchableOpacity
            onPress={() => window.location.replace('https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983')}
            style={style.guest}
        >
            <img src={user.userMedia.length !== 0 ? user.userMedia[0] : DefaultProfileImage} style={style.profileImg} />
        </TouchableOpacity>
    );
};

const getTooltipIconColor = (theme) => (theme === "dark" ? snow : charcoal);

const GuestListRow = (props) => {
    const [displayGuestListTooltip, setDisplayGuestListTooltip] = useState(false);

    return (
    <View style={style.container}>
        <View style={style.wrapper}>
            <Text style={style.emoji}>🕺</Text>
            <Text style={style.subtitle}>AvenuMeet</Text>
            <TouchableOpacity
                onPress={() => setDisplayGuestListTooltip(true)}
                style={{ paddingTop: 18, paddingBottom: 18 }}
            >
                <IconImage
                    theme={props.theme}
                    icon={TooltipIcon}
                    size={16}
                    tintColor={"white"}
                    onPress={() => setDisplayGuestListTooltip(true)}
                    style={style.tooltipButton}
                />
            </TouchableOpacity>
            <Text style={style.emoji}>💃</Text>
        </View>
        <FlatList
            style={style.flatlist}
            contentContainerStyle={style.wrapper}
            horizontal={true}
            data={props.venueGoing}
            renderItem={(item) => renderItem(item)}
        />
        <PopupModal
            displayState={displayGuestListTooltip}
            setDisplayState={setDisplayGuestListTooltip}
            theme={props.theme}
        >
            <GuestListAvenuMeetTooltip theme={props.theme} venue={true} />
        </PopupModal>
    </View>
    );
};

GuestListRow.propTypes = {
};

export default React.memo(GuestListRow);
