import text from "../../../../styles/text";
import { Dimensions } from "react-native";
import { white } from "../../../../utils/color";

export default {
    container: {
        backgroundColor: "black",
        borderRadius: 20,
        width: "100%",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    guest: {
        height: 75,
        width: 75,
        borderRadius: 75 / 2,
        borderWidth: 2,
        borderColor: white,
        marginRight: -7,
    },
    flatlist: {
        marginLeft: 15,
        marginBottom: 10,
        width: Dimensions.get("window").width - 45,
        marginRight: 10,
    },
    subtitle: {
        ...text.subtitleBold,
        color: white,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
    },
    wrapper: {
        flexDirection: "row",
        width: "100%",
    },
    profileImg: {
        height: 72,
        width: 72,
        borderRadius: 36,
    },
    emoji: {
        fontSize: 32,
        marginBottom: 12,
        marginLeft: 10,
        marginTop: 10,
    },
};
