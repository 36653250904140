import { StyleSheet } from "react-native";

import { white, air, ash, black } from "../../../utils/color";
import { family, bold, subtitle } from "../../../utils/font";

export default StyleSheet.create({
  text: {
    minWidth: 100,

    fontFamily: family,
    fontSize: subtitle,
    textAlign: "center",

    color: ash,
  },

  textDark: {
    color: air,
    fontFamily: family,
  },

  textSelected: {
    color: black,
    fontFamily: family,
  },

  textSelectedDarktheme: {
    color: white,
    fontFamily: family,
  },

  textSelectedDark: {
    color: white,
    fontFamily: family,
  },

  textSmall: {
    fontWeight: bold,
    fontFamily: family,
  },
});