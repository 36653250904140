import React, { useState } from "react";
import {
    Keyboard,
    SafeAreaView,
    TouchableWithoutFeedback,
    View,
    TextInput,
    Text,
} from "react-native";
import "./react-phone-number-input-style.css";
import { getCountryCallingCode } from "react-phone-number-input";
import ReactFlagsSelect from "react-flags-select";
import PropTypes from "prop-types";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import BubbleTextField from "../../../../components/fields/BubbleTextField";
import RatioImage from "../../../../components/images/RatioImage";

import { AvenuNameOld, AvenuArrow, AvenuNameNew } from "../../utils/images";
import { PHONE_REGEX } from "../../utils/regex";

import style from "./style";

const handleChangePhone = (event, onChangePhone, countryNum) => {
    let split = event.target.value.split(" ");
    let nonCountryNumber = "";
    if (split.length > 1) {
        nonCountryNumber = split[1];
    } else {
        nonCountryNumber = split[0];
    }
    let next = nonCountryNumber.replace(/(\+1 )|-/g, "");
    next = `${next.substring(0, 3)}-${next.substring(3, 6)}-${next.substring(
        6
    )}`;
    next = next.replace(/-+$/, "");
    next = next !== "" ? `+${countryNum} ${next}` : next;
    onChangePhone(next);
};

const convertPhoneToNumsOnly = (num) => {
    let next = num.replace(/(\+)|-/g, "");
    next = next.replace(/[-+]/, "");
    next = next !== "" ? `+${next}` : next;
    return next;
};

const AuthPhoneView = (props) => {
    const [phone, onChangePhone] = useState("");
    const [selected, setSelected] = useState("US");
    const [countryNum, setCountryNum] = useState("1");

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SafeAreaView style={style.screen}>
                <View style={style.imageContainer}>
                    <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
                </View>
                <View style={style.flagContainer}>
                    <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => {
                            setSelected(code);
                            console.log("code", code);
                            console.log(
                                "callingCode:",
                                getCountryCallingCode(code)
                            );
                            setCountryNum(getCountryCallingCode(code));
                        }}
                        showSelectedLabel={false}
                        showSecondarySelectedLabe={false}
                        placeholder={""}
                        searchPlaceholder={false}
                        className={"countryPicker"}
                        selectButtonClassName={"countryPickerButton"}
                        countries={[
                            "AU",
                            "AT",
                            "BE",
                            "CA",
                            "CN",
                            "DK",
                            "FR",
                            "DE",
                            "IN",
                            "IT",
                            "JP",
                            "MX",
                            "NZ",
                            "NO",
                            "PT",
                            "SG",
                            "ZA",
                            "ES",
                            "SE",
                            "CH",
                            "NL",
                            "TR",
                            "GB",
                            "US",
                        ]}
                    />
                </View>
                <BubbleTextField
                    style={style.phoneNumberButton}
                    text={phone}
                    placeholder="Enter Mobile Number"
                    onChange={(event) =>
                        handleChangePhone(event, onChangePhone, countryNum)
                    }
                    onSubmit={() => {
                        if (phone.match(PHONE_REGEX)) {
                            props.bubbleAction(convertPhoneToNumsOnly(phone));
                        }
                    }}
                    keyboardType="number"
                    inputMode="numeric"
                    pattern="[0-9]*"
                />
                <BubbleButton
                    title={props.bubbleTitle}
                    onPress={(event) => {
                        event.preventDefault();
                        props.bubbleAction(convertPhoneToNumsOnly(phone));
                    }}
                    bubbleStyle={style.buttonBubble}
                    color={"#8ADDFF"}
                    titleStyle={style.buttonTitle}
                    disabled={!phone.match(PHONE_REGEX)}
                />
                <Text style={style.plaintext}>
                    Creating an Avenu profile is an opt-in to receive SMS texts
                    from hosts & friends about event invites, announcements,
                    reminders, and recaps.
                </Text>
                <RatioImage
                    image={AvenuArrow}
                    size={75}
                    ratio={1}
                    style={style.image}
                />
            </SafeAreaView>
        </TouchableWithoutFeedback>
    );
};

AuthPhoneView.propTypes = {
    bubbleTitle: PropTypes.string.isRequired,
    bubbleAction: PropTypes.func.isRequired,
};

export default AuthPhoneView;
