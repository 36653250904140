import React, { useState } from "react";
const UiContext = React.createContext();

function UiProvider(props) {
    const [url, setUrl] = useState(null);
    const [funcOnReturn, setFuncOnReturn] = useState(null);
    return (
        <UiContext.Provider
            value={{
                url: url,
                setUrl: setUrl,
                funcOnReturn: funcOnReturn,
                setFuncOnReturn: setFuncOnReturn
            }}
        >
            {props.children}
        </UiContext.Provider>
    );
}

export { UiContext, UiProvider };
