import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";
import { darkThemeBackground, white } from "../../../../../utils/color";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",

    paddingLeft: 32,
    paddingRight: 32,
  },

  footer: {
    position: "fixed",

    right: 32,
    bottom: 24,
  },

  sheetFooter: {
    position: "absolute",

    right: 30,
    bottom: 52,
  },

  header: {
    ...text.headerBold,

    marginTop: 40,
    marginBottom: 24,
    color: "white"
  },

  subtitle: {
    ...text.subtitleBold,
    color: "white",
    marginLeft: "5vw"
  },

  list: {
    ...views.list,
  },

  modal: {
    height: "100%",
    width: "100%",
  },

  modalCard: {
    height: "90%",
    width: "70%",
    backgroundColor: darkThemeBackground,
    position: "absolute",
    top: "5%",
    left: "15%",
    borderRadius: 20,
  },

  sheet: {
    paddingHorizontal: 24,

    borderRadius: 12,
    backgroundColor: white,
  },

  screen: {
    ...views.screen,
    height: "100%",
    minHeight: "100vh"
  },
});