import text from "../../../../styles/text";
import { color } from "../../../../utils/color";
import { Dimensions } from "react-native";

const { width } = Dimensions.get("window");

export default {
    row: {
        backgroundColor: color,
        borderRadius: 12,
        color: "black",
        paddingRight: 12,
        paddingLeft: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        position: width < 768 ? "fixed" : "relative",
        left: width < 768 ? 12 : 0,
        right: 12,
        bottom: width < 768 ? 10 : 0,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    icon: {
        paddingHorizontal: 12,
        paddingVertical: 13,
    },
    text: {
        fontWeight: "700",
        fontSize: 14,
    },
};
