import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";

import { ash } from "../../../../../utils/color";

export default StyleSheet.create({
  body: {
    ...text.body,
    color: ash,
  },

  container: {
 
    width: "100%",
    paddingLeft: 32,
    paddingRight: 32,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: "10vh"
  },

  footer: {
    alignSelf: "flex-end",
    paddingRight: 32,
    marginBottom: 24,
  },

  header: {
    ...text.headerBold,

    marginTop: 40,
    marginBottom: 24,
    color: "white"
  },

  row: {
    marginBottom: 24,
    width: "40vh",
  },

  screen: {
    ...views.screen,
    alignItems: "flex-end"
  },

  wrapper: {

  },


  map: {
  
    backgroundColor: "white",
    borderRadius: 20,
    height: "40vh",
    overflow: "hidden",
},

  locationOption: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "black",
    borderRadius: 15,
    padding: 10,
    marginBottom: 14,
  },
});