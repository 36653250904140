import React, { useState, useEffect } from "react";
import {
    Keyboard,
    SafeAreaView,
    TouchableWithoutFeedback,
    View,
    Text,
} from "react-native";
import PropTypes from "prop-types";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import BubbleTextField from "../../../../components/fields/BubbleTextField";
import RatioImage from "../../../../components/images/RatioImage";

import { AvenuNameOld, AvenuArrow, AvenuNameNew } from "../../utils/images";

import style from "./style";

const AuthPasswordView = (props) => {
    const [password, onChangePassword] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SafeAreaView style={style.screen}>
                <View style={style.imageContainer}>
                    <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
                </View>
                <BubbleTextField
                    style={style.password}
                    text={password}
                    type={"password"}
                    placeholder="Enter Password"
                    onChange={(event) => onChangePassword(event.target.value)}
                    onSubmit={() => {
                        props.bubbleAction(password);
                    }}
                />
                <BubbleButton
                    title={props.bubbleTitle}
                    onPress={() => {
                        props.bubbleAction(password);
                    }}
                    bubbleStyle={style.buttonBubble}
                    titleStyle={style.buttonTitle}
                />
                {props.loginError && (
                    <Text style={style.errorText}>
                        Invalid phone number / verification password combination
                        Please try again or contact support@avenuteam.com
                    </Text>
                )}
                <RatioImage
                    image={AvenuArrow}
                    size={75}
                    ratio={1}
                    style={style.image}
                />
            </SafeAreaView>
        </TouchableWithoutFeedback>
    );
};

AuthPasswordView.propTypes = {
    bubbleTitle: PropTypes.string.isRequired,
    bubbleAction: PropTypes.func.isRequired,
    loginError: PropTypes.object,
};

AuthPasswordView.defaultProps = {
    loginError: null,
};

export default AuthPasswordView;
