import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const onDisabled = () => {};

const getBubbleStyle = (bubbleStyle, disabled, color) => {
    return [
        style.bubble,
        bubbleStyle,
        disabled
            ? style.disabledBubble
            : { ...style.activeBubble, backgroundColor: color },
    ];
};

const getTitleStyle = (titleStyle, disabled) => {
    return [style.title, titleStyle, disabled && style.disabledTitle];
};

const BubbleButton = (props) => (
    <TouchableOpacity
        onPress={!props.disabled ? props.onPress : onDisabled}
        activeOpacity={props.disabled ? 1 : 0.2}
        style={props.buttonStyle}
    >
        <View
            style={getBubbleStyle(
                props.bubbleStyle,
                props.disabled,
                props.color
            )}
        >
            <Text style={getTitleStyle(props.titleStyle, props.disabled)}>
                {props.title}
            </Text>
        </View>
    </TouchableOpacity>
);

BubbleButton.defaultProps = {
    bubbleStyle: {},
    titleStyle: {},
    disabled: false,
    color: "#1ffdba",
};

BubbleButton.propTypes = {
    title: PropTypes.string.isRequired,
    onPress: PropTypes.func.isRequired,
    bubbleStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    disabled: PropTypes.bool,
    color: PropTypes.string,
};

export default React.memo(BubbleButton);
