import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",

    paddingLeft: 32,
    paddingRight: 32,
  },

  details: {
    flex: 1,
    justifyContent: "center"
  },

  footer: {
    alignSelf: "flex-end",
    marginBottom: 0,
  },

  header: {
    ...text.headerBold,

    marginTop: 40,
    marginBottom: 24,
    color: "white",
  },

  pad: {
    width: 48,
  },

  row: {
    flexDirection: "row",
  },

  screen: {
    ...views.screen,
  },

  spacer: {
    flex: 0.5,
  },

  wrapper: {
   flex: 1
  },
});