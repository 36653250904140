import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";
import { white, darkThemeBackground } from "../../../../../utils/color";

export default StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",

        paddingLeft: 32,
        paddingRight: 32,
    },

    footer: {
        position: "fixed",

        right: 32,
        bottom: 24,
    },

    sheetFooter: {
        position: "absolute",

        right: 30,
        bottom: 52,
    },

    header: {
        ...text.headerBold,

        marginTop: 40,
        marginBottom: 24,
        color: "white",
    },

    subtitle: {
        ...text.subtitleBold,
    },

    list: {
        ...views.list,
    },

    flatlistContent: {
        paddingBottom: 80,
    },

    sheet: {
        paddingHorizontal: 24,

        borderRadius: 12,
        backgroundColor: white,
    },

    modal: {
        height: "100%",
        width: "100%",
    },

    modalCard: {
        height: "90%",
        width: "70%",
        backgroundColor: darkThemeBackground,
        position: "absolute",
        top: "5%",
        left: "15%",
        borderRadius: 20,
    },

    screen: {
        ...views.screen,
        height: "100%",
        minHeight: "100vh",
    },

    text: {
        color: "white",
        fontSize: 15,
        alignSelf: "center",
    },

    downloadbutton: {
        height: "12vh",
        width: "20vh",
        alignSelf: "center",
        marginBottom: 100,
    },

    image: {
        width: "100%",
        height: "100%",
    },
    modal: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },

    limitModalCard: {
        // height: "90%",
        width: "60%",
        backgroundColor: darkThemeBackground,
        position: "absolute",
        top: "40%",
        // left: "15%",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
    },
    modalClose: {
        position: "absolute",
        right: 7,
        top: 7,
    },
    modalTitle: {
        color: "white",
        fontSize: "20px",
    },
    modalText: {
        marginTop: 10,
        textAlign: "center",
        color: "white",
        fontSize: "15px",
    },
});
