export const family = "Barlow";

export const bold = "700";
export const regular = "400";

export const hero = 63;
export const header = 24;
export const title = 20;
export const subtitle = 16;
export const body = 14;
