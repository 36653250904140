import React from "react";
import EventAddressView from "../views/EventAddressView";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";
import { useNavigate } from "react-router-dom";

const EventAddressScreen = (props) => {
    const { address, setAddress, coordinates, setCoordinates } = useContext(CreateEventContext);
    const navigate = useNavigate();

    return (
    <EventAddressView 
        address={address}
        setAddress={setAddress}
        coordinates={coordinates}
        setCoordinates={setCoordinates}
        navigate={navigate}
    />
    );
};

export default EventAddressScreen;