import { StyleSheet } from "react-native";

import { snow, darkThemeBackground } from "../utils/color";

export default StyleSheet.create({
  list: {
    flex: 1,
    width: "100%",
  },

  modal: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",

    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },

  pill: {
    width: 48,
    height: 4,

    marginTop: 16,
    marginBottom: 12,

    borderRadius: 2,
    backgroundColor: snow,
  },

  screen: {
    flex: 1,
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: darkThemeBackground,
    height: "100vh"
  },

  sheet: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 48,

    borderRadius: 16,
  },
});
