import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { SafeAreaView, View, Text } from "react-native";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import LabeledBarTextField from "../../../../components/fields/LabeledBarTextField";
import LabeledPickerField from "../../../../components/fields/LabeledPickerField";
import ProfileEditImage from "../../../../components/custom/ProfileEditImage";

import { GENDERS } from "../../../../utils/constants";

import style from "./style";
import { snow, white, color, sky } from "../../../../utils/color";

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const isCompleted = (fields) => {
    return (
        fields.userFirst !== "" &&
        fields.userLast !== "" &&
        // fields.userGender !== "" &&
        fields.userMedia.length !== 0 &&
        fields.userBirthdate &&
        !isNaN(fields.userBirthdate) &&
        isValidEmail(fields.userEmail)
    );
};

const RegistrationView = (props) => {
    const [firstName, onChangeFirstName] = useState("");
    const [lastName, onChangeLastName] = useState("");
    const [email, onChangeEmail] = useState(props.userAuth.user.email ?? "");
    const [birthdateString, setBirthdateString] = useState("");
    const [birthdate, setBirthDate] = useState(
        props.userAuth.user.email ? new Date(2023, 0, 1) : null
    );
    const [gender, onChangeGender] = useState(
        props.userAuth.user.email ? "Prefer not to say" : ""
    );
    const [media, setMedia] = useState([]);
    const [education, onChangeEducation] = useState("");
    const [work, onChangeWork] = useState("");

    const [invitedBy, setInvitedBy] = useState("");

    const [showBirthdatePicker, setShowBirthdatePicker] = useState(false);
    const [showGenderPicker, setShowGenderPicker] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const profile = {
        userFirst: firstName,
        userLast: lastName,
        userSearch: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
        userHandle: "",
        userPhone: props.userAuth.user.phoneNumber,
        userEmail: email,
        userBio: "",
        userBirthdate: birthdate,
        userGender: gender,
        userSchool: education,
        userMedia: media,
        userInvitedBy: invitedBy,
        userStatus: { statusType: "NOT SURE", updatedAt: new Date() },
        userFriends: [],
        userMeets: [],
        userRequests: [],
        userRequestsMeet: [],
        userBlocks: [],
        userTokens: [],
        userPrivacy: true,
        userWork: work,
        userInstagram: "",
    };

    const firstNameCharLimit = 30;
    const lastNameCharLimit = 30;
    const invitedByCharLimit = 30;
    const workEducationCharLimit = 20;

    const handleChangeBirthdateString = (birthdateString) => {
        const formattedText = formatBirthdate(birthdateString);
        setBirthdateString(formattedText);
        if (birthdateString.length !== 10 && birthdateString.length !== 8) {
            setBirthDate(null);
        } else {
            const date = new Date(birthdateString);
            setBirthDate(date);
        }
    };

    const formatBirthdate = (input) => {
        // format the birthday input automatically
        let formattedText = input.replace(/[^0-9]/g, ""); // remove non-numeric characters

        if (formattedText.length > 2 && formattedText.length <= 4) {
            // Add slash after the month
            formattedText =
                formattedText.slice(0, 2) + "/" + formattedText.slice(2);
        } else if (formattedText.length > 4) {
            // Add slashes after the month and day
            formattedText =
                formattedText.slice(0, 2) +
                "/" +
                formattedText.slice(2, 4) +
                "/" +
                formattedText.slice(4, 8);
        }

        return formattedText;
    };

    const handleEnter = () => {
        if (isCompleted(profile)) {
            props.createProfile(profile);
        }
    };

    return (
        <SafeAreaView style={style.screen}>
            <View style={style.wrapper}>
                <ProfileEditImage image={media[0]} onPress={setMedia} />
                {props.userAuth.user.phoneNumber ? (
                    <Text style={style.text}>
                        Show off your fabulous face for secure, hassle-free
                        event entry! 😁
                    </Text>
                ) : null}
                {media.length !== 0 ? (
                    <View style={style.wrapper}>
                        <LabeledBarTextField
                            theme={props.theme}
                            label={
                                props.userAuth.user.phoneNumber
                                    ? "First Name"
                                    : "Organization Name"
                            }
                            text={firstName}
                            required={true}
                            placeholder={
                                props.userAuth.user.phoneNumber
                                    ? "Enter Your First Name"
                                    : "Enter Your Organization Name"
                            }
                            charLimit={firstNameCharLimit}
                            rightLabel={`${firstName.length}/${firstNameCharLimit}`}
                            onChange={onChangeFirstName}
                            onSubmit={handleEnter}
                            onFocused={() => {
                                setShowBirthdatePicker(false);
                                setShowGenderPicker(false);
                            }}
                            style={{
                                ...style.textfield,
                                backgroundColor: "#2A2A2A",
                            }}
                        />
                        <LabeledBarTextField
                            theme={props.theme}
                            label={
                                props.userAuth.user.phoneNumber
                                    ? "Last Name"
                                    : "City or Campus"
                            }
                            text={lastName}
                            required={true}
                            placeholder={
                                props.userAuth.user.phoneNumber
                                    ? "Enter Your Last Name"
                                    : "Enter Your City or Campus"
                            }
                            charLimit={lastNameCharLimit}
                            rightLabel={`${lastName.length}/${lastNameCharLimit}`}
                            onChange={onChangeLastName}
                            onSubmit={handleEnter}
                            onFocused={() => {
                                setShowBirthdatePicker(false);
                                setShowGenderPicker(false);
                            }}
                            style={{
                                ...style.textfield,
                                backgroundColor: "#2A2A2A",
                            }}
                        />
                        {props.userAuth.user.phoneNumber ? (
                            <>
                                <LabeledBarTextField
                                    theme={props.theme}
                                    label="Birthdate"
                                    text={birthdateString}
                                    placeholder="MM/DD/YYYY"
                                    keyboardType="numeric"
                                    required={true}
                                    onChange={handleChangeBirthdateString}
                                    onSubmit={handleEnter}
                                    onFocused={() => {
                                        setShowBirthdatePicker(false);
                                        setShowGenderPicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />
                                <LabeledBarTextField
                                    theme={props.theme}
                                    label="Email"
                                    text={email}
                                    required={true}
                                    // leftLabel=" (optional)"
                                    onChange={onChangeEmail}
                                    onSubmit={handleEnter}
                                    onFocused={() => {
                                        setShowBirthdatePicker(false);
                                        setShowGenderPicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />
                                <LabeledPickerField
                                    theme={props.theme}
                                    label="Gender Identity"
                                    value={gender}
                                    required={false}
                                    leftLabel=" (optional)"
                                    values={GENDERS}
                                    onChangeValue={onChangeGender}
                                    focused={showGenderPicker}
                                    onFocused={() => {
                                        setShowGenderPicker(!showGenderPicker);
                                        setShowBirthdatePicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        fontFamily: "arial",
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />
                                <LabeledBarTextField
                                    theme={props.theme}
                                    label="Education"
                                    text={education}
                                    required={false}
                                    charLimit={workEducationCharLimit}
                                    leftLabel=" (optional)"
                                    rightLabel={`${education.length}/${workEducationCharLimit}`}
                                    onChange={onChangeEducation}
                                    onSubmit={handleEnter}
                                    onFocused={() => {
                                        setShowBirthdatePicker(false);
                                        setShowGenderPicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />
                                <LabeledBarTextField
                                    theme={props.theme}
                                    label="Work"
                                    text={work}
                                    required={false}
                                    charLimit={workEducationCharLimit}
                                    leftLabel=" (optional)"
                                    rightLabel={`${work.length}/${workEducationCharLimit}`}
                                    onChange={onChangeWork}
                                    onSubmit={handleEnter}
                                    onFocused={() => {
                                        setShowBirthdatePicker(false);
                                        setShowGenderPicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />{" "}
                                <LabeledBarTextField
                                    theme={props.theme}
                                    label={"Invited By"}
                                    text={invitedBy}
                                    required={false}
                                    placeholder={"First & Last Name"}
                                    charLimit={invitedByCharLimit}
                                    leftLabel=" (optional)"
                                    rightLabel={`${invitedBy.length}/${invitedByCharLimit}`}
                                    onChange={setInvitedBy}
                                    onSubmit={handleEnter}
                                    onFocused={() => {
                                        setShowBirthdatePicker(false);
                                        setShowGenderPicker(false);
                                    }}
                                    style={{
                                        ...style.textfield,
                                        backgroundColor: "#2A2A2A",
                                    }}
                                />
                            </>
                        ) : null}
                        <View style={{ marginTop: 120 }} />
                    </View>
                ) : null}
                <BubbleButton
                    title="Confirm"
                    onPress={() => props.createProfile(profile)}
                    buttonStyle={style.buttonStyle}
                    bubbleStyle={style.buttonBubble}
                    titleStyle={style.buttonTitle}
                    disabled={!isCompleted(profile)}
                    color={sky}
                />
            </View>
        </SafeAreaView>
    );
};

RegistrationView.propTypes = {
    userPhone: PropTypes.string.isRequired,
    createProfile: PropTypes.func.isRequired,
};

export default RegistrationView;
