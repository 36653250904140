export const Calendar = require("../assets/images/calendar@3x.png");
export const Camera = require("../assets/images/camera.png");
export const Check = require("../assets/images/check.png");
export const Plus = require("../assets/images/plus.png");
export const OldCheckIcon = require("../assets/images/icon-check.png");
export const OldPauseIcon = require("../assets/images/icon-pause.png");
export const OldStopIcon = require("../assets/images/icon-cancel.png");

export const Dual = require("../assets/images/shadow-dual.png");
export const Shadow = require("../assets/images/shadow.png");

export const DefaultEventImage = require("../assets/images/default-event-image.png");
export const DefaultProfileImage = require("../assets/images/default-profile.jpeg");

export const DefaultVenueImageLink =
  "https://firebasestorage.googleapis.com/v0/b/avenu-2bd9c.appspot.com/o/avenu%2Fdefault-venue.png?alt=media&token=9ad119f1-326e-4259-932f-dca2624b844d";