import React from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const ActionRow = (props) => (
    <TouchableOpacity style={style.wrapper} onPress={props.onPress}>
      <View style={style.container}>
      <View style={{ flexDirection: "row", alignItems: "center"}}>
        <Image
          source={props.icon}
          style={style.icon}
        />
          <Text style={style.text}>
            {props.title}
          </Text>
        </View>
        {props.tooltip}
      </View>
    </TouchableOpacity>

);

ActionRow.defaultProps = {
  tooltip: null,
};

ActionRow.propTypes = {
  icon: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  tintColor: PropTypes.string,
  tooltip: PropTypes.element,
};

export default React.memo(ActionRow);