import React from "react";
import EventDescriptionView from "../views/EventDescriptionView";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";
import { useNavigate } from "react-router-dom";

const EventDescriptionScreen = (props) => {
    const { description, onChangeDescription } = useContext(CreateEventContext);
    const navigate = useNavigate();

    return (
        <EventDescriptionView
            description={description}
            onChangeDescription={onChangeDescription}
            navigate={navigate}
        />
    );
}

export default EventDescriptionScreen;