import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const onDisabled = () => {};

const getOnPress = (disabled, onPress) => (disabled ? onDisabled : onPress);
const getOpacity = (disabled) => (disabled ? 1.0 : 0.2);

const getBarStyle = (disabled, barStyle) => ({
  ...style.bar,
  ...(disabled && style.barDisabled),
  ...barStyle,
});

const getTitleStyle = (disabled) => (disabled ? [style.title, style.titleDisabled] : style.title);

const ActionButton = (props) => (
  <TouchableOpacity
    onPress={getOnPress(props.disabled, props.onPress)}
    activeOpacity={getOpacity(props.disabled)}
    style={props.touchableStyle}
  >
    <View style={getBarStyle(props.disabled, props.style)}>
      <Text style={getTitleStyle(props.disabled)}>{props.title}</Text>
    </View>
  </TouchableOpacity>
);

ActionButton.defaultProps = {
  disabled: false,
  style: {},
  touchableStyle: {},
};

ActionButton.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  touchableStyle: PropTypes.object,
};

export default React.memo(ActionButton);