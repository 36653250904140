import React, { useContext, useRef } from "react";
import { FlatList, View, Text, SafeAreaView } from "react-native";
import PropTypes from "prop-types";

import EmptyRow from "../../../../components/rows/EmptyRow";
import EventRow from "../../components/EventRow";

import { Calendar } from "../../../../utils/images";

import style from "./style";
import { EventContext } from "../../EventContext";
import { EditIcon } from "../../../../utils/icons";

// import VenueRow from "../../../venues/components/VenueRow";

const renderItem = (item, navigate, setCurrEventId, readEvent, users, userId, route, upcoming, recurring) => {
  let eventGuests = {};
  if (route === "EventsFeed") {
    const eventInvited = [];
    const eventGoing = [];
    let eventInvitedContacts = [];

    if (item.eventInvitedUsers) {
      const eventInvitedIds = item.eventInvitedUsers;
      const eventGoingIds = Array.from(new Set([...item.eventHosts, ...item.eventGoingUsers]));

      eventInvitedIds.forEach((id) => {
        const user = users[id];
        if (user !== undefined && !eventGoingIds.includes(id)) {
          eventInvited.push(user);
        }
      });

      eventGoingIds.forEach((id) => {
        const user = users[id];
        if (user !== undefined) {
          eventGoing.push(user);
        }
      });

      if (item.eventContacts) {
        const formedEventContacts = [];
        if (item.eventContacts) {
          item.eventContacts.forEach((contact) => {
            formedEventContacts.push({
              userFirst: contact.givenName,
              userLast: contact.familyName ? contact.familyName : "",
              userMedia: [],
              id: null,
              userMeets: [],
            });
          });
        }
        eventInvitedContacts = formedEventContacts;
      }

      eventGoing.reverse();

      eventGuests = {
        invitedUsers: eventInvited,
        goingUsers: eventGoing,
        invitedContacts: eventInvitedContacts,
      };
    }
  }

  let invitedBy = null;
  if (item.eventInvites && item.eventInvites[userId]) {
    invitedBy = users[item.eventInvites[userId]];
  } else if (item.venueInvites && item.venueInvites[userId]) {
    invitedBy = users[item.venueInvites[userId]];
  }

  return  (
    <EventRow
      users={users}
      userId={userId}
      invitedBy={invitedBy}
      image={item.eventMedia[0]}
      title={item.eventTitle}
      time={item.eventStart}
      navigate={navigate}
      eventGuests={eventGuests}
      eventHosts={item.eventHosts}
      hideGuests={item.eventHideGuests}
      gradientIndex={item.eventGradientIndex}
      venue=""
      icon={upcoming ? EditIcon : null}
      iconStyle={{alignSelf: "flex-end", marginTop: 10, marginRight: 10}}
      onPressIcon={() => {
        setCurrEventId(item.id);
        readEvent(item.id, navigate, "/event/edit");
      }}
      iconSize={30}
      onPress={() => {
        if (recurring) {
          navigate(`/recurring_event?eventId=${item.id}`) 
        }
        else {
          setCurrEventId(item.id);
          readEvent(item.id, navigate);
        }
      }}
      dayOfWeek={item.dayOfWeek}
      recurring={recurring}
    />
  );
};

const renderEmpty = () => (
  <View>
    <EmptyRow icon={Calendar} title="No Events" />
    <Text
      style={{ fontSize: 16, opacity: 0.2, textAlignVertical: "center", textAlign: "center", color: "white" }}
    >
      Seems a little quiet right now. Create your own event, search a code, discover nearby venues,
      or check your friends' statuses to see what they're up to tonight!
    </Text>
  </View>
);

const keyExtractor = (item) => item.id;

const EventsListView = (props) => {
  const { readEvent } = useContext(EventContext);

  // const data = props.venues ? props.venues.concat(props.data) : props.data;

  return (
    <SafeAreaView style={style.screen}>
      <FlatList
        style={[style.list, style.listAddOn]}
        contentContainerStyle={{alignItems: "center"}}
        data={props.data}
        removeClippedSubviews={true}
        initialNumToRender={10}
        renderItem={({ item }) =>
          renderItem(item, props.navigate, props.setCurrEventId, readEvent, props.users, props.userId, props.route, props.upcoming, props.recurring)
        }
        keyExtractor={keyExtractor}
        ListEmptyComponent={renderEmpty}
        onRefresh={props.onRefresh}
        onEndReached={props.onEndReached}
        onEndReachedThreshold={0.3}
        refreshing={false}
      />
    </SafeAreaView>
  );
};

EventsListView.defaultProps = {
  onRefresh: () => {},
};

EventsListView.propTypes = {
  data: PropTypes.array.isRequired,
  navigation: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  users: PropTypes.object,
  route: PropTypes.string.isRequired,
  recurring: PropTypes.bool,
};

export default EventsListView;
