import { StyleSheet } from "react-native";

export default StyleSheet.create({
    code: {
        flex: 1,
    },

    container: {
        width: "93%",
        justifyContent: "center",
        alignItems: "center",

        aspectRatio: 0.8,
        borderRadius: 16,

        backgroundColor: "white",
    },

    qrContainer: {
        justifyContent: "center",
        alignItems: "center",
    },

    iconWrapper: {
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
    },

    icon: {
        width: 48,
        height: 48,
    },

    modal: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "40%",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
    },

    user: {
        marginLeft: 20,
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-start",
    },

    userText: {
        marginLeft: 7,
        fontSize: 18,
    },

    details: {
        fontSize: 18,
        alignSelf: "flex-end",
        marginRight: 20,
    },

    profileImg: {
        height: 40,
        width: 40,
        borderRadius: 36,
    },
});
