import React from "react";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";
import { charcoal, white, black } from "../../../../utils/color";

import style from "./style";

const NoTicketTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>No Tickets</Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      RSVPs for Guest List only. Non-ticketed events do not issue tickets for guests and do not have
      an attendance limit. For free events with limited capacity, set a ticket limit to a Free
      General Admission ticket type.
      {"\n\n"}If you choose to create a non-ticketed event, you cannot later edit to issue or sell
      tickets through Avenu.
    </Text>
  <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

NoTicketTooltip.defaultProps = {};

NoTicketTooltip.propTypes = {};

export default React.memo(NoTicketTooltip);