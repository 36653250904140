import { Dimensions, StyleSheet } from "react-native";

import { color, darkThemeBackground } from "../../../../utils/color";
import viewStyles from "../../../../styles/viewStyles";

const { width: screenWidth } = Dimensions.get("window");

export default StyleSheet.create({
    screen: {
        ...viewStyles.screen,
        backgroundColor: darkThemeBackground,
        height: "100vh",
    },

    imageContainer: {
        margin: 32,
        marginBottom: 150,
        borderRadius: 10,

        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    flagContainer: {
        marginLeft: 24,
        marginRight: 24,
        zIndex: 10,
        width: screenWidth * 0.85,
    },

    phoneNumberButton: {
        width: screenWidth * 0.85,
    },

    buttonBubble: {
        backgroundColor: "#8ADDFF",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
        width: screenWidth * 0.85,
    },

    plaintext: {
        paddingTop: 15,
        paddingBottom: 30,
        paddingHorizontal: 20,
        fontSize: 14,
        width: "100%",
        color: "white",
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center",
    },

    buttonTitle: {
        color: "black",
    },

    image: {
        tintColor: "white",
    },

    spacer: {
        flex: 1,
    },
});
