import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";

const SplashScreen = (props) => {
    const { userAuth, setOnLoginCallbackObj } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (userAuth && userAuth.user.uid) {
            navigate("/event/host")
        }
        else {
            setOnLoginCallbackObj({
                func: () => {
                    return "/event/host"
                },
                name: "navToHost",
            });
            navigate("/auth/home")
        }
    }, [userAuth])
}

export default SplashScreen;