export const generateId = () => {
  const id = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 28; i++) {
    id.push(characters.charAt(Math.floor(Math.random() * characters.length)));
  }

  return id.join("");
};

export const generateEventRef = () => {
  const id = [];
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

  id.push("#");
  for (let i = 0; i < 6; i++) {
    id.push(characters.charAt(Math.floor(Math.random() * characters.length)));
  }

  return id.join("");
};
