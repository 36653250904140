import text from "../../../../styles/text";
import { steel } from "../../../../utils/color"

export default {
    container: {
        backgroundColor: "black",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    body: {
        fontSize: 16,
        color: "white",
        marginTop: 20,
        marginBottom: 24,
        marginLeft: 21,
        marginRight: 19,
    },
};
