import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import EventTicketsView from "../views/EventTicketsView";
import { CreateEventContext } from "../CreateEventContext";
import { useLocation } from "react-router-dom";

const EventTicketsScreen = (props) => {
    const {
        tickets, setTickets, 
        link, setLink, 
        uiCompleted, setUiCompleted,
    } = useContext(CreateEventContext);
    const navigate = useNavigate();
    return (
        <EventTicketsView
            comingFrom={useLocation().pathname}
            tickets={tickets}
            setTickets={setTickets}
            link={link}
            setLink={setLink}
            uiCompleted={uiCompleted}
            setUiCompleted={setUiCompleted}
            navigate={navigate}
        />
    );
};

export default EventTicketsScreen;