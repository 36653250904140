import React, { useState, useEffect, useContext } from "react";
import AuthPhoneView from "../views/AuthPhoneView";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const AuthPhoneScreen = (props) => {
  const {
    code,
  } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  return (
    <>
      <AuthPhoneView
        bubbleTitle="Enter"
        bubbleAction={(phone) => {
          code(phone);
          navigate("/auth/code");
        }}
      />
    </>
  );
};
export default AuthPhoneScreen;
