import { StyleSheet } from "react-native";

export default StyleSheet.create({
    empty: {
        flex: 1,
        marginLeft: 32,
        marginRight: 32,
        marginTop: 24,
        marginBottom: 24,

        textAlign: "center",
    },

    list: {
        flex: 1,
        width: "100%",
    },

    screen: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center",
    },
});
