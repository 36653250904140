/**
 * Checks if string value is empty
 * @param {string} value 
 * @returns {boolean}
 */
export const isEmpty = (value) => !value || value === "";

/**
 * Converts integer amount to String in dollar format
 * @param {number} amount - integer amount equivalent to total cents
 * @returns {string}
 */
export const convertIntegerAmountToDollarString = (amount) => {
  if (amount >= 100) {
    return String((amount / 100).toFixed(2));
  } else if (amount === 0) {
    return "0.00";
  } else {
    return "0." + String(amount);
  }
};
