import React from "react";
import { View, Text, } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";
import { charcoal, white, black } from "../../../../utils/color";

import style from "./style";

const AnnounceEventTooltip = ({ theme }) => {
  return (
    <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
      <Text style={[style.header, { color: theme === "dark" ? white : black }]}>
        Announce Event
      </Text>
      <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
        Non-private events are announced with a push notification to all friends of the event
        creator. Verified users can request their event be announced to all nearby Avenu users
      </Text>
      <View style={[style.collection, { marginTop: 25 }]}>
        <DisabledSwitch />
      </View>
    </View>
  );
};

AnnounceEventTooltip.defaultProps = {};

AnnounceEventTooltip.propTypes = {};

export default React.memo(AnnounceEventTooltip);
