import { StyleSheet } from "react-native";
import { darkThemeBackground } from "../../../utils/color";

export default StyleSheet.create({
    container: {
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: darkThemeBackground,
        width: "100%",
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: darkThemeBackground,
    },
    loginButtonText: {
        padding: 10,
        color: "white",
        alignSelf: "center",
        fontWeight: "bold",
    },
    avenuLogo: {
        height: 40,
    },
    item: {
        alignSelf: "flex-start",
        justifySelf: "flex-start",
        marginLeft: 10,
        borderRadius: 8,
        height: "100%",
    },
    title: {
        color: "white",
        fontWeight: "bold",
        fontSize: 20,
    },
    titleBox: {
        marginLeft: -40,
        alignSelf: "center",
    },
    spacer: {},
});
