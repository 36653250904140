import React, { useState, useEffect, useContext } from "react";

import AuthPasswordView from "../views/AuthPasswordView";
import { AuthContext } from "../AuthContext";
import { UiContext } from "../../../uiContext";

import { useNavigate } from "react-router-dom";
import { findAllByTestId } from "@testing-library/react";
import { EventContext } from "../../events/EventContext";
import { VenueContext } from "../../venues/VenueContext";

const AuthPasswordScreen = (props) => {
    const navigate = useNavigate();
    const {
        confirm,
        user,
        userFound,
        userAuth,
        setUserAuth,
        loginEmail,
        readUserIfPresent,
        onLoginCallbackObj,
        setOnLoginCallbackObj,
        error,
    } = useContext(AuthContext);
    const { url, setUrl } = useContext(UiContext);
    const { event, buyFreeTicket, updateGoingUsers } = useContext(EventContext);
    const { venue, updateVenueGoingList } = useContext(VenueContext);

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        if (userFound === true && user) {
            console.log("DEBUG userfound..?", user, "userAUth:", userAuth);
            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] == buyFreeTicket
            ) {
                // if (
                //     !event.eventPrivacy ||
                //     event.eventInvitedUsers.includes(userAuth.user.uid) ||
                //     event.eventContacts
                //         .map((contact) => contact.phoneNumber)
                //         .includes(user.userPhone.slice(-10))
                // )
                    onLoginCallbackObj["func"](userAuth.user.uid, event);
                console.log("DEBUG fired onLoginCallbackObj");
                navigate(url);
                setUrl(null);
            } else if (onLoginCallbackObj &&
                onLoginCallbackObj["updateGoingUsers"] == updateGoingUsers
            ) {
                onLoginCallbackObj["updateGoingUsers"](userAuth.user.uid, event);
                onLoginCallbackObj["addEventCodeToUser"](userAuth.user.uid, event);
                console.log("DEBUG fired onLoginCallbackObj");
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"],
                    user
                );
                console.log("DEBUG navpath fired", navPath);
                navigate(navPath);
            } else if (
                (onLoginCallbackObj &&
                    onLoginCallbackObj["name"] == "navToTickets") ||
                onLoginCallbackObj["name"] == "navToCreate" ||
                onLoginCallbackObj["name"] == "navToHost"
            ) {
                let navPath = onLoginCallbackObj["func"];
                console.log("navToName fired", navPath);
                navigate(navPath());
            } 
            else if (onLoginCallbackObj["name"] == "navToVenueDetail") {
                updateVenueGoingList(userAuth.user.uid, venue);
                let navPath = onLoginCallbackObj["func"];
                console.log("navToName fired", navPath);
                navigate(navPath());
            }
            else if (onLoginCallbackObj.name === "navToEdit") {
                let navPath = onLoginCallbackObj.func;
                console.log("navToEdit fired", navPath);
                navigate(navPath().route, { state: navPath().params });
            } else {
                navigate(-3);
                //navigate(url)
                //setUrl(null)
            }
        } else if (userFound === false) {
            navigate("/auth/signup");
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                userFound,
                user
            );
        }

        console.log("DEBUG user fired for some reason", user);
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <AuthPasswordView
                bubbleTitle="Login"
                bubbleAction={(password) => loginEmail(password)}
                loginError={error}
            />
        </>
    );
};

export default AuthPasswordScreen;
