
import React, { useContext, useEffect } from "react";
import EventLinksView from "../views/EventLinksView";

import { useNavigate } from "react-router-dom";
import { CreateEventContext } from "../CreateEventContext";

const EventLinksScreen = (props) => {
    const { eventOtherLinks, setEventOtherLinks, uiCompleted, setUiCompleted } =
        useContext(CreateEventContext);
    const navigate = useNavigate();

  return (
    <EventLinksView
      links={eventOtherLinks}
      setLinks={setEventOtherLinks}
      navigate={navigate}
      uiCompleted={uiCompleted}
      setUiCompleted={setUiCompleted}
    />
  );
};


export default EventLinksScreen;
