import React, { useState, useEffect, useContext } from "react";
// import db, { auth } from "../../../firebaseConfig";
import AuthEmailView from "../views/AuthEmailView";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
// import { fetchSignInMethodsForEmail } from "firebase/auth";

const AuthEmailScreen = (props) => {
    const { navigateSignIn } = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigate = useNavigate();
    return (
        <>
            <AuthEmailView
                bubbleTitle="Enter"
                bubbleAction={(email) => {
                    // code(phone);
                    // navigate("/auth/password");
                    navigateSignIn(email, navigate);
                }}
            />
        </>
    );
};
export default AuthEmailScreen;
