import React, { useEffect, useState } from "react";
import { View, FlatList, Text, TouchableOpacity } from "react-native-web";
import PropTypes from "prop-types";
import { DefaultProfileImage } from "../../utils/images"

import GuestListAvenuMeetTooltip from "../GuestListAvenuMeetTooltip";
import PopupModal from "../../../../components/hoc/PopupModal";
import IconImage from "../../../../components/images/IconImage";
import { TooltipIcon } from "../../../../utils/icons";
import { snow, charcoal } from "../../../../utils/color";
import style from "./style";

const renderItem = (item) => {
    let user = item.item;
    if (user.id === "extra") {
        return (<TouchableOpacity
            onPress={() => window.location.replace('https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983')}
            style={style.extra}
        >
            <Text style={{ ...style.extraText, fontSize: user.size }}>{user.text}</Text>
        </TouchableOpacity>)
    }
    else if (user.id === "contact") {
        return (
            <TouchableOpacity
                onPress={() => window.location.replace('https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983')}
                style={[style.guest, { backgroundColor: user.backgroundColor },]}
            >
                <Text style={style.contactText}>
                    {user.givenName ? `${user.givenName[0]}` : null}
                    {user.familyName ? `${user.familyName[0]}` : null}
                </Text>
            </TouchableOpacity>
        )
    }
    return (
        <TouchableOpacity
            onPress={() => window.location.replace('https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983')}
            style={style.guest}
        >
            <img key={user.userPhone} src={user.userMedia.length !== 0 ? user.userMedia[0] : DefaultProfileImage} style={style.profileImg} />
        </TouchableOpacity>
    );
};

const getTooltipIconColor = (theme) => (theme === "dark" ? snow : charcoal);

const GuestListRow = (props) => {
    const [guestItems, setGuestItems] = useState(props.eventGuestsUsers);
    const [contactItem, setContactItem] = useState(props.eventContacts);
    const [displayGuestListTooltip, setDisplayGuestListTooltip] = useState(false);

    // This is for the guestlist to update correctly with the updated guestlist without having to refresh //
    useEffect(() => {
        setGuestItems(props.eventGuestsUsers);
    }, [props.eventGuestsUsers]);

    useEffect(() => {
        if (contactItem && guestItems.length + contactItem.length > 50) {
            const slicedGuestItems = guestItems.slice(0, 50);
            slicedGuestItems.push({
                id: "extra",
                text: `+${guestItems.length + contactItem.length - 50}`,
                size: guestItems.length + contactItem.length >= 1000 ? 16 : 20,
            })
            setGuestItems(slicedGuestItems);
        }
    }, []);

    return (
        <View style={style.container}>
            <View style={{...style.wrapper, flexDirection: 'row', alignItems: 'center'}}>
                <img src={require("../../../../assets/icons/icon-avenumeet1.png")} style={{...style.image, width: '40px', height: '40px', marginLeft: '10px'}} />
                <Text style={{...style.subtitle, marginBottom: '20px'}}>AvenuMeet</Text>
                <TouchableOpacity
                    onPress={() => setDisplayGuestListTooltip(true)}
                    style={{ paddingTop: 12, paddingBottom: 18 }}
                >
                    <IconImage
                        theme={props.theme}
                        icon={TooltipIcon}
                        size={16}
                        tintColor={"white"}
                        onPress={() => setDisplayGuestListTooltip(true)}
                        style={style.tooltipButton}
                    />
                </TouchableOpacity>
                <img src={require("../../../../assets/icons/icon-avenumeet2.png")} style={{...style.image, width: '40px', height: '40px', marginLeft: '10px'}} />
            </View>
            <FlatList
                style={style.flatlist}
                contentContainerStyle={style.wrapper}
                horizontal={true}
                data={contactItem ? guestItems.concat(contactItem) : guestItems}
                renderItem={(item) => renderItem(item)}
                showsHorizontalScrollIndicator={false} // Add this line to hide the scroll bar
            />
            <PopupModal
                displayState={displayGuestListTooltip}
                setDisplayState={setDisplayGuestListTooltip}
                theme={props.theme}
            >
                <GuestListAvenuMeetTooltip theme={props.theme} venue={false}/>
            </PopupModal>
        </View>
    );
}


export default GuestListRow;
