import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";

import style from "./style";

const dayToNameMap = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
};

const convertTimeToString = (time) => {
    const timeInt = parseInt(time);
    let hour = parseInt(timeInt / 100);
    let minute = timeInt % 100;
    let suffix = "";
    if (hour < 12) {
        suffix = " am";
    } else {
        suffix = " pm";
        if (hour !== 12) {
            hour = hour - 12;
        }
    }

    if (minute < 10) {
        minute = "0" + minute.toString();
    }
    return hour.toString() + ":" + minute.toString() + suffix;
};

const HoursRow = (props) => {
    return (
        <View style={style.container}>
            <View style={style.wrapper}>
                <Text style={style.emoji}>🕙</Text>
                <Text style={style.subtitle}>Hours of Operation</Text>
            </View>
            {props.venueOpeningHours.periods.length > 1
                ? props.venueOpeningHours.periods.map((period, i) => (
                      <View style={style.row}>
                          {period.close && period.open && (
                              <>
                                  <Text style={style.day}>
                                      {dayToNameMap[i]}:{" "}
                                  </Text>
                                  <Text style={style.time}>
                                      {convertTimeToString(period.open.time)} -{" "}
                                      {convertTimeToString(period.close.time)}
                                  </Text>
                              </>
                          )}
                      </View>
                  ))
                : props.venueOpeningHours.weekday_text.map((text, i) => (
                      <View style={style.row}>
                          <Text style={style.day}>
                              {text.trim().split(/\s+/)[0]}
                          </Text>
                          <Text style={style.time}>
                              {text.replace(
                                  `${
                                      i === 6
                                          ? dayToNameMap[0]
                                          : dayToNameMap[i + 1]
                                  }:`,
                                  ""
                              )}
                          </Text>
                      </View>
                  ))}
        </View>
    );
};

HoursRow.propTypes = {
    venueOpeningHours: PropTypes.object.isRequired,
};

export default React.memo(HoursRow);
