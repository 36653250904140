import { color, red } from "../../../../utils/color";

export default {
    icon: {
        paddingHorizontal: 12,
        paddingVertical: 10,
    },
    unlockIcon: {
        paddingHorizontal: 8,
    },
    row: {
        flex: 1,
        flexDirection: "row",
    },
    text: {
        color: "white",
        // fontWeight: "700",
        fontSize: "18px",
    },
    releaseTime: {
        color: "white",
        // fontWeight: "700",
        fontSize: "14px",
        marginTop:'0.22rem'
    },
    ticketNameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    soldOutText: {
        color: "white",
        fontWeight: "700",
        fontSize: "18px",
        paddingRight: 20,
    },
    quantityContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 40,
        // paddingRight: 10,
    },
    quantityDisplayContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 40,
        paddingRight: 12,
    },
    quantityText: {
        color: "white",
        // fontWeight: "700",
        fontSize: "15px",
    },
    quantity: {
        color: "white",
        // fontWeight: "700",
        fontSize: "15px",
        // paddingRight: 20,
        // marginTop: 13,
    },
    subtitle: {
        color: "gray",
        fontSize: "17px",
        // fontWeight: "700",
        paddingTop: "5px",
    },
    ticketText: {
        color: "white",
        fontSize: "17px",
        fontWeight: "700",
        paddingTop: "5px",
    },
    description: {
        flex: 1,
        flexDirection: "column",
        marginLeft: "30px",
        marginBottom: 40,
    },
    priceContainer: {
        flexDirection: "row",
        alignItems: "baseline",
    },
    feesText: {
        color: "gray",
        marginLeft: 5,
        fontSize: "10px",
    },
};
