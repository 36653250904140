import React from "react";
import PropTypes from "prop-types";

import BarTextField from "../BarTextField";
import LabeledComponent from "../../hoc/LabeledComponent";

const LabeledBarTextField = LabeledComponent((props) => (
    <BarTextField
        theme={props.theme}
        text={props.text}
        keyboardType={props.keyboardType}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        onFocused={props.onFocused}
        style={props.style}
        editable={props.editable}
        multiline={props.multiline}
        maxLength={props.charLimit}
    />
));

LabeledBarTextField.defaultProps = {
    placeholder: "",
    onChange: () => {},
    onFocused: () => {},
    editable: true,
    requiredStyle: {},
    style: {},
};

LabeledBarTextField.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    required: PropTypes.bool,
    multiline: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    editable: PropTypes.bool,
    charLimit: PropTypes.number,
    style: PropTypes.object,
};

export default React.memo(LabeledBarTextField);
