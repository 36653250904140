import React from "react";
import { SafeAreaView, View } from "react-native";
import PropTypes from "prop-types";

import IconTextButton from "../../../../components/buttons/IconTextButton";

import { PrivacyIcon, TermsIcon } from "../../utils/icons";

import style from "./style";

const LegalHomeView = (props) => {
  return (
    <SafeAreaView style={style.screen}>
      <View style={style.container}>
        <IconTextButton
          theme={props.theme}
          icon={PrivacyIcon}
          text="Privacy Policy"
          onPress={() =>
            // getParam to see if which navigator user clicked from to get to LegalScreen
            props.navigate("/auth/privacy")
          }
        />
        <IconTextButton
          theme={props.theme}
          icon={TermsIcon}
          text="Terms of Service"
          onPress={() =>
            // getParam to see if which navigator user clicked from to get to LegalScreen
            props.navigate("/auth/terms")
          }
        />
      </View>
    </SafeAreaView>
  );
};

LegalHomeView.propTypes = {
  navigation: PropTypes.object.isRequired,
};

export default LegalHomeView;
