import React, { useState, useEffect } from "react";
import { FlatList, Modal, Text, View, TouchableOpacity } from "react-native";

import NavHeader from "../../../../components/custom/NavHeader";
import ItemRow from "../../../../components/rows/ItemRow";
import TicketModal from "../../components/TicketModal";

//import Modal from 'react-modal';
import { CalendarIcon, QRIcon, TicketIcon } from "../../../../utils/icons";
import { DefaultEventImage } from "../../../../utils/images.js";
import BubbleButton from "../../../../components/buttons/BubbleButton";

import { useNavigate } from "react-router-dom";

import style from "./style";
import { color } from "../../../../utils/color";

const showTicket = async (ticket, setTicket) => {
    setTicket({
        data: ticket,
        ticketQuantity: ticket.ticketQuantity,
        ticketType: ticket.ticketType,
        ticketTitle: ticket.ticketTitle,
        eventRefCode: ticket.eventRefCode,
        visible: true,
    });
};

const hideTicket = (setTicket) => {
    setTicket({
        data: null,
        ticketQuantity: null,
        ticketType: null,
        ticketTitle: null,
        visible: false,
    });
};
const renderItem = (item, navigation, setTicket) => {
    return item.ticketEvent ? (
        <ItemRow
            theme={"dark"}
            title={item.ticketTitle}
            subtitle={item.ticketName}
            image={item.ticketMedia}
            icons={[
                {
                    icon: QRIcon,
                    onPress: () => showTicket(item, setTicket),
                },
            ]}
            default={DefaultEventImage}
            onPress={() => showTicket(item, setTicket)}
        />
    ) : null;
};

const keyExtractor = (item) => item.id;

const TicketsView = (props) => {
    const navigate = useNavigate();
    const [ticket, setTicket] = useState({
        data: null,
        ticketQuantity: null,
        ticketType: null,
        ticketTitle: null,
        visible: false,
    });

    useEffect(() => {
        console.log("fired Tickets view");
        window.scrollTo(0, 0);
    }, []);

    //Read tickets on render and after a second
    useEffect(() => {
        props.readUserTickets();
        const timer = setTimeout(() => props.readUserTickets(), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <View style={style.screen}>
            <NavHeader
                onPress={() => {
                    const queryParams = new URLSearchParams(
                        window.location.search
                    );
                    navigate("/event?eventId=" + props.event.id);
                }}
                onAddTicket={() => {
                    navigate("/event/tickets?eventId=" + props.event.id);
                }}
                userAuth={props.userAuth}
                title="My Passes"
            />
            <View style={style.line} />
            <View style={style.container}>
                {props.userAuth ? (
                    <FlatList
                        style={style.list}
                        data={props.tickets}
                        renderItem={({ item }) =>
                            renderItem(item, navigate, setTicket)
                        }
                        keyExtractor={keyExtractor}
                        initialNumToRender={20}
                        maxToRenderPerBatch={20}
                        onEndReached={() => {
                            props.readUserTickets(
                                props.tickets[props.tickets.length - 1]
                                    .createdAt
                            );
                        }}
                        onEndReachedThreshold={0.5}
                        onRefresh={() => props.readUserTickets()}
                        refreshing={false}
                        ListEmptyComponent={
                            <View style={{ flex: 1, justifyContent: "center" }}>
                                <Text
                                    style={{
                                        color: "white",
                                        alignSelf: "center",
                                        marginTop: "5vmin",
                                    }}
                                >
                                    You don’t have any tickets yet. Get some for
                                    this event!
                                </Text>
                                <BubbleButton
                                    title={"Get Tickets"}
                                    bubbleStyle={{
                                        backgroundColor: color,
                                        borderWidth: 2,
                                        borderColor: "white",
                                    }}
                                    onPress={() => {
                                        navigate(
                                            "/event/tickets?eventId=" +
                                                props.event.id
                                        );
                                    }}
                                />
                            </View>
                        }
                    />
                ) : (
                    <TouchableOpacity
                        onPress={() => navigate("/auth/home")}
                        style={style.item}
                    >
                        <Text style={style.empty}>
                            Login to view your tickets!
                        </Text>
                    </TouchableOpacity>
                )}
            </View>

            <Modal
                animationType="fade"
                visible={ticket.visible}
                transparent={true}
            >
                <TicketModal
                    ticketData={ticket.data}
                    ticketQuantity={ticket.ticketQuantity}
                    ticketType={ticket.ticketType}
                    ticketTitle={ticket.ticketTitle}
                    eventRefCode={ticket.eventRefCode}
                    user={props.user}
                    onDismiss={() => hideTicket(setTicket)}
                />
            </Modal>
        </View>
    );
};

export default TicketsView;
