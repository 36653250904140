import text from "../../../../styles/text";
import { white } from "../../../../utils/color"

export default {
    container: {
        backgroundColor: "black",
        borderRadius: 20,
        height: 200,
        flexDirection: "row",
        width: "100%",
        overflow: "hidden",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    body: {
        width: "60%",
        textAlign: "center",
        fontSize: 20,
        marginLeft: 30,
        color: white,
    },

    subtitle: {
        ...text.subtitleBold,
        marginBottom: 12,
    },

    locationIcon: {
        flex: 1,
        justifyContent: "center",
    },
    map: {
        flex: 1,
        backgroundColor: "white",
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        height: 200,
    },
    item: {
        position: "absolute",
        bottom: 10,
        right: 10,
    },
};
