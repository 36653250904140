import React, { useState } from "react";
import {
    Keyboard,
    SafeAreaView,
    TouchableWithoutFeedback,
    View,
    TextInput,
} from "react-native";
import PropTypes from "prop-types";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import BubbleTextField from "../../../../components/fields/BubbleTextField";
import RatioImage from "../../../../components/images/RatioImage";

import { AvenuNameOld, AvenuArrow, AvenuNameNew } from "../../utils/images";

import style from "./style";

const AuthEmailView = (props) => {
    const [email, onChangeEmail] = useState("");

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <SafeAreaView style={style.screen}>
                <View style={style.imageContainer}>
                    <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
                </View>
                <BubbleTextField
                    style={style.email}
                    text={email}
                    placeholder="Enter Email"
                    onChange={(event) => onChangeEmail(event.target.value)}
                    onSubmit={() => {
                        // event.preventDefault();
                        props.bubbleAction(email);
                    }}
                    inputMode="string"
                />
                <BubbleButton
                    title={props.bubbleTitle}
                    onPress={(event) => {
                        event.preventDefault();
                        props.bubbleAction(email);
                    }}
                    bubbleStyle={style.buttonBubble}
                    titleStyle={style.buttonTitle}
                />
                <RatioImage
                    image={AvenuArrow}
                    size={75}
                    ratio={1}
                    style={style.image}
                />
            </SafeAreaView>
        </TouchableWithoutFeedback>
    );
};

AuthEmailView.propTypes = {
    bubbleTitle: PropTypes.string.isRequired,
    bubbleAction: PropTypes.func.isRequired,
};

export default AuthEmailView;
