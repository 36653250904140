import React from "react";
import { Image, Platform, TextInput, View } from "react-native";
import PropTypes from "prop-types";

import { ash, green, steel, charcoal, snow } from "../../../utils/color";

import style from "./style";

const getIconStyle = (value, focused,) =>
  value !== "" || focused ? style.iconDarkmode : [style.iconDarkmode, style.iconDisabledDarkmode];

const TextIconField = (props) => (
  <View style={style.field} >
    <View style={style.container} >
      <Image source={props.icon} style={getIconStyle(props.value, props.focused)} />
      <TextInput
        style={{ ...style.text, color: snow }}
        value={props.value}
        placeholder={props.placeholder}
        placeholderTextColor={steel}
        keyboardType={props.keyboardType}
        returnKeyType={props.returnKey}
        returnKeyLabel={props.returnKey}
        onChange={props.onChange}
        onChangeText={props.onChangeText}
        onSubmitEditing={props.onSubmitEditing}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        autoCapitalize={props.autoCapitalize}
        autoFocus={props.autoFocus}
        editable={props.editable}
        selectionColor={green}
        textAlign={props.textAlign}
        ref={props.setRef}
      />
    </View>
  </View>
);

TextIconField.defaultProps = {
  placeholder: "",
  returnKey: Platform.OS === "ios" ? "default" : "return",
  keyboardType: "default",
  onChange: () => { },
  onChangeText: () => { },
  onSubmitEditing: () => { },
  onBlur: () => { },
  onFocus: () => { },
  autoCapitalize: "sentences",
  autoFocus: false,
  editable: true,
  focused: false,
  textAlign: "left",
  icon: null,
  setRef: null,
};

TextIconField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  keyboardType: PropTypes.string,
  returnKey: PropTypes.string,
  onChange: PropTypes.func,
  onChangeText: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoCapitalize: PropTypes.string,
  autoFocus: PropTypes.bool,
  editable: PropTypes.bool,
  focused: PropTypes.bool,
  textAlign: PropTypes.string,
  setRef: PropTypes.object,
};

export default React.memo(TextIconField);