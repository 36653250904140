import React from "react";
import PropTypes from "prop-types";

import style from "./style";
import IconButton from "../../../../components/buttons/IconButton";
import { AppStoreDownload } from "../../utils/images";
import { PlusIcon } from "../../../../utils/icons";
import { TouchableOpacity, Text, View, Image } from "react-native";
import { black } from "../../../../utils/color";
import { AvenuArrow } from "../../../auth/utils/images";

const NoTicketRow = (props) => {
    return (
        <View style={style.row}>
            <TouchableOpacity
                onPress={() =>
                    window.location.replace(
                        "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                    )
                }
                style={style.downloadbutton}
            >
                <Image source={AppStoreDownload} style={style.image} />
            </TouchableOpacity>
            <TouchableOpacity
                style={style.ticketButton}
                onPress={props.onPress}
            >
                <IconButton
                    icon={props.event["eventGoingUsers"].includes(props.userId) ? AvenuArrow : PlusIcon}
                    style={style.icon}
                    theme={"dark"}
                    onPress={props.onPress}
                    tintColor={black}
                />
                <Text style={style.text}>
                    {props.event["eventGoingUsers"].includes(props.userId)
                        ? "AvenuMeet"
                        : "Join event"}
                </Text>
            </TouchableOpacity>
        </View>
    );
}

export default React.memo(NoTicketRow);
