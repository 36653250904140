import text from "../../../../styles/text";

export default {
    container: {
        backgroundColor: "black",
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    text: {
        fontSize: 26,
        color: "white",
        marginTop: 15,
        marginBottom: 15,
        alignSelf: "center"
    },

    subtitle: {
        ...text.subtitleBold,
        marginBottom: 12,
        color: "white",
    },

    code: {
        flexDirection: "row",
    },

    buttons: {
        flexDirection: "row",
    },

    refRow: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 14,
    },
    icon: {
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 20,
        fontSize: 26,
    },
    item: {
        marginRight: 16,
    },
};
