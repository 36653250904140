import React, { useEffect, useRef, useState } from "react";
import {
    FlatList,
    Text,
    View,
    TouchableOpacity,
    ScrollView,
    LogBox,
    SafeAreaView,
} from "react-native";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.scss";

import CircleButton from "../../../../../components/buttons/CircleButton";
import NavHeader from "../../../../../components/custom/NavHeader";
import ItemRow from "../../../../../components/rows/ItemRow";

import {
    red,
    charcoal,
    snow,
    blue,
} from "../../../../../utils/color";
import { TEMP_NEW_TICKET_SKU } from "../../../../../utils/constants";
import {
    CancelIcon,
    MoreIcon,
    PlusIcon,
    TooltipIcon,
} from "../../../../../utils/icons";

import { generateId } from "../../../../../utils/ids";
import { Check } from "../../../../../utils/images";
import {
    convertIntegerAmountToDollarString,
    isEmpty,
} from "../../../../../utils/text";

import style from "./style";
import TextSmallField from "../../../../../components/fields/TextSmallField";
import ActionButton from "../../../../../components/buttons/ActionButton";
import SwitchRow from "../../../../../components/rows/SwitchRow";
import HiddenTicketTooltip from "../../../components/HiddenTicketTooltip";
import ExternalTicketTooltip from "../../../components/ExternalTicketTooltip";
import NoTicketTooltip from "../../../components/NoTicketTooltip";
import IconImage from "../../../../../components/images/IconImage";
import PopupModal from "../../../../../components/hoc/PopupModal";
import { Box, Modal } from "@mui/material";

LogBox.ignoreLogs(["VirtualizedLists should never be nested"]);

const formComplete = (name, price) => !isEmpty(name) && !isEmpty(price);

const getTooltipIconColor = (theme) => (theme === "dark" ? snow : charcoal);

const getRealPrice = (listedPrice) => {
    return (listedPrice + 100) / 0.95;
};

const handleAdd = (
    name,
    price,
    limit,
    perGuestLimit,
    description,
    setTicketOpen,
    setSku,
    tickets,
    setTickets,
    ticketCount,
    setTicketCount,
    isHiddenTicket,
    hiddenTicketCode
) => {
    if (formComplete(name, price)) {
        const existingHiddenTicket = tickets.find(
            (ticket) =>
                ticket.ticketCode === hiddenTicketCode &&
                ticket.ticketCode !== ""
        );
        if (existingHiddenTicket) {
            Swal.fire({
                icon: "error",
                title: "Invalid Code",
                text: "You cannot have two hidden tickets with the same code.",
            });
        } else if (hiddenTicketCode.length < 4 && isHiddenTicket) {
            Swal.fire({
                icon: "error",
                title: "Invalid Code",
                text: "Codes must be between 4-12 characters",
            });
        } else {
            const newTicket = {
                ticketSku: generateId(),
                ticketName: name,
                ticketPrice: parseInt(price) * 100,
                ticketLimit: isEmpty(limit) ? "" : parseInt(limit),
                ticketPerGuestLimit: isEmpty(perGuestLimit)
                    ? 2
                    : parseInt(perGuestLimit),
                ticketDescription: description,
                ticketCode: hiddenTicketCode,
                pastTicketCode: hiddenTicketCode,
            };
            tickets.push(newTicket);
            setTickets(tickets);
            setTicketCount(ticketCount + 1);
            setTicketOpen(false);
            setSku("");
        }
    } else {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please enter both ticket name and price!",
            confirmButtonColor: blue,
        });
    }
};

const handleDelete = (
    sku,
    tickets,
    setTickets,
    ticketCount,
    setTicketCount,
    isExternalLink
) => {
    if (ticketCount <= 1 && !isExternalLink) {
        Swal.fire({
            icon: "error",
            title: "Last Ticket Type",
            text: "Each ticketed event requires at least one ticket type! You can turn off tickets or link tickets externally using the toggles below",
        });
    } else {
        const updatedTickets = tickets.map((ticket) =>
            ticket.ticketSku === sku
                ? { ...ticket, ticketDeleted: true }
                : ticket
        );
        setTickets(updatedTickets);
        setTicketCount(ticketCount - 1);
    }
};

const handleDeleteAll = (setTickets) => {
    setTickets([]);
};

const handleEdit = (
    name,
    onChangeName,
    price,
    onChangePrice,
    limit,
    onChangeLimit,
    perGuestLimit,
    onChangePerGuestLimit,
    description,
    onChangeDescription,
    setTicketOpen,
    sku,
    setSku,
    tickets,
    setTickets,
    hiddenTicketCode = "",
    setHiddenTicketCode,
    isHiddenTicketVisible,
    setHiddenTicketVisible,
    pastTicketCode,
    setPastTicketCode
) => {
    if (formComplete(name, price)) {
        console.log("hiddenTicketCode: ", hiddenTicketCode);
        const existingHiddenTicket = tickets.find(
            (ticket) =>
                ticket.ticketSku !== sku &&
                ticket.ticketCode === hiddenTicketCode &&
                ticket.ticketCode !== ""
        );
        if (existingHiddenTicket) {
            Swal.fire({
                icon: "error",
                title: "Invalid Code",
                text: "You cannot have two hidden tickets with the same code.",
            });
            return;
        }
        if (isHiddenTicketVisible && !hiddenTicketCode) {
            Swal.fire({
                icon: "error",
                title: "Code Empty",
                text: "Please add a ticket code or toggle off Hide Ticket",
            });
            return;
        }
        if (isHiddenTicketVisible) {
            if (hiddenTicketCode.length < 4) {
                Swal.fire({
                    icon: "error",
                    title: "Invalid Code",
                    text: "Code must be between 4 and 12 characters.",
                });
                return;
            }
        }
        const realCode = isHiddenTicketVisible ? hiddenTicketCode : "";
        if (pastTicketCode !== "" && realCode !== "") {
            if (pastTicketCode !== realCode) {
                Swal.fire({
                    icon: "error",
                    title: "Invalid Code",
                    text: `You can unhide a ticket, but you can not change the code. \n\n Previous code: ${pastTicketCode}`,
                });
                return;
            }
        }

        const pastCode =
            pastTicketCode === "" ? hiddenTicketCode : pastTicketCode;
        const updated = [];
        tickets.forEach((ticket) => {
            ticket.ticketSku === sku
                ? updated.push({
                      ticketSku: sku,
                      ticketName: name,
                      ticketPrice: parseInt(price) * 100,
                      ticketLimit: isEmpty(limit) ? "" : parseInt(limit),
                      ticketPerGuestLimit: isEmpty(perGuestLimit)
                          ? 2
                          : parseInt(perGuestLimit),
                      ticketDescription: description,
                      ticketCode: realCode ?? "",
                      pastTicketCode: pastCode ?? "",
                      ticketsPurchased: ticket.ticketsPurchased ?? 0,
                  })
                : updated.push(ticket);
        });
        setTickets(updated);
        setTicketOpen(false);
        setSku("");
        onChangeName("");
        onChangePrice("");
        onChangeLimit("");
        onChangePerGuestLimit("");
        onChangeDescription("");
        setHiddenTicketCode("");
        setHiddenTicketVisible(false);
        setPastTicketCode("");
    } else {
        Swal.fire({
            icon: "error",
            title: "Oops",
            text: `Please enter both ticket name and price!`,
            confirmButtonColor: blue,
        });
    }
};

const renderItem = (
    item,
    setTicketOpen,
    tickets,
    ticketCount,
    setTicketCount,
    setSku,
    setTickets,
    onChangeName,
    onChangePrice,
    onChangeLimit,
    onChangePerGuestLimit,
    setHiddenTicketCode,
    setHiddenTicketVisible,
    setPastTicketCode,
    onChangeDescription,
    theme,
    isExternalLink
) => {
    if (item.ticketDeleted) return;
    const subtitle = item.ticketCode
        ? `$${convertIntegerAmountToDollarString(item.ticketPrice)}, Code: ${
              item.ticketCode
          }`
        : `$${convertIntegerAmountToDollarString(item.ticketPrice)}`;

    const icons = [];
    const isHiddenTicketVisible =
        item.ticketCode !== undefined && item.ticketCode !== "";
    icons.push(
        {
            icon: MoreIcon,
            tintColor: "white",
            onPress: () => {
                setSku(item.ticketSku);
                setHiddenTicketVisible(isHiddenTicketVisible);
                setHiddenTicketCode(item.pastTicketCode ?? "");
                onChangeName(item.ticketName);
                onChangePrice(
                    convertIntegerAmountToDollarString(item.ticketPrice)
                );
                onChangeLimit(`${item.ticketLimit ? item.ticketLimit : ""}`);
                onChangePerGuestLimit(
                    `${
                        item.ticketPerGuestLimit ? item.ticketPerGuestLimit : ""
                    }`
                );
                onChangeDescription(item.ticketDescription ?? "");
                setPastTicketCode(item.pastTicketCode ?? "");
                setTicketOpen(true);
            },
        },
        {
            icon: CancelIcon,
            tintColor: red,
            onPress: () =>
                handleDelete(
                    item.ticketSku,
                    tickets,
                    setTickets,
                    ticketCount,
                    setTicketCount,
                    isExternalLink
                ),
        }
    );

    return (
        <ItemRow
            theme={theme}
            hideImage={true}
            title={
                item.ticketName?.length > 28
                    ? `${item.ticketName.slice(0, 28)}...`
                    : item.ticketName
            }
            subtitle={subtitle}
            showFee={convertIntegerAmountToDollarString(item.ticketPrice) > 0}
            feeText={`($${convertIntegerAmountToDollarString(
                getRealPrice(item.ticketPrice)
            )} with fees)`}
            icons={icons}
            onPress={() => {}}
            isHiddenTicket={item.ticketCode}
        />
    );
};

const renderHeader = (
    tickets,
    setTickets,
    navigation,
    setTicketOpen,
    setSku,
    theme
) => (
    <ItemRow
        theme={theme}
        hideImage={true}
        title="New Ticket"
        icons={[
            {
                icon: PlusIcon,
                tintColor: "white",
                onPress: () => {
                    setSku(TEMP_NEW_TICKET_SKU);
                    setTicketOpen(true);
                },
            },
        ]}
        onPress={() => {
            setSku(TEMP_NEW_TICKET_SKU);
            setTicketOpen(true);
        }}
    />
);

const renderEmpty = () => <></>;

const keyExtractor = (item) => item.ticketSku;

const EventTicketsView = (props) => {
    const [tickets, setTickets] = useState(props.tickets);
    const [ticketCount, setTicketCount] = useState(
        props.tickets.filter((ticket) => !ticket.ticketDeleted).length
    );

    useEffect(() => {
        setTicketCount(
            tickets.filter((ticket) => !ticket.ticketDeleted).length
        );
    }, [tickets]);

    const [link, setLink] = useState(props.link);
    const [sku, setSku] = useState("");
    const [name, onChangeName] = useState("");
    const [price, onChangePrice] = useState("");
    const [limit, onChangeLimit] = useState("");
    const [perGuestLimit, onChangePerGuestLimit] = useState("");
    const [description, onChangeDescription] = useState("");
    const [isHiddenTicketVisible, setHiddenTicketVisible] = useState(false);
    const [hiddenTicketCode, setHiddenTicketCode] = useState("");
    const [pastTicketCode, setPastTicketCode] = useState("");
    const [isExternalTicket, setIsExternalTicket] = useState(props.link !== "");
    const [noTicket, setNoTicket] = useState(
        !isExternalTicket && props.tickets.length < 1
    );
    const [externalTicketLink, setExternalTicketLink] = useState(props.link);
    const [displayHiddenTicketTooltip, setDisplayHiddenTicketTooltip] =
        useState(false);
    const [displayExternalTicketTooltip, setDisplayExternalTicketTooltip] =
        useState(false);
    const [displayNoTicketTooltip, setDisplayNoTicketTooltip] = useState(false);

    const [focused, setFocused] = useState("");
    const [ticketOpen, setTicketOpen] = useState(false);

    const nameRef = useRef();
    const priceRef = useRef();
    const limitRef = useRef();
    const perGuestLimitRef = useRef();
    const descriptionRef = useRef();
    const hiddenCodeRef = useRef();
    const linkRef = useRef();

    const handleCodeChange = (text) => {
        // Remove special characters and convert to uppercase
        const sanitizedCode = text.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
        if (sanitizedCode.length > 12) {
            setHiddenTicketCode(sanitizedCode.slice(0, 12));
        } else {
            setHiddenTicketCode(sanitizedCode);
        }
    };

    const handleLinkToggle = () => {
        if (props.comingFrom === "/event/edit/tickets") {
            if (isExternalTicket) {
                Swal.fire({
                    icon: "error",
                    title: "Sorry!",
                    text: `Hosts cannot edit ticketing method after creating the event. Create a new event to sell tickets through Avenu.`,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Sorry!",
                    text: `Hosts cannot edit ticketing method after creating the event. Create a new event to sell tickets through an external link.`,
                });
            }
        } else {
            setIsExternalTicket(!isExternalTicket);
        }
    };

    const handleNoTicketToggle = () => {
        if (props.comingFrom === "/event/edit/tickets") {
            if (noTicket) {
                Swal.fire({
                    icon: "error",
                    title: "Sorry!",
                    text: `Hosts cannot edit ticketing method after creating the event. Create a new event to sell tickets.`,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Sorry!",
                    text: `Hosts cannot edit ticketing method after creating the event. Create a new event to get rid of your tickets.`,
                });
            }
        } else {
            setNoTicket(!noTicket);
        }
    };

    const handleConfirm = () => {
        if (isExternalTicket) {
            if (externalTicketLink === "") {
                Swal.fire({
                    icon: "error",
                    title: "Invalid Link",
                    text: `You need to paste a link to your tickets`,
                });
            } else {
                handleDeleteAll(props.setTickets);
                props.setTickets([]);
                props.setLink(externalTicketLink);
                props.navigate(-1);
            }
        } else if (noTicket) {
            handleDeleteAll(props.setTickets);
            props.setTickets([]);
            props.navigate(-1);
        } else {
            if (props.tickets.length < 1) {
                Swal.fire({
                    icon: "error",
                    title: "Missing Ticket",
                    text: `Each event requires at least one ticket type! (Unless you are linking to tickets externally or toggle no tickets)`,
                });
            } else {
                setExternalTicketLink("");
                props.setLink("");
                props.setTickets(tickets);
                props.navigate(-1);
            }
        }
    };

    return (
        <SafeAreaView style={style.screen}>
            <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
            <View style={style.container}>
                <Text style={style.header}>Manage Ticket Types</Text>
                <ScrollView style={style.ticketList}>
                    {!isExternalTicket && !noTicket && (
                        <FlatList
                            style={style.list}
                            data={tickets}
                            renderItem={({ item }) =>
                                renderItem(
                                    item,
                                    setTicketOpen,
                                    tickets,
                                    ticketCount,
                                    setTicketCount,
                                    setSku,
                                    setTickets,
                                    onChangeName,
                                    onChangePrice,
                                    onChangeLimit,
                                    onChangePerGuestLimit,
                                    setHiddenTicketCode,
                                    setHiddenTicketVisible,
                                    setPastTicketCode,
                                    onChangeDescription,
                                    "dark",
                                    isExternalTicket
                                )
                            }
                            ListHeaderComponent={() =>
                                renderHeader(
                                    tickets,
                                    setTickets,
                                    props.navigation,
                                    setTicketOpen,
                                    setSku,
                                    "dark"
                                )
                            }
                            ListEmptyComponent={renderEmpty}
                            keyExtractor={keyExtractor}
                            showsVerticalScrollIndicator={false}
                        />
                    )}
                    {!noTicket && (
                        <SwitchRow
                            title="Link To External Ticket"
                            value={isExternalTicket}
                            onChangeValue={handleLinkToggle}
                            tooltip={
                                <TouchableOpacity
                                    style={style.tooltipButton}
                                    onPress={() =>
                                        setDisplayExternalTicketTooltip(true)
                                    }
                                >
                                    <IconImage
                                        theme={"dark"}
                                        icon={TooltipIcon}
                                        size={16}
                                        tintColor={getTooltipIconColor("dark")}
                                        onPress={() => {
                                            setDisplayExternalTicketTooltip(
                                                true
                                            );
                                        }}
                                    />
                                </TouchableOpacity>
                            }
                        >
                            <PopupModal
                                displayState={displayExternalTicketTooltip}
                                setDisplayState={
                                    setDisplayExternalTicketTooltip
                                }
                                theme={"dark"}
                            >
                                <ExternalTicketTooltip theme={"dark"} />
                            </PopupModal>
                        </SwitchRow>
                    )}
                    {!noTicket && isExternalTicket && (
                        <TextSmallField
                            theme={"dark"}
                            value={externalTicketLink}
                            placeholder="Paste Your Link here"
                            onChangeText={setExternalTicketLink}
                            onFocus={() => setFocused("EXTERNAL_LINK")}
                            focused={focused === "EXTERNAL_LINK"}
                            setRef={linkRef}
                        />
                    )}

                    {!isExternalTicket && (
                        <SwitchRow
                            title="No Tickets"
                            value={noTicket}
                            onChangeValue={handleNoTicketToggle}
                            tooltip={
                                <TouchableOpacity
                                    style={style.tooltipButton}
                                    onPress={() =>
                                        setDisplayNoTicketTooltip(true)
                                    }
                                >
                                    <IconImage
                                        theme={"dark"}
                                        icon={TooltipIcon}
                                        size={16}
                                        tintColor={getTooltipIconColor("dark")}
                                        onPress={() => {
                                            console.log(
                                                "pre: ",
                                                displayNoTicketTooltip
                                            );
                                            setDisplayNoTicketTooltip(true);
                                            console.log(
                                                "post: ",
                                                displayNoTicketTooltip
                                            );
                                        }}
                                    />
                                </TouchableOpacity>
                            }
                        >
                            <PopupModal
                                displayState={displayNoTicketTooltip}
                                setDisplayState={setDisplayNoTicketTooltip}
                                theme={"dark"}
                            >
                                <NoTicketTooltip theme={"dark"} />
                            </PopupModal>
                        </SwitchRow>
                    )}
                </ScrollView>

                <ScrollView contentContainerStyle={style.bottomContainer}>
                    <Text style={style.subHeader}>Info on Payouts</Text>
                    <Text
                        style={{
                            textAlignVertical: "center",
                            textAlign: "center",
                            opacity: 0.35,
                            marginTop: 5,
                            fontSize: 12,
                            color: "white",
                        }}
                    >
                        Payouts are sent to the PayPal account associated with
                        the event creator’s phone number within 72 hours after
                        the event. Please email support@avenuteam.com if Zelle,
                        wire, or another method is preferred. Flagged or
                        reported behavior may delay payouts.
                    </Text>
                </ScrollView>
                <View style={style.footer}>
                    <CircleButton
                        icon={Check}
                        onPress={() => {
                            const temp = props.uiCompleted;
                            temp.tickets = true;
                            props.setUiCompleted(temp);
                            handleConfirm();
                        }}
                    />
                </View>
            </View>
            <Modal
                open={ticketOpen}
                onClose={() => {
                    setTicketOpen(false);
                    setSku("");
                    onChangeName("");
                    onChangePrice("");
                    onChangeLimit("");
                    onChangePerGuestLimit("");
                    onChangeDescription("");
                    setHiddenTicketCode("");
                    setHiddenTicketVisible(false);
                    setPastTicketCode("");
                }}
                style={style.modalContainer}
            >
                <Box style={style.modalCard}>
                    <View style={{ paddingLeft: 15, paddingRight: 15 }}>
                        {sku === TEMP_NEW_TICKET_SKU ? (
                            <Text style={{ ...style.header, marginTop: 20 }}>
                                Add Ticket
                            </Text>
                        ) : (
                            <Text style={{ ...style.header, marginTop: 20 }}>
                                Edit Ticket
                            </Text>
                        )}
                        <TextSmallField
                            theme={"dark"}
                            value={name}
                            placeholder="Ticket Name"
                            onChangeText={onChangeName}
                            onBlur={() => setFocused("")}
                            onFocus={() => setFocused("NAME")}
                            autoCapitalize="words"
                            focused={focused === "NAME"}
                            maxLength={24}
                            setRef={nameRef}
                        />
                        <TextSmallField
                            theme={"dark"}
                            value={`${price}`}
                            placeholder="Ticket Price"
                            keyboardType="numeric"
                            onChangeText={onChangePrice}
                            onFocus={() => setFocused("PRICE")}
                            focused={focused === "PRICE"}
                            setRef={priceRef}
                        />
                        <TextSmallField
                            theme={"dark"}
                            value={`${limit}`}
                            placeholder="Ticket Limit"
                            keyboardType="numeric"
                            onChangeText={onChangeLimit}
                            onFocus={() => setFocused("LIMIT")}
                            focused={focused === "LIMIT"}
                            setRef={limitRef}
                        />
                        <TextSmallField
                            theme={"dark"}
                            value={`${perGuestLimit}`}
                            placeholder="Limit per Guest - Default 2"
                            keyboardType="numeric"
                            onChangeText={onChangePerGuestLimit}
                            onFocus={() => setFocused("PERGUESTLIMIT")}
                            focused={focused === "PERGUESTLIMIT"}
                            setRef={perGuestLimitRef}
                        />
                        <TextSmallField
                            theme={"dark"}
                            value={description}
                            placeholder="Ticket Description"
                            onChangeText={onChangeDescription}
                            onFocus={() => setFocused("DESCRIPTION")}
                            focused={focused === "DESCRIPTION"}
                            maxLength={90}
                            setRef={descriptionRef}
                        />
                        <SwitchRow
                            title="Hide Ticket"
                            value={isHiddenTicketVisible}
                            onChangeValue={setHiddenTicketVisible}
                            tooltip={
                                <TouchableOpacity
                                    style={style.tooltipButton}
                                    onPress={() =>
                                        setDisplayHiddenTicketTooltip(true)
                                    }
                                >
                                    <IconImage
                                        theme={"dark"}
                                        icon={TooltipIcon}
                                        size={16}
                                        tintColor={getTooltipIconColor("dark")}
                                        onPress={() => {
                                            setDisplayHiddenTicketTooltip(true);
                                        }}
                                    />
                                </TouchableOpacity>
                            }
                        >
                            <PopupModal
                                displayState={displayHiddenTicketTooltip}
                                setDisplayState={setDisplayHiddenTicketTooltip}
                                theme={"dark"}
                            >
                                <HiddenTicketTooltip theme={"dark"} />
                            </PopupModal>
                        </SwitchRow>
                        {isHiddenTicketVisible && (
                            <TextSmallField
                                theme={"dark"}
                                value={hiddenTicketCode}
                                placeholder="Ticket Code"
                                onChangeText={handleCodeChange}
                                onFocus={() => setFocused("HIDDEN_CODE")}
                                focused={focused === "HIDDEN_CODE"}
                                setRef={hiddenCodeRef}
                            />
                        )}
                        {sku === TEMP_NEW_TICKET_SKU ? (
                            <ActionButton
                                title="Add"
                                onPress={() => {
                                    handleAdd(
                                        name,
                                        price,
                                        limit,
                                        perGuestLimit,
                                        description,
                                        setTicketOpen,
                                        setSku,
                                        tickets,
                                        setTickets,
                                        ticketCount,
                                        setTicketCount,
                                        isHiddenTicketVisible,
                                        hiddenTicketCode
                                    );
                                    onChangeName("");
                                    onChangePrice("");
                                    onChangeLimit("");
                                    onChangePerGuestLimit("");
                                    onChangeDescription("");
                                    setHiddenTicketCode("");
                                    setHiddenTicketVisible(false);
                                    setPastTicketCode("");
                                }}
                                style={{ marginTop: 20 }}
                            />
                        ) : (
                            <ActionButton
                                title="Save"
                                onPress={() => {
                                    handleEdit(
                                        name,
                                        onChangeName,
                                        price,
                                        onChangePrice,
                                        limit,
                                        onChangeLimit,
                                        perGuestLimit,
                                        onChangePerGuestLimit,
                                        description,
                                        onChangeDescription,
                                        setTicketOpen,
                                        sku,
                                        setSku,
                                        tickets,
                                        setTickets,
                                        hiddenTicketCode,
                                        setHiddenTicketCode,
                                        isHiddenTicketVisible,
                                        setHiddenTicketVisible,
                                        pastTicketCode,
                                        setPastTicketCode
                                    );
                                }}
                                style={{ marginTop: 20 }}
                            />
                        )}
                    </View>
                </Box>
            </Modal>
        </SafeAreaView>
    );
};

EventTicketsView.propTypes = {
    tickets: PropTypes.array.isRequired,
    setTickets: PropTypes.func.isRequired,
};

export default EventTicketsView;
