import React from "react";

import EventDetailsView from "../views/EventDetailsView";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";

const EventDetailsScreen = (props) => {
    const { 
        name, onChangeName,
        description, onChangeDescription,
        start, setStart, 
        end, setEnd } = useContext(CreateEventContext);
    const navigate = useNavigate();

    return (
        <EventDetailsView
            name={name}
            onChangeName={onChangeName}
            description={description}
            onChangeDescription={onChangeDescription}
            start={start}
            setStart={setStart}
            end={end}
            setEnd={setEnd}
            navigate={navigate}
        />
    );
};

export default EventDetailsScreen;