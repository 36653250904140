import * as firestore from "firebase/firestore";
import moment from "moment";
import { DateTime } from "luxon";
import tzlookup from "tz-lookup";

export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const YEAR = 365 * DAY;

// get time in event coordinates's timezone
export const getLocalDateTime = (date, coordinates) => {
    if (!coordinates) {
        return DateTime.fromJSDate(date);
    }
    const timezone = tzlookup(coordinates.latitude, coordinates.longitude);
    const milliseconds = date.seconds * 1000 + date.nanoseconds / 1e6;
    const dateTime = date.seconds
        ? DateTime.fromMillis(milliseconds, { zone: timezone })
        : DateTime.fromJSDate(date, { zone: timezone });
    return dateTime;
};

export const isMoreThan24HoursApart = (date1, date2) => {
    // Calculate the difference between the two dates in milliseconds
    const diffInMs = Math.abs(date1 - date2);

    // Convert the difference to hours
    const diffInHours = diffInMs / (60 * 60);
    // Return true if the difference is more than 24 hours
    return diffInHours > 24;
};

export const getProtectedDate = (date) => {
    let protectedDate;

    try {
        const timestamp = new firestore.Timestamp(
            date.seconds,
            date.nanoseconds
        );
        protectedDate = timestamp.toDate();
    } catch {
        protectedDate = date;
    }

    return protectedDate;
};

const getElapsedDate = (date) => {
    return moment(date).format("ll");
};

export const getElapsedTime = (date) => {
    const protectedDate = getProtectedDate(date);
    const elapsedTime = Date.now() - protectedDate;

    const minutes = Math.floor(elapsedTime / MINUTE);
    const hours = Math.floor(elapsedTime / HOUR);

    if (elapsedTime < MINUTE) {
        return "now";
    } else if (elapsedTime < HOUR) {
        return `${minutes} minute${minutes < 2 ? "" : "s"} ago`;
    } else if (elapsedTime < DAY) {
        return `${hours} hour${hours < 2 ? "" : "s"} ago`;
    }

    return getElapsedDate(protectedDate);
};

export const getDateTime = (date) => {
    const protectedDate = getProtectedDate(date);
    return moment(protectedDate).format("ll h:mm A");
};

export const getDateTimeShort = (date) => {
    const protectedDate = getProtectedDate(date);
    return moment(protectedDate).format("MMM Do h:mm A");
};

export const getTime = (date) => {
    const protectedDate = getProtectedDate(date);
    return moment(protectedDate).format("h:mm A");
};

export const getDate = (date) => {
    const protectedDate = getProtectedDate(date);
    return moment(protectedDate).format("MMMM D, YYYY");
};

export const isRecent = (date) => {
    const protectedDate = getProtectedDate(date);
    return new Date() - protectedDate < DAY;
};

export const isToday = (date) => {
    const protectedDate = getProtectedDate(date);
    const right_now = new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    today.setHours(4);

    if (right_now.getTime() < today.getTime()) {
        return (
            protectedDate.getTime() < today.getTime() &&
            protectedDate.getTime() > today.getTime() - 86400000
        );
    } else {
        return protectedDate.getTime() >= today.getTime();
    }
};

export const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};

export const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 * Processes event datetimes to have valid values
 * @param {*} date
 * @param {*} currentStart
 * @param {*} currentEnd
 * @param {*} setStart
 * @param {*} setEnd
 */
export const processDate = (
    date,
    currentStart,
    currentEnd,
    setStart,
    setEnd
) => {
    if (!currentStart && !currentEnd) {
        setStart(date);
        setEnd(new Date(date.getTime() + 3600 * 1000));
    } else if (!currentEnd) {
        // will never occur, as pressing on start also fills in end time with date + 1 hour
    } else if (!currentStart) {
        const diffTime = currentEnd - date;
        if (diffTime <= 0) {
            // will never occur, handled when focusing on start with end time < current time
            setStart(date);
            setEnd(new Date(date.getTime() + 3600 * 1000));
        } else {
            setStart(date);
            setEnd(new Date(date.getTime() + diffTime));
        }
    } else {
        const diffTime = Math.abs(currentEnd - currentStart);
        setStart(date);
        setEnd(new Date(date.getTime() + diffTime));
    }
};

/**
 * Processes event datetimes to have valid values
 * @param {*} date
 * @param {*} currentStart
 * @param {*} currentEnd
 * @param {*} setStart
 * @param {*} setEnd
 */
export const processEndDate = (
    date,
    currentStart,
    currentEnd,
    setStart,
    setEnd,
    recurringInterval
) => {
    setEnd(date);
    const diffTime = date - currentStart;
    const differenceInDays = diffTime / (1000 * 3600 * 24);

    if (recurringInterval === "Weekly" && differenceInDays > 6) {
        const newStart = new Date(date);
        newStart.setDate(newStart.getDate() - 5);
        setStart(newStart);
    }
    if (date - currentStart < 0) {
        setStart(date);
    }
};

/**
 * Processes event start times to have valid start and end times
 * @param {*} start
 * @param {*} currentEnd
 * @param {*} setStart
 * @param {*} setEnd
 */
export const processStart = (start, currentEnd, setStart, setEnd) => {
    if (currentEnd - start > 0) {
        setStart(start);
    } else {
        setStart(start);
        setEnd(new Date(start.getTime() + 3600 * 1000));
    }
};

/**
 * Processes event end times to have valid end times
 * @param {*} currentStart
 * @param {*} end
 * @param {*} setStart
 * @param {*} setEnd
 */
export const processEnd = (currentStart, end, setEnd) => {
    const diffTime = end - currentStart;
    if (diffTime > 0) {
        setEnd(end);
    } else {
        setEnd(
            new Date(
                currentStart.getTime() + 3600 * 1000 * 24 - Math.abs(diffTime)
            )
        );
    }
};
