import text from "../../../../styles/text";
import { black, white } from "../../../../utils/color";

export default {
    body: {
        ...text.body,
    },
    container: {
        backgroundColor: black,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        flexDirection: "row",
        width: "100%",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    title: {
        fontWeight: "600",
        fontSize: 28,
        letterSpacing: -2,
        marginLeft: 25,
        marginTop: 10,
        marginBottom: 18,
        color: white,
    },
};
