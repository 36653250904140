import text from "../../../../styles/text";
import { white } from "../../../../utils/color";

const styles = {
    body: {
        backgroundColor: "black",
        borderRadius: 20,
        marginTop: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
        flex: 1,
    },
    wrapper: {
        flexDirection: "row",
        width: "100%",
        overflow: 'hidden', // This will clip any overflowing content from the flatlist
        alignItems: 'center',
    },
    postImg: {
        marginRight: 40,
        height: 200,
        width: 115, // Set a fixed width for the images to ensure they fit within the container
        borderRadius: 10,
        borderWidth: 2,
        borderColor: white,
        objectFit: 'cover',
    },
    subtitle: {
        ...text.subtitleBold,
        color: white,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginTop: 20,
    },
    flatlist: {
        flexGrow: 0,
        width: '100%', // Ensure the FlatList does not exceed the width of its container
    },
    flatlistContainer: {
        maxHeight: 200,
        width: '90%',
        marginLeft: 20,
        marginBottom: 15,
        marginTop: 10,
        alignSelf: "center",
        flexDirection: 'row',
        overflow: 'hidden', // Hide any overflow content
    },
    post: {
        height: 200,
        width: 115, // Ensure the post container matches the image size
        borderRadius: 10,
        marginRight: 40,
    },
    icon: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 30,
        height: 30,
    },
    flatlistContent: {
        // Ensure the content container supports horizontal layout
        flexDirection: 'row',
        alignItems: 'center',
    },
};

export default styles;