import { darkThemeBackground } from "../../../../utils/color";
import { Dimensions } from "react-native";

const { width } = Dimensions.get("window");

export default {
    container: {
        // background: darkThemeBackground,
        color: "white",
        alignItems: "center",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        // background: darkThemeBackground,
        paddingTop: "10px",
        flexDirection: width < 768 ? "column" : "row",
        justifyContent: "space-around",
        width: "100%",
    },
    body: {
        // background: darkThemeBackground,
        width: "95%",
    },
    left: {
        width: width < 768 ? "100%" : "49%",
        display: "flex",
        flexDirection: "column",
    },
    right: {
        width: width < 768 ? "100%" : "49%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
    },
};
