import { black } from "../utils/color";
import { bold, header, title, subtitle, body, hero } from "../utils/font";

export default {
    hero: {
        fontSize: hero,

        color: black,
    },

    heroBold: {
        fontWeight: bold,
        fontSize: hero,

        color: black,
    },

    header: {
        fontSize: header,

        color: black,
    },

    headerBold: {
        fontWeight: bold,
        fontSize: header,
    },

    title: {
        fontSize: title,

        color: black,
    },

    titleBold: {
        fontWeight: bold,
        fontSize: title,
    },

    subtitle: {
        fontSize: subtitle,
    },

    subtitleBold: {
        fontWeight: bold,
        fontSize: subtitle,
    },

    body: {
        fontSize: body,
    },
};
