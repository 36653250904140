import React, { useState, useEffect } from "react";
import db from "../../firebaseConfig";

import { doc, setDoc, getDoc, getDocs, documentId, query, collection, where, orderBy, startAfter } from "firebase/firestore";
const TicketsContext = React.createContext();

function TicketsProvider(props) {
    return (
        <TicketsContext.Provider
            value={{
            }}
        >
            {props.children}
        </TicketsContext.Provider>
    );
}

export { TicketsContext, TicketsProvider };
