export const PRIVACY = `
PRIVACY NOTICE

Last updated April 08, 2022 



This privacy notice for ArroSocial, Inc. ("Company" , “Avenu” , "we" , "us" or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you: 
Visit our website at https://www.avenuapp.com, or any website of ours that links to this privacy notice. 
Download and use our mobile application (Avenu - Social Event Discovery), or any other application of ours that links to this privacy notice. 
Engage with us in other related ways, including any sales, marketing, or events.
Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at support@avenuteam.com. 


SUMMARY OF KEY POINTS

This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for. You can also click here to go directly to our table of contents.

What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Click here to learn more. 

Do we process any sensitive personal information? We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Click here to learn more. 

Do you receive any information from third parties? We do not receive any information about you from third parties. 

How do you process my information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Click here to learn more.

In what situations and with which types of parties do we share personal information? We may share information in specific situations and with specific categories of third parties. Click here to learn more. 

How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Click here to learn more. 

What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Click here to learn more.

How do I exercise my rights? The easiest way to exercise your rights is by emailing support@avenuteam.com. We will consider and act upon any request in accordance with applicable data protection laws. 

Want to learn more about what we do with any information we collect? Click here to review the notice in full. 



TABLE OF CONTENTS


1. WHAT INFORMATION DO WE COLLECT?
2. HOW DO WE PROCESS YOUR INFORMATION? 
3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION? 
4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? 
5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES? 
6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? 
7. HOW LONG DO WE KEEP YOUR INFORMATION? 
8. HOW DO WE KEEP YOUR INFORMATION SAFE? 
9. WHAT ARE YOUR PRIVACY RIGHTS?
10. CONTROLS FOR DO-NOT-TRACK FEATURES
11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
12. DO WE MAKE UPDATES TO THIS NOTICE?
13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?


1. WHAT INFORMATION DO WE COLLECT?



Personal information you disclose to us


In Short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. 

Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: 
names 
phone numbers 
job titles 
contact preferences 
billing addresses 
debit/credit card numbers 
contact or authentication data 
email addresses 

Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information: 

data about a person's sex life or sexual orientation 
financial data 
student data 
Payment Data. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/privacy. 

Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, calendar, bluetooth, microphone, sms messages, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.

All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.

Information automatically collected

In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.

We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.

Like many businesses, we also collect information through cookies and similar technologies. 

The information we collect includes:
Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.

2. HOW DO WE PROCESS YOUR INFORMATION?


In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

We process your personal information for a variety of reasons, depending on how you interact with our Services, including: 
To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order. 
To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service. 
To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service. 
To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information. 
To fulfill and manage your orders. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services. 
To enable user-to-user communications. We may process your information if you choose to use any of our offerings that allow for communication with another user. 
To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services. 
To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS? " below). 
To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more. 
To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. 
To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them. 
To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you. 
To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm. 


3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?


In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests. 

If you are located in the EU or UK, this section applies to you. 

The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
Consent. We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Click here to learn more. 
Performance of a Contract. We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you. 
Legitimate Interests. We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to: 
Send users information about special offers and discounts on our products and services 
Develop and display personalized and relevant advertising content for our users 
Analyze how our services are used so we can improve them to engage and retain users 
Support our marketing activities 
Diagnose problems and/or prevent fraudulent activities 
Understand how our users use our products and services so we can improve user experience 
Legal Obligations. We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved. 
Vital Interests. We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person. 

If you are located in Canada, this section applies to you. 

We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time. Click here to learn more.

In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way 
For investigations and fraud detection and prevention 
For business transactions provided certain conditions are met 
If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim 
For identifying injured, ill, or deceased persons and communicating with next of kin 
If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse 
If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province 
If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records 
If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced 
If the collection is solely for journalistic, artistic, or literary purposes 
If the information is publicly available and is specified by the regulations 


4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?


In Short: We may share information in specific situations described in this section and/or with the following categories of third parties. 

Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents (“third parties”) who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are, include but are not limited to, as follows: 
Advertising Networks 
Entertainment Companies
Ticket Marketplaces
Venues/Organizers
We also may need to share your personal information in the, including but not limited to, following situations: 
Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. 
When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). To find out more about Google’s Privacy Policy, please refer to this link. We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document. 
Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us. 
Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions. 
Other Users. When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile. 

5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?

In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.

The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions. 


6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?


In Short: We may use cookies and other tracking technologies to collect and store your information.

We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice. 


7. HOW LONG DO WE KEEP YOUR INFORMATION?


In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.

We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than twelve (12) months past the termination of the user's account. 

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. 


8. HOW DO WE KEEP YOUR INFORMATION SAFE?


In Short: We aim to protect your personal information through a system of organizational and technical security measures.

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment. 


9. WHAT ARE YOUR PRIVACY RIGHTS?


In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time. 

In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section “ HOW CAN YOU CONTACT US ABOUT THIS NOTICE?” below. 

We will consider and act upon any request in accordance with applicable data protection laws. 
If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

If you are located in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.


Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section " HOW CAN YOU CONTACT US ABOUT THIS NOTICE? " below or updating your preferences. 


However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. 

Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section " HOW CAN YOU CONTACT US ABOUT THIS NOTICE? " below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. 

Account Information

If you would at any time like to review or change the information in your account or terminate your account, you can: 
Log in to your account settings and update your user account. 
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements. 

Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. To opt out of interest-based advertising by advertisers on our Services visit http://www.aboutads.info/choices/. 


10. CONTROLS FOR DO-NOT-TRACK FEATURES


Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.


11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?


In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.

California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.). 

CCPA Privacy Notice

The California Code of Regulations defines a "resident" as:

(1) every individual who is in the State of California for other than a temporary or transitory purpose and
(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose

All other individuals are defined as "non-residents."

If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.

What categories of personal information do we collect?

We have collected the following categories of personal information in the past twelve (12) months: 

Collected, Used in the Past 12 Months

A. Identifiers
Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name

YES, YES



B. Personal information categories listed in the California Customer Records statute
Name, contact information, education, employment, employment history, and financial information

YES, YES


C. Protected classification characteristics under California or federal law
Gender and date of birth

YES, YES


D. Commercial information
Transaction information, purchase history, financial details, and payment information

NO, NO


E. Biometric information
Fingerprints and voiceprints

NO, NO


F. Internet or other similar network activity
Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements

NO, NO



G. Geolocation data
Device location

YES,  YES


H. Audio, electronic, visual, thermal, olfactory, or similar information
Images and audio, video or call recordings created in connection with our business activities

NO, NO


I. Professional or employment-related information
Business contact details in order to provide you our services at a business level or job title, work history, and professional qualifications if you apply for a job with us

NO, NO


J. Education Information
Student records and directory information

NO, NO


K. Inferences drawn from other personal information
Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics

YES,      YES



We may also collect other personal information outside of these categories instances where you interact with us in person, online, or by phone or mail in the context of: 
Receiving help through our customer support channels; 
Participation in customer surveys or contests; and 
Facilitation in the delivery of our Services and to respond to your inquiries. 
How do we use and share your personal information?

We collect and share your personal information through: 
Targeting cookies/Marketing cookies 
Social media cookies 
Beacons/Pixels/Tags 
Click redirects: Ticket Marketplaces  
More information about our data collection and sharing practices can be found in this privacy notice. 

You can opt out from the selling of your personal information by disabling cookies in Cookie Preference Settings and clicking on the Do Not Sell My Personal Information link on our homepage. 

You may contact us by email at support@avenuteam.com, or by referring to the contact details at the bottom of this document. 

If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

Will your information be shared with anyone else?

We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that processes the information on our behalf.

We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information. 

We have disclosed the following categories of personal information to third parties for a business or commercial purpose in the preceding twelve (12) months: 
Category B. Personal information, as defined in the California Customer Records law, such as your name, contact information, education, employment, employment history, and financial information. 
Category C. Gender and Date of Birth.
The categories of third parties to whom we disclosed personal information for a business or commercial purpose can be found under "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?".

We have sold the following categories of personal information to third parties in the preceding twelve (12) months: 
Category B. Personal information, as defined in the California Customer Records law, such as your name, contact information, education, employment, employment history, and financial information. 
Category C. Gender and Date of Birth
The categories of third parties to whom we sold personal information are: 
Advertising Networks 
Entertainment Company
Ticket Marketplace
Venues/Organizers
Your rights with respect to your personal data

Right to request deletion of the data — Request to delete

You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.

Right to be informed — Request to know

Depending on the circumstances, you have a right to know:
whether we collect and use your personal information;
the categories of personal information that we collect;
the purposes for which the collected personal information is used;
whether we sell your personal information to third parties;
the categories of personal information that we sold or disclosed for a business purpose;
the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
the business or commercial purpose for collecting or selling personal information.
In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.

Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights

We will not discriminate against you if you exercise your privacy rights.

Verification process

Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.

We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.

Other privacy rights
You may object to the processing of your personal information.
You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
To exercise these rights, you can contact us by email at support@avenuteam.com, or by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.


12. DO WE MAKE UPDATES TO THIS NOTICE?


In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.


13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?


If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by email at support@avenuteam.com, or by post to:

ArroSocial, Inc.
53 Jennys Ln, Barrington, RI 02806, USA
Barrington, RI 02806
United States

If you have any further questions or comments, you may also contact us by post at the following corporate address:

ArroSocial, Inc.
53 Jennys Ln, Barrington, RI 02806, USA
Barrington, RI 02806
United States
Phone: (+1) 860-389-7283


14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?


Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please email us at support@avenuteam.com.
`;

export const TERMS = `
END USER LICENSE AGREEMENT

Last updated April 08, 2022



Avenu - Social Event Discovery is licensed to the end user or consumers (“End-User” or “You” or “Your”) and all terms apply, along with additional terms, to organizers, contractors, and other personnel (“Organizer” or “Worker”) by ArroSocial, Inc., located at 53 Jennys Ln, Barrington, RI 02806, USA, Barrington, Rhode Island 02806, United States ("Licensor" “We” “Our” or “Avenu”), for use only under the terms and conditions of this License Agreement (or “Terms” or “Agreement”). We are registered in Michigan, United States and have Our registered office at 1315 Hill Street, Ann Arbor, MI 48104. Our VAT number is 83-4002913.

By downloading the Licensed Application from Apple's software distribution platform ("App Store") and Google's software distribution platform ("Play Store"), and any update thereto (as permitted by this License Agreement), You indicate that You agree to be bound by all of the terms and conditions of this Agreement, and that You accept this Agreement. App Store and Play Store are referred to in this Agreement as “Services.”

The parties of this License Agreement acknowledge that the Services are not a Party to this License Agreement and are not bound by any provisions or obligations with regard to the Licensed Application, such as warranty, liability, maintenance and support thereof. 

This License Agreement may not provide for usage rules for the Licensed Application that are in conflict with the latest Apple Media Services Terms and Conditions and Google Play Terms of Service ("Usage Rules"). Avenu acknowledges that it had the opportunity to review the Usage Rules and this License Agreement is not conflicting with them.

Avenu - Social Event Discovery when purchased or downloaded through the Services, is licensed to You for use only under this Agreement. The Licensor reserves all rights not expressly granted to You. Avenu - Social Event Discovery is to be used on devices that operate with Apple's operating systems ("iOS" and "Mac OS") or Google's operating system ("Android").


Table of Contents
1. THE APPLICATION	1
2. SCOPE OF LICENSE	2
3. TECHNICAL REQUIREMENTS	2
4. MAINTENANCE AND SUPPORT	3
5. USE OF DATA	3
6. SAFETY	3
7. USER-GENERATED CONTRIBUTIONS	3
7. CONTRIBUTION LICENSE	4
8. LIMITATION OF LIABILITY	5
9. WARRANTY	6
10. FEES AND REFUNDS	6
11. PRODUCT CLAIMS	7
12. LEGAL COMPLIANCE	7
13. CONTACT INFORMATION	7
14. TERMINATION	7
15. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY	7
16. DISPUTE RESOLUTION	8
17. MISCELLANEOUS	8



1. THE APPLICATION


Avenu - Social Event Discovery ("Licensed Application") is a piece of software created to facilitate social experiences through events supported by a robust marketplace that leverage peer-to-peer and social commerce as well as the gig and sharing economies. — and customized for iOS and Android mobile devices ("Devices"). It is used to Create, discover, and enjoy social events with one's friends, network, and beyond.

The Licensed Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if Your interactions would be subjected to such laws, You may not use this Licensed Application. You may not use the Licensed Application in a way that would violate the Gramm-Leach-Bliley Act (GLBA).



2. SCOPE OF LICENSE


2.1  You are given a non-transferable, non-exclusive, non-sublicensable license to install and use the Licensed Application on any Devices that You own or control and as permitted by the Usage Rules, with the exception that such Licensed Application may be accessed and used by other accounts associated with You via Family Sharing or volume purchasing. You are solely responsible for all activities that occur under Your account. 

2.2  This license will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed Application, unless a separate license is provided for such update, in which case the terms of that new license will govern.

2.3  You may not share or make the Licensed Application available to third parties (unless to the degree allowed by the Usage Rules, and with Avenu's prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application.

2.4  You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or attempt to derive the source code of the Licensed Application, or any part thereof, or modify another website to falsely imply that it is, or is associated with Avenu (except with Avenu's prior written consent).

2.5  You may not copy (excluding when expressly authorized by this license and the Usage Rules) or alter the Licensed Application or portions thereof. You may create and store copies only on devices that You own or control for backup keeping under the terms of this license, the Usage Rules, and any other terms and conditions that apply to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorized third parties may gain access to these copies at any time. If You sell Your Devices to a third party, You must remove the Licensed Application from the Devices before doing so.

2.6  Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.

2.7  Licensor reserves the right to modify the terms and conditions of licensing.

2.8  Nothing in this license should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with applicable third-party terms and conditions.
2.9 You are not barred from using the Licensed Application under the laws of the United States or any other applicable jurisdiction. 


3. TECHNICAL REQUIREMENTS


3.1  The Licensed Application requires a firmware version iOS 11.0 or higher. Licensor recommends using the latest version of the firmware.

3.2  Licensor attempts to keep the Licensed Application updated so that it complies with modified/new versions of the firmware and new hardware. You are not granted rights to claim such an update. You agree that We can download and install updates to the Licensed Application on Your device. 

3.3  You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application satisfies the technical specifications mentioned above.

3.4  Licensor reserves the right to modify the technical specifications as it sees appropriate at any time.



4. MAINTENANCE AND SUPPORT


4.1  The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You can reach the Licensor at the email address listed in the App Store or Play Store Overview for this Licensed Application.

4.2  Avenu and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support services with respect to the Licensed Application.



5. USE OF DATA


You acknowledge that Licensor will be able to access and adjust Your downloaded Licensed Application content and Your personal information, and that Licensor's use of such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy: http://www.avenuapp.com/privacy.

You acknowledge that the Licensor may periodically collect and use technical data and related information about Your device, system, and application software, and peripherals, offer product support, facilitate the software updates, and for purposes of providing other services to You (if any) related to the Licensed Application. Licensor may also use this information to improve its products or to provide services or technologies to You, as long as it is in a form that does not personally identify You.

6. SAFETY
You shall be respectful and courteous to other users on and off the Licensed Application. Avenu places high priority on user experience and safety, by only allowing users to message each other once they have agreed to accept such communication. Avenu is not responsible and shall not be held liable for any conduct by any user on or off the Licensed Application. You agree that all interactions, including if You communicate off the Licensed Application or meet in person, that You will maintain safety and respect. Avenu is not responsible for any content posted that could be sensitive, cause triggering affects, mental health issues, or spurs addictions. Any content is subject to removal by Avenu, at Avenu’s sole discretion. You should take all precautions while using the Licensed Application. You should not, in any circumstances, send financial information, money, or other currency to other users.   


YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. AVENU DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS USERS OR OTHERWISE REQUEST BACKGROUND INFORMATION FROM USERS. AVENU MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF OTHER USERS. AVENU RESERVES THE RIGHT AND YOU AGREE TO ALLOW AVENU TO CONDUCT, ANY CRIMINAL BACKGROUND CHECKS OR SCREENINGS AT ANY TIME, AND YOU AGREE THAT ANY INFORMATION YOU PROVIDE COULD BE USED FOR SUCH PURPOSES. 

7. USER-GENERATED CONTRIBUTIONS


The Licensed Application may invite You to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide You with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Licensed Application, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Licensed Application and through third-party websites or applications. As such, any Contributions You transmit may be treated as non-confidential and non-proprietary. When You create or make available any Contributions, You thereby represent and warrant that:

1. You are at least 18 years old. 
2. You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize Us, the Licensed Application, and other users of the Licensed Application to use Your Contributions in any manner contemplated by the Licensed Application and this License Agreement, and the creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of Your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
3. You have the written consent, release, and/or permission of each and every identifiable individual person in Your Contributions to use the name or likeness or each and every such identifiable individual person to enable inclusion and use of Your Contributions in any manner contemplated by the Licensed Application and this License Agreement.
4. Your Contributions are not false, inaccurate, or misleading.
5. Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
6. Your Contributions are not obscene, lewd, lascivious, filthy, nude, partially nude, sexually suggestive, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by Us).
7. Your Contributions do not ridicule, mock, disparage, intimidate, harass, stalk, mistreat, defame, or abuse anyone.
8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or class of people.
9. Your Contributions do not violate any applicable law, regulation, or rule.
10. Your Contributions do not violate the privacy or publicity rights of any third party.
11. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
12. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
13. Your Contributions do not otherwise violate, or link to material that violates, any provision of this License Agreement, or any applicable law or regulation.

Any use of the Licensed Application in violation of the foregoing violates this License Agreement and may result in, among other things, termination or suspension of Your rights to use the Licensed Application. Avenu has sole discretion to determine whether Your actions violate this Agreement.


7. CONTRIBUTION LICENSE


By posting Your Contributions to any part of the Licensed Application or making Contributions accessible to the Licensed Application by linking Your account from the Licensed Application to any of Your social networking accounts, You automatically grant, and You represent and warrant that You have the right to grant, to Us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use copy, reproduce, disclose, advertise, sell, resell, publish, broad cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, Your image and voice) for any purpose, commercial advertising, or otherwise, and to prepare derivative works of, or incorporate in other works, such as Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.

This license will apply to any form, media, or technology now known or hereafter developed, and includes Our use of Your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images You provide. You waive all moral rights in Your Contributions, and You warrant that moral rights have not otherwise been asserted in Your Contributions.

We do not assert any ownership over Your Contributions. You retain full ownership of all of Your Contributions and any intellectual property rights or other proprietary rights associated with Your Contributions. We are not liable for any statements or representations in Your Contributions provided by You in any area in the Licensed Application. You are solely responsible for Your Contributions to the Licensed Application and You expressly agree to exonerate Us from any and all responsibility and to refrain from any legal action against Us regarding Your Contributions.

We have the right, in Our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to recategorize any Contributions to place them in more appropriate locations in the Licensed Application; and (3) to prescreen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor Your Contributions.



8. LIMITATION OF LIABILITY


TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL AVENU, ITS AFFILIATES, EMPLOYEES, DIRECTORS, SHAREHOLDERS, LICENSORS, OR SERVICE PROVIDERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, FIXED, OR ENHANCED DAMAGES, INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS OR CONFIDENTIAL OR OTHER INFORMATION OR LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF PRIVACY, FAILURE TO MEET ANY DUTY, INCLUDING GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY PUCINIARY OR OTHER LOSS WHATSOEVER, WHETHER INCURRED DIRECTLY OR INDIRECTLY, RESULTING FROM: (I) YOUR USE OF THE SERVICE; (II) THE CONDUCT OR CONTENT OF ANY MEMBERS OR THIRD PARTIES ON OR THOURH ANY OF OUR SERVICES OR IN CONNECTION WITH THE SERVICE; OR (III) ANY UNATHORIZED ACCESS, USE OR ALTERATION OF YOUR CONTENT, EVEN IF AVENU HAS BEEN ADVISED OF SUCH DAMAGES. IN NO EVENT SHALL AVENU BE LIABLE FOR ACTS OF GOD, WAR, TERRORISM MACHINE OR COMPUTER BREAKDOWN OR MALFUCTION, INTERRUPTION OR MALFUCNTION OF COMMUNICATION, LABOR DIFFICULTIES OR ANY OTHER CAUSE BEYOND AVENU’S REASONABLE CONTROL.  

AVENU MAXIMUM LIABILITY FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THE SERVICE OR THIS AGREEMENT EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO AVENU DURING THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRECEEDING THE DATE YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY OTHER LEGAL PROCEEDING. THIS IS REGARDLESS OF DEFAULT, CONTRACT, TORT, STATUTE, OR OTHERWISE. THESE LIMITATIONS APPLY TO ALL EVENTS REGARDING THIS AGREEMENT REGARDLESS OF WHETHER YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH RESPECT TO THEIR ESSENTIAL PRUPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU. 



9. WARRANTY


9.1  Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor warrants that the Licensed Application works as described in the user documentation.

9.2  No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorizedly modified, handled inappropriately or culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or if there are any other reasons outside of Avenu's sphere of influence that affect the executability of the Licensed Application.

9.3  You are required to inspect the Licensed Application immediately after installing it and notify Avenu about issues discovered without delay by email provided in Product Claims. The defect report will be taken into consideration and further investigated if it has been emailed within a period of ninety (90) days after discovery.

9.4  If We confirm that the Licensed Application is defective, Avenu reserves a choice to remedy the situation either by means of solving the defect or substitute delivery.

9.5  In the event of any failure of the Licensed Application to conform to any applicable warranty, You may notify the Services Store Operator, and Your Licensed Application purchase price will be refunded to You. To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the Licensed Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.

9.6  If the user is a contractor or venue, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the contractor or venue knew, or should have known, about the claim. The statutory periods of limitation given by law apply for users who are consumers.
   


10. FEES AND REFUNDS

Avenu charges You if You purchase or sell tickets or registrations for events. These fees are based on agreements between Avenu and the Organizer. The Organizer determines the fees that will be charged, and You will see these as a line item entitled “Organizer Fee” on the event page or 
These fees include the costs of hosting the event, taxes, processing, and other related charges. Avenu also charges a fee to maintain the platform, comply with laws and regulations, staffing, account for profit and loss, and address other operational expenses to increase the overall experience of the Licensed Application. 

You could also be subject to bank, credit card, foreign transaction, or other processing fees as assessed for using the card You add to Your account. Please check with Your credit card company or bank before transacting so you are aware of any applicable fees, surcharges, or other assessable charges. 

You can transfer tickets to another account holder if done via the Licensed Application. Avenu is not responsible for unauthorized transfers of tickets. In certain instances the Organizer can authorize the ticket transfer, and You should contact the Organizer directly. 

Organizers are paid five business days after the event ends. The Organizer’s bank will determine when the payments arrive. We collect 5% of the purchase price of the ticket from the Organizer and We collect 5% of the purchase price of the ticket from You. The Organizer is responsible for paying appropriate local, state, and federal taxes. Stripe payment processing is currently 2.9% + $.30 per transaction, find out more on Stripe Fees here. Avenu reserves the right to change this fee structure at any time, without notice to the Organizer or You.

Avenu fees are non-refundable. When You request a refund for tickets to the event and it is approved (by either the Organizer or Avenu), You must destroy the ticket and cannot copy or send it to another person to attend the event. Any violation of this policy is fraud under applicable law. The Organizer is responsible for checking the validity of tickets, and Avenu waives all liability for damages resulting from ticket fraud or damages regarding the unauthorized purchase of event tickets. 


11. PRODUCT CLAIMS


Avenu and You acknowledge that Avenu is not responsible for addressing any claims from You or any third party relating to the Licensed Application failure due to Your device, operating system, or other potential claims regarding issues outside of Avenu’s direct control. 


12. LEGAL COMPLIANCE


You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as a "terrorist supporting" country; and that You are not listed on any US Government list of prohibited or restricted parties.



13. CONTACT INFORMATION


For general inquiries, complaints, questions or issues concerning the Licensed Application, please contact: support@avenuteam.com.      


14. TERMINATION


The license is valid until terminated by Avenu or by You. Your rights under this license will terminate automatically and without notice from Avenu if You fail to adhere to any term(s) of this license. Upon License termination, You shall stop all use of the Licensed Application, and destroy all copies, full or partial, of the Licensed Application.
      

15. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY


The Licensed Application may contain links to third party websites or resources. Avenu is not responsible for these external websites or resources. Avenu is not responsible or liable or these third parties’ terms or actions. We will not be a party to or be responsible for monitoring any transaction between You and any third parties. Avenu represents and warrants that Avenu will comply with applicable third-party terms of agreement when using Licensed Application.

In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement," both Apple and Google and their subsidiaries shall be third-party beneficiaries of this End User License Agreement and — upon Your acceptance of the terms and conditions of this License Agreement, both Apple and Google will have the right (and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.




16. DISPUTE RESOLUTION


This License Agreement is governed by the laws of the State of Delaware without regard to conflicts of law rules, except to the extent preempted by federal law. The venue at all times shall be in Miami-Dade County, Florida. You agree to submit to personal jurisdiction in Miami-Dade County, Florida. 

Upon demand of any party, any dispute, claim, or controversy, arising out of or in connection with these Terms, between or among the parties, shall be resolved by binding arbitration. The dispute shall be in accordance with the JAMS Streamlined Arbitration Procedure Rules for claims that do not exceed $250,000 and the JAMS Comprehensive Arbitration Rules and Procedures for claims exceeding $250,000 in affect at the time the arbitration is initiated. The arbitrator, and not a federal, state, or local court or agency, shall have exclusive jurisdiction and authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability, or formation of these Terms (including the Privacy Policy).The arbitrator shall be empower to grant whatever relief would be available in a court under law or in equity. The arbitrators award shall be written and binding on the parties, and may be entered as a judgment in any court of competent jurisdiction. 
Before You commence arbitration of a claim, You must provide Avenu written notice that includes Your name, residence address, username, email address, phone number, a detailed description of the dispute, and the relief You are seeking. 
Notice must be emailed to legal@avenuteam.com and mailed, return receipt requested, to:
53 Jennys Ln, 
Barrington, RI 02806, USA

Before We commence arbitration, We will send You a notice to the email address on Your account or other commercially reasonable means provided to Us. If after thirty calendar days from receiving the notice, the dispute is not resolved, You or Us can commence the arbitration. 



17. MISCELLANEOUS


17.1  If any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be replaced by valid ones formulated in a way that will achieve the primary purpose.

17.2 Testimonials. The Licensed Application may contain testimonials by users. These testimonials a specific to the particular person, and Your individual results may vary. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials. 
               
17.3  Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.
`;
