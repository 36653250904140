import React from "react";
import { View, Text } from "react-native";
import PropTypes from "prop-types";


import style from "./style";
import { getContainerStyle, snow, black } from "../../../../utils/color";

const LoadingRow = (props) => {
  return (
    <View style={getContainerStyle(style.container, props.theme)}>
      <View style={style.wrapper}>
        <View style={style.headerRow}>
          <Text style={style.emoji}>🔁</Text>
          <Text style={style.subtitle}>Recurring Event</Text>
        </View>
        <Text style={{ ...style.weekday, color: props.theme === "dark" ? snow : black }}>
          Loading...
        </Text>
      </View>
    </View>
  );
};

LoadingRow.propTypes = {
  theme: PropTypes.string,
};

export default React.memo(LoadingRow);