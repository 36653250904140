import { StyleSheet } from "react-native";

import { air, ash } from "../../../utils/color";
import { family, subtitle } from "../../../utils/font";

export default StyleSheet.create({
  row: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",

    padding: 64,
  },

  icon: {
    width: 96,
    height: 96,

    tintColor: ash,
  },

  iconDark: {
    tintColor: air,
  },

  text: {
    margin: 16,

    fontFamily: family,
    fontSize: subtitle,
    textAlign: "center",
    color: ash,
  },

  textDark: {
    color: air,
  },
});