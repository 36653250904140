import React, { useState, useEffect } from "react";
import AuthHomeView from "../views/AuthHomeView";

import { useNavigate } from "react-router-dom";
const AuthHomeScreen = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  return (
    <AuthHomeView
      phoneAction={() => navigate("/auth/phone")}
      emailAction={() => navigate("/auth/email")}
      version="beta"
      navigate={navigate}
    />
  );
};


export default AuthHomeScreen;

