import React from "react";
import { View } from "react-native";
import { green } from "../../../utils/color";

const DisabledSwitch = () => (
  <View
    style={{
      width: 50,
      height: 31,
      borderRadius: 15,
      backgroundColor: green,
      justifyContent: "center",
    }}
  >
    <View
      style={{
        width: 28,
        height: 28,
        backgroundColor: "white",
        borderRadius: 14,
        marginLeft: 20,
      }}
    ></View>
  </View>
);

export default DisabledSwitch;