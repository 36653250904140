export default {
    container: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 20,

        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    image: {
        width: "100%",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
    },

    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        resizeMode: "contain",
        transform: [{ rotateX: "180deg" }],
    },
};
