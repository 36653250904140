import { StyleSheet } from "react-native";

export default StyleSheet.create({
  bar: {
    flexDirection: "row",

    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
    margin: 16,

    borderRadius: 8,
    backgroundColor: "whitesmoke",
  },

  textfield: {
    flex: 1,
    fontSize: 17,
  },

  multilinefield: {
    flex: 1,
    height: 120,
    fontSize: 18,
  },
});
