import React, { useRef, useState } from "react";

import {
  Keyboard,
  View,
  Text,
  SafeAreaView
} from "react-native";


import NavHeader from "../../../../../components/custom/NavHeader";
import MultiTextField from "../../../../../components/custom/MultiTextField";
import CircleButton from "../../../../../components/buttons/CircleButton";
import NewDateTimeField from "../../../../../components/fields/NewDateTimeField";
import TextField from "../../../../../components/fields/TextField";

import { processDate, processEndDate, processEnd, processStart } from "../../../../../utils/time";

import { Check } from "../../../../../utils/images";
import { EVENT_NAME_LIMIT } from "../../../../../utils/constants";

import style from "./style";


const isComplete = (name, description, start) => name !== "" && description !== "" && start;

const hasChanges = (name, description, start, end, details) => {
  return (
    name !== details.name ||
    description !== details.description ||
    start !== details.start ||
    end !== details.end
  );
};

const toggleFocus = (focused, setFocused, type) => {
  if (focused === type) {
    setFocused("");
  } else {
    Keyboard.dismiss();
    setTimeout(() => setFocused(type), 10);
  }
};

const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  }

const EventDetailsView = (props) => {
  const [name, onChangeName] = useState(props.name);
  const [description, onChangeDescription] = useState(props.description);

  const [start, setStart] = useState(props.start);
  const [end, setEnd] = useState(props.end);

  const [focused, setFocused] = useState("");
  const nameRef = useRef();

  return (
    <SafeAreaView style={style.screen}>
      <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
        <View style={style.container}>
          <Text style={style.header}>Event details</Text>
          <View style={style.details}>
          <TextField
                    value={name}
                    placeholder="Event name"
                    onChangeText={onChangeName}
                    onBlur={() => setFocused("")}
                    onFocus={() => setFocused("NAME")}
                    autoCapitalize="words"
                    focused={focused === "NAME"}
                    setRef={nameRef}
                    maxLength={EVENT_NAME_LIMIT}
                />
        <MultiTextField
            value={description}
            placeholder="Details, web links, dress code, etc."
            onChange={(event) => onChangeDescription(event.target.value)}
            onBlur={() => setFocused("")}
            onFocus={() => setFocused("DESCRIPTION")}
            autoCapitalize="sentences"
            focused={focused === "DESCRIPTION"}
            minRows={2}
            maxRows={15}
        />        
          <NewDateTimeField
            theme={props.theme}
            value={start}
            placeholder="Date"
            onChangeValue={(date) => processDate(date, start, end, setStart, setEnd)}
            focused={focused === "DATE"}
            setFocused={() => toggleFocus(focused, setFocused, "DATE")}
            mode="date"
            minDate={new Date()}
          />
          <NewDateTimeField
            theme={props.theme}
            value={end}
            placeholder="Date"
            onChangeValue={(date) => processEndDate(date, start, end, setStart, setEnd)}
            focused={focused === "DATE END"}
            setFocused={() => toggleFocus(focused, setFocused, "DATE END")}
            mode="date"
            minDate={new Date()}
          />
          <View style={style.row}>
            <View style={style.wrapper}>
              <NewDateTimeField
                theme={props.theme}
                value={start}
                placeholder="Start time"
                onChangeValue={(start) => processStart(start, end, setStart, setEnd)}
                focused={focused === "START"}
                setFocused={() => toggleFocus(focused, setFocused, "START")}
                mode="time"
              />
            </View>
            <View style={style.pad} />
            <View style={style.wrapper}>
              <NewDateTimeField
                theme={props.theme}
                value={end}
                placeholder="End time"
                onChangeValue={(end) => processEnd(start, end, setStart, setEnd)}
                focused={focused === "END"}
                setFocused={() => toggleFocus(focused, setFocused, "END")}
                mode="time"
              />
            </View>
          </View>
          <View style={style.spacer} />
            <View style={style.footer}>
              <CircleButton
                icon={Check}
                onPress={() => {
                    props.onChangeName(name);
                    props.onChangeDescription(description);
                    props.setStart(start);
                    props.setEnd(end);
                    props.navigate(-1);
                }}
                disabled={
                  !isComplete(name, description, start) ||
                  !hasChanges(name, description, start, end, 
                    { name : props.name, 
                      description: props.description, 
                      start: props.start, 
                      end: props.end }) ||
                  !isValidDate(start) || !isValidDate(end)
                }
              />
            </View>
            </View>
        </View>
    </SafeAreaView>
  );
};

EventDetailsView.propTypes = {
};

export default EventDetailsView;