import React from "react";
import EventTimeView from "../views/EventTimeView";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";

const EventTimeScreen = (props) => {
    const {start, end, 
        setStart, setEnd, 
        recurringInterval, setRecurringInterval} = useContext(CreateEventContext);
    const navigate = useNavigate();

    return (
    <EventTimeView
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
        recurringInterval={recurringInterval}
        setRecurringInterval={setRecurringInterval}
        navigate={navigate}
    />
    );
};

export default EventTimeScreen;