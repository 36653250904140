import React from "react";
import { Platform, TextInput, View } from "react-native";
import PropTypes from "prop-types";

import { color, sky, snow, steel } from "../../../utils/color";

import style from "./style";

const BarTextField = (props) => {
    // let isMultiline;
    // if(props.multiline == null){
    //   isMultiline = true
    // } else {
    //   isMultiline = props.multiline
    // }

    const handleKeyPress = (event) => {
        if (event.nativeEvent.key === "Enter") {
            event.preventDefault();
            if (props.onSubmit) {
                props.onSubmit();
            }
        }
    };

    return (
        <View style={[style.bar, props.style]}>
            <TextInput
                style={{
                    ...(props.multiline
                        ? style.multilinefield
                        : style.textfield),
                    color: snow,
                }}
                placeholder={props.placeholder}
                placeholderTextColor={steel}
                value={props.text}
                returnKeyType={props.returnKey}
                returnKeyLabel={props.returnKey}
                keyboardType={props.keyboardType}
                onChangeText={props.onChange}
                onSubmitEditing={props.onSubmit}
                onKeyPress={handleKeyPress}
                onFocus={props.onFocused}
                editable={props.editable}
                autoCapitalize="none"
                selectionColor={sky}
                multiline={props.multiline ? true : false}
                maxLength={props.maxLength ? props.maxLength : null}
            />
        </View>
    );
};

BarTextField.defaultProps = {
    placeholder: "",
    returnKey: Platform.OS === "ios" ? "default" : "return",
    onChange: () => {},
    onSubmit: () => {},
    onFocused: () => {},
    editable: true,
    style: {},
};

BarTextField.propTypes = {
    text: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    returnKey: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onFocused: PropTypes.func,
    editable: PropTypes.bool,
    maxLength: PropTypes.number,
    style: PropTypes.object,
};

export default React.memo(BarTextField);
