export const BackIcon = require("../assets/icons/icon-back@2x.png");
export const BackIcon3x = require("../assets/icons/icon-back@3x.png");
export const CancelIcon = require("../assets/icons/icon-cancel.png");
export const CheckIcon = require("../assets/icons/icon-check@2x.png");
export const MoreIcon = require("../assets/icons/icon-more@2x.png");
export const PlusIcon = require("../assets/icons/icon-plus@2x.png");

export const PlusIcon3x = require("../assets/icons/icon-plus@3x.png");
export const ReportIcon = require("../assets/icons/icon-report.png");
export const DMIcon = require("../assets/images/icon-dm.png");

export const CameraIcon = require("../assets/icons/icon-camera@2x.png");
export const GroupIcon = require("../assets/icons/icon-group@2x.png");

export const HomeIcon = require("../assets/icons/icon-home.png");
export const PostsIcon = require("../assets/icons/icon-posts.png");
export const ProfileIcon = require("../assets/icons/icon-profile.png");
export const ClockIcon = require("../assets/icons/icon-clock.png");
export const UnlockIcon = require("../assets/icons/icon-unlock.png");
export const LockIcon = require("../assets/images/lock.png");
export const WebIcon = require("../assets/icons/icon-web.png");
export const CrownIcon = require("../assets/icons/icon-tableService.png");
export const VideoCameraIcon = require("../assets/icons/icon-videoCamera.png");
export const MerchandiseIcon = require("../assets/icons/icon-merchandise.png");

export const CalendarIcon = require("../assets/icons/icon-calendar@2x.png");
export const CheckoutIcon = require("../assets/icons/icon-checkout.png");
export const EditIcon = require("../assets/icons/icon-edit@2x.png");
export const FlashIcon = require("../assets/icons/icon-flash.png");
export const FlashOffIcon = require("../assets/icons/icon-flash-off.png");
export const FlipIcon = require("../assets/icons/icon-flip.png");
export const ImageIcon = require("../assets/icons/icon-image@2x.png");
export const InsightsIcon = require("../assets/icons/icon-insights.png");
export const LocationIcon = require("../assets/icons/icon-location@2x.png");
export const LinkIcon = require("../assets/icons/icon-link.png");
export const LogoutIcon = require("../assets/icons/icon-logout.png");
export const MessageIcon = require("../assets/icons/icon-message.png");
export const MinusIcon = require("../assets/icons/icon-minus.png");
export const MinusIcon3x = require("../assets/icons/icon-minus@3x.png");
export const SaveIcon = require("../assets/icons/icon-save.png");
export const ScanIcon = require("../assets/icons/icon-scan.png");
export const SearchIcon = require("../assets/icons/icon-search.png");
export const SettingsIcon = require("../assets/icons/icon-settings.png");
export const ShareIcon = require("../assets/icons/icon-share@2x.png");
export const TagIcon = require("../assets/icons/icon-tag.png");
export const TicketIcon = require("../assets/icons/icon-ticket@2x.png");
export const QRIcon = require("../assets/icons/icon-qr@3x.png");
export const TrashIcon = require("../assets/icons/icon-trash.png");
export const UsersIcon = require("../assets/icons/icon-users@2x.png");
export const KeyIcon = require("../assets/icons/icon-key.png");
export const InstagramIcon = require("../assets/icons/icon-instagram.png");
export const StarIcon = require("../assets/icons/icon-star.png");
export const InviteIcon = require("../assets/images/icon-invite.png");
export const CopyIcon = require("../assets/icons/icon-copy@3x.png");
export const MapMarker = require("../assets/images/map-marker.png");
export const TooltipIcon = require("../assets/images/icon-tooltip.png");
export const ExternalLinkIcon = require("../assets/icons/icon-external-link.png");

export const UploadIcon = require("../assets/icons/icon-upload.png");

export const Check = require("../assets/images/icon-check@2x.png");
export const Cancel = require("../assets/images/icon-cancel@2x.png")
