import React from "react";
import PropTypes from "prop-types";

import LabeledComponent from "../../hoc/LabeledComponent";
import PickerField from "../PickerField";

const LabeledPickerField = LabeledComponent((props) => (
  <PickerField
    theme={props.theme}
    value={props.value}
    values={props.values}
    onChangeValue={props.onChangeValue}
    focused={props.focused}
    onFocused={props.onFocused}
    style={props.style}
  />
));

LabeledPickerField.defaultProps = {
  style: {},
};

LabeledPickerField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  onFocused: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default React.memo(LabeledPickerField);
