import React, { useState, useEffect } from "react";
import db from "../../firebaseConfig";

import { doc, setDoc, getDoc, updateDoc, getDocs, documentId, query, collection, where, arrayUnion, FieldValue } from "firebase/firestore";
import { generateId } from "../../utils/ids";

const PaymentsContext = React.createContext();

function PaymentsProvider(props) {
    const [paymentTotal, setPaymentTotal] = useState(0);
    const [referralCode, setReferralCode] = useState(null);
    const [ticketDetails, setTicketDetails] = useState({});
    const [unlockedTickets, setUnlockedTickets] = useState([]);
    const [ticketInfo, setTicketInfo] = useState({});

    return (
        <PaymentsContext.Provider
            value={{
                paymentTotal: paymentTotal,
                setPaymentTotal: setPaymentTotal,
                referralCode: referralCode,
                setReferralCode: setReferralCode,
                ticketDetails: ticketDetails,
                setTicketDetails: setTicketDetails,
                unlockedTickets: unlockedTickets,
                setUnlockedTickets: setUnlockedTickets,
                ticketInfo: ticketInfo,
                setTicketInfo: setTicketInfo,
            }}
        >
            {props.children}
        </PaymentsContext.Provider>
    );
}

export { PaymentsContext, PaymentsProvider };
