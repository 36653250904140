import { StyleSheet } from "react-native";

import { family, body } from "../../../utils/font";

export default StyleSheet.create({
  container: {
    height: 80,
    flexDirection: "row",
    marginLeft: 10,
    alignItems: "center",
    width: "90%",
    justifyContent: "space-between"
  },

  icon: {
    width: 30,
    height: 30,
    tintColor: "white",
    marginRight: 10,
  },

  line: {
    width: "100%",
    height: 1,
  },

  row: {
  },

  text: {
    margin: 16,

    fontFamily: family,
    color: "white",
    fontSize: 20,
  },

  wrapper: {
    justifyContent: "flex-start",
    width: "100%",
  },
});