import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import { black } from "../../../../utils/color";
import { family, body } from "../../../../utils/font";

export default StyleSheet.create({
  header: {
    ...text.header,

    marginBottom: 10,
  },

  statusColumn: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 100,
  },

  collection: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
});
