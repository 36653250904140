import { StyleSheet } from "react-native";
import { color } from "../../../utils/color";

export default StyleSheet.create({
    bubble: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",

        height: 64,

        paddingLeft: 24,
        paddingRight: 24,

        // marginLeft: 24,
        // marginRight: 24,
        marginTop: 8,
        marginBottom: 8,

        borderRadius: 32,
    },

    activeBubble: {
        backgroundColor: color,
    },

    disabledBubble: {
        backgroundColor: "whitesmoke",
    },

    disabledTitle: {
        color: "gray",
    },

    title: {
        width: "100%",
        textAlign: "center",
    },
});
