import React from "react";
import { Image } from "react-native";


const DefaultImage = (props) => (
    <Image source={props.source ? props.source : props.defaultImage} style={props.style} />
);


export default DefaultImage;
