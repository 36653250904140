import React from "react";
import { View, Text, Image } from "react-native";
import PropTypes from "prop-types";
import style from "./style";

import { charcoal, white, black, green } from "../../../../utils/color";
import { AvenuArrow } from "../../../auth/utils/images";

const getIconStyle = (tintColor, theme, size) => ({
    ...style.icon,
    tintColor: tintColor || (theme === "dark" ? green : green),
    width: size || 24,
    height: size || 24,
});
const GuestListAvenuMeetTooltip = ({ theme, venue }) => (
    <View style={{ backgroundColor: charcoal }}>
        <View style={{ flexDirection: "column" }}>
            <Text style={[style.header, { color: white }]}>
                AvenuMeet
            </Text>
            {venue ? (
                <Text style={[style.text, { color: white }]}>
                    Join the venue to add friends or anonymously request to Meet through Avenu. {"\n\n"}
                    If the Meet request is mutual, it’s a Green Light! You’ll both be notified and can message
                    each other.
                </Text>
            ) : (
                <Text style={[style.text, { color: white }]}>
                    Join the event to add friends or anonymously request to Meet through Avenu. {"\n\n"}
                    If the Meet request is mutual, it’s a Green Light! You’ll both be notified and can message
                    each other.
                </Text>
            )}
        </View>
        <View
            style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "center",
            }}
        >
            <Image source={AvenuArrow} style={getIconStyle(null, theme, 50)} />
        </View>
    </View>
);

GuestListAvenuMeetTooltip.propTypes = {
    theme: PropTypes.string,
};

export default React.memo(GuestListAvenuMeetTooltip);
