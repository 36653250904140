import React, { useState, useEffect, useMemo } from "react";
import { View, Text } from "react-native";
import PropTypes from "prop-types";

import { getTime, getLocalDateTime } from "../../../../utils/time";
import style from "./style";

const TitleRow = (props) => {
    const startDateTime = useMemo(
        () => getLocalDateTime(props.start, props.coordinates),
        [props.start, props.coordinates]
    );
    const endDateTime = useMemo(
        () => getLocalDateTime(props.end, props.coordinates),
        [props.end, props.coordinates]
    );

    const [startDay, startMonth, startDate, startTime] = useMemo(() => {
        const dateTimeStr = startDateTime.toFormat("ccc LLL dd t").toString();
        return dateTimeStr.split(" ");
    }, [startDateTime]);

    const [endDay, endMonth, endDate, endTime] = useMemo(() => {
        const dateTimeStr = endDateTime.toFormat("ccc LLL dd t").toString();
        return dateTimeStr.split(" ");
    }, [endDateTime]);
    const diffHours =
        props.end.toDate().getTime() - props.start.toDate().getTime();
    let moreThanDay = false;
    let multiDayLessThanWeek = false;
    let moreThanMonth = false;
    // Check if the event lasts more than 24 hours or if ends past midnight
    if (diffHours >= 24 * 60 * 60 * 1000) {
        moreThanDay = true;
        // Check if the event spans across different months
        if (startMonth !== endMonth && diffHours >= 24 * 60 * 60 * 1000) {
            moreThanMonth = true;
        }
        if (diffHours < 168 * 60 * 60 * 1000) {
            multiDayLessThanWeek = true;
        }
    }

    return (
        <View style={style.container}>
            <View style={style.wrapper}>
                <Text
                    style={{
                        ...style.title,
                        fontSize: props.title.length > 12 ? 20 : 28,
                    }}
                >
                    {props.title}
                </Text>
                <Text style={style.body}>
                    {multiDayLessThanWeek ? (
                        <>
                            <Text>
                                {startDay}, {startTime} - {endDay}, {endTime}
                            </Text>
                        </>
                    ) : (
                        <>
                            <Text>
                                {startDateTime.toFormat("t")} -{" "}
                                {endDateTime.toFormat("t")}
                            </Text>
                        </>
                    )}
                </Text>
            </View>
            <View style={style.left}>
                {moreThanDay ? (
                    <>
                        <Text
                            style={style.date}
                        >{`${startDate}-${endDate}`}</Text>
                        <Text style={style.month}>
                            {moreThanMonth
                                ? `${startMonth}-${endMonth}`
                                : startMonth}
                        </Text>
                    </>
                ) : (
                    <>
                        <Text style={style.date}>{startDay}</Text>
                        <Text
                            style={style.month}
                        >{`${startMonth} ${startDate}`}</Text>
                    </>
                )}
            </View>
        </View>
    );
};

TitleRow.propTypes = {
    title: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
};

export default React.memo(TitleRow);
