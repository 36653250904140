import { darkThemeBackground } from "../../../../utils/color";
import { Dimensions } from "react-native";

const { width } = Dimensions.get("window");

export default {
    container: {
        // background: darkThemeBackground,
        color: "white",
        alignItems: "center",
        width: "100%",
    },
    body: {
        // background: darkThemeBackground,
        width: "95%",
    },
    wrapper: {
        // background: darkThemeBackground,
        paddingTop: "10px",
        flexDirection: width < 768 ? "column" : "row",
        justifyContent: "space-around",
        width: "100%",
    },
    left: {
        width: width < 768 ? "100%" : "49%",
        flexDirection: "column",
    },
    right: {
        width: width < 768 ? "100%" : "49%",
        flexDirection: "column",
    },
    icon: {
        position: "absolute",
        bottom: 12,
        right: 12,
    },
    modal: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },

    modalCard: {
        // height: "90%",
        width: width < 1000 ? "60%" : "40%",
        backgroundColor: darkThemeBackground,
        position: "absolute",
        top: "40%",
        // left: "15%",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 15,
    },
    modalClose: {
        position: "absolute",
        right: 7,
        top: 7,
    },
    modalTitle: {
        color: "white",
        fontSize: "20px",
    },
    modalText: {
        marginTop: 10,
        textAlign: "center",
        color: "white",
        fontSize: "15px",
    },
    image: {
        marginTop: 10,
        aspectRatio: 2,
        width: 150,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
};
