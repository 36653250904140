import React, { useContext, useEffect, useState } from "react";
import EventHostingView from "../views/EventHostingView";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../EventContext";
import { AuthContext } from "../../auth/AuthContext";

const EventHostingScreen = (props) => {
    const {fetchHosted, hostedEvents, readHostingEvents, setCurrEventId} = useContext(EventContext);
    const {userAuth} = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (fetchHosted && userAuth && userAuth.user) {
            console.log("DEBUG read hosted events FIRED")
            readHostingEvents(userAuth.user.uid);
        }
    }, [userAuth])

    if (!userAuth) {
       return (<></>);
    }

    return (
        <EventHostingView
            events={hostedEvents}
            navigate={navigate}
            userId={userAuth.user.uid}
            setCurrEventId={setCurrEventId}
        />
    );
};

export default EventHostingScreen;