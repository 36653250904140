import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import views from "../../../../styles/views";

export default StyleSheet.create({
  header: {
    ...text.headerBold,

    marginLeft: 16,
    marginRight: 16,
    marginTop: 24,
    marginBottom: 24,
  },

  list: {
    ...views.list,
  },

  pill: {
    ...views.pill,
  },

  screen: {
    ...views.screen,
    backgroundColor: "transparent",
  },

  sheet: {
    ...views.sheet,
  },

  spacer: {
    height: 16,
  },

  listAddOn: {
    paddingVertical: 6,
  },
});