import { StyleSheet } from "react-native";

import { white, snow, ash, sky } from "../../../utils/color";

export default StyleSheet.create({
    circle: {
        justifyContent: "center",
        alignItems: "center",

        width: 64,
        height: 64,

        borderRadius: 32,
        backgroundColor: sky,
    },

    circleDisabled: {
        backgroundColor: snow,
    },

    icon: {
        width: 24,
        height: 24,

        tintColor: "white",
    },

    iconDisabled: {
        tintColor: ash,
    },
});
