import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";

import style from "./style";
import IconButton from "../../../../components/buttons/IconButton";
import {
    PlusIcon3x,
    MinusIcon3x,
    ClockIcon,
    UnlockIcon,
    LockIcon
} from "../../../../utils/icons";
import { TouchableOpacity, Text, View } from "react-native";

import { PaymentsContext } from "../../../payments/PaymentsContext";

import { red } from "../../../../utils/color";

import { convertIntegerAmountToDollarString } from "../../../../utils/text.js";
const TicketRow = (props) => {
    const { setPaymentTotal, paymentTotal, setTicketDetails, ticketDetails } =
        useContext(PaymentsContext);
    const index = props.index;
    const key = props.id || index;
    const initialQuantity = ticketDetails.hasOwnProperty(key)
        ? ticketDetails[key]
        : 0;
    const [quantity, setQuantity] = useState(initialQuantity);
    // const [paymentTotal, setPaymentTotal] = useState(props.paymentTotal);

    let dateString;
    let timeString;

    if (props.isScheduledTicket) {
        let ticketReleaseTime;

        // check if releaseTime is a Firestore Timestamp object
        if (
            typeof props.releaseTime === "object" &&
            "seconds" in props.releaseTime
        ) {
            ticketReleaseTime = new Date(props.releaseTime.seconds * 1000);
        } else {
            ticketReleaseTime = new Date(props.releaseTime);
        }

        dateString = ticketReleaseTime.toLocaleDateString();
        timeString = ticketReleaseTime.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }

    const isScheduledTicketReleased = (releaseTime) => {
        const currentTimestamp = Date.now();

        let ticketReleaseTimestamp;

        // check if releaseTime is a Firestore Timestamp object
        if (typeof releaseTime === "object" && "seconds" in releaseTime) {
            ticketReleaseTimestamp = releaseTime.toDate().getTime();
        } else {
            ticketReleaseTimestamp = new Date(releaseTime).getTime();
        }

        return ticketReleaseTimestamp <= currentTimestamp;
    };

    const isTicketReleased = props.isScheduledTicket 
        ? isScheduledTicketReleased(props.releaseTime)
        : true;

    const getRealPrice = (listedPrice) => {
        if (listedPrice > 0) {
            return (listedPrice + 100) / 0.95;
        } else {
            return listedPrice;
        }
    };

    const handleIncrement = () => {
        if (quantity < props.maxTicketsPerGuest) {
            setQuantity(quantity + 1);
            // setPaymentTotal(paymentTotal + props.ticketPrice);
            setPaymentTotal(paymentTotal + getRealPrice(props.ticketPrice));

            const newTicketDetails = { ...ticketDetails };
            if (newTicketDetails.hasOwnProperty(key)) {
                newTicketDetails[key] += 1;
            } else {
                newTicketDetails[key] = 1;
            }
            setTicketDetails(newTicketDetails);
        }
    };

    const handleDecrement = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
            // setPaymentTotal(paymentTotal + props.ticketPrice);
            setPaymentTotal(paymentTotal - getRealPrice(props.ticketPrice));

            const newTicketDetails = { ...ticketDetails };
            if (newTicketDetails.hasOwnProperty(key)) {
                newTicketDetails[key] -= 1;
                if (newTicketDetails[key] === 0) {
                    delete newTicketDetails[key];
                }
                setTicketDetails(newTicketDetails);
            }
        }
    };

    return (
        <View style={style.row}>
            <View style={style.description}>
                <View style={style.ticketNameContainer}>
                    <Text
                        style={props.display ? style.quantityText : style.text}
                    >
                        {props.ticketName}
                    </Text>
                    {props.isTicketHidden && (
                        <IconButton
                            icon={UnlockIcon}
                            style={style.unlockIcon}
                            size={18}
                            theme={"dark"}
                            tintColor={"grey"}
                        />
                    )}
                </View>
                {props.ticketDescription.length > 0 ? (
                    <Text style={style.subtitle}>{`${
                        props.ticketDescription + "\n"
                    }`}</Text>
                ) : null}
                {isTicketReleased ? (
                    <View style={style.priceContainer}>
                        <Text
                            style={
                                props.display
                                    ? style.quantityText
                                    : style.ticketText
                            }
                        >
                            {`$${convertIntegerAmountToDollarString(
                                props.ticketPrice
                            )}`}
                        </Text>
                        {!props.soldOut && (
                            <Text style={style.feesText}>{props.feesText}</Text>
                        )}
                    </View>
                ) : (
                    <Text style={style.releaseTime}>
                        {"Locked until: " + dateString + ", " + timeString}
                    </Text>
                )}
            </View>
            {/* {props.display ? <Text style={style.quantity}>x1</Text> : (props.soldOut ? <Text style={style.soldOutText}>Sold out!</Text> : <IconButton icon={PlusIcon3x} style={style.icon} size={25} theme={"dark"} onPress={props.onPress} />)} */}
            {props.display ? (
                <View style={style.quantityDisplayContainer}>
                    <Text style={style.quantity}>x{props.quantity}</Text>
                </View>
            ) : props.soldOut ? (
                <Text style={style.soldOutText}>Sold out!</Text>
            ) : props.maxTicketsPerGuest <= 0 ? (
                <Text style={style.soldOutText}>Guest Limit Hit!</Text>
            ) : isTicketReleased && !props.isTicketLocked ? (
                <View style={style.quantityContainer}>
                    <IconButton
                        icon={MinusIcon3x}
                        style={style.icon}
                        size={25}
                        theme={"dark"}
                        onPress={handleDecrement}
                        disabled={quantity <= 0}
                    />
                    <Text style={style.quantity}>
                        {ticketDetails[key] ? ticketDetails[key] : 0}
                    </Text>
                    <IconButton
                        icon={PlusIcon3x}
                        style={style.icon}
                        size={25}
                        theme={"dark"}
                        onPress={handleIncrement}
                        disabled={quantity >= props.maxTicketsPerGuest}
                    />
                </View>
            ) : (
                <>
                {props.isTicketLocked && (
                    <IconButton
                        icon={LockIcon}
                        style={style.icon}
                        size={25}
                        theme={"dark"}
                        tintColor={red}
                    />
                )} 
                {!isTicketReleased &&
                    <IconButton
                        icon={ClockIcon}
                        style={style.icon}
                        size={25}
                        theme={"dark"}
                        tintColor={red}
                    />
                }
                </>
            )}
        </View>
    );
};

export default React.memo(TicketRow);
