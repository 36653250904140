import React from "react";
import PropTypes from "prop-types";

import style from "./style";
import IconButton from "../../../../components/buttons/IconButton";
import { AppStoreDownload } from "../../utils/images";
import { PlusIcon } from "../../../../utils/icons";
import { TouchableOpacity, Text, View, Image } from "react-native";
import { black, color, air } from "../../../../utils/color";

const PurchaseRow = (props) => {
	if (!props.event["eventGoingUsers"].includes(props.userId)) {
		return (
			<View style={style.row}>
				<TouchableOpacity
					style={style.ticketButton}
					onPress={props.ticketOnClick}
				>
					<Text style={style.ticketText}>View my Passes</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={props.eventOnClick}
					style={{ ...style.button, backgroundColor: props.limitReached ? air : color }}
				>
					<IconButton
						icon={PlusIcon}
						style={style.icon}
						theme={"dark"}
						onPress={props.eventOnClick}
						tintColor={black}
					/>
					<Text style={style.text}>
						{props.allTicketLimitReached
							? "Sold out!"
							: props.event["eventGoingUsers"].includes(
								props.userId
							)
								? "View Passes"
								: "Join event"}
					</Text>
					<Image source={AppStoreDownload} />
				</TouchableOpacity>
			</View>
		);
	} else {
		return (
			<View style={style.row}>
				<TouchableOpacity
					style={style.ticketButton}
					onPress={props.ticketOnClick}
				>
					<Text style={style.ticketText}>View my Passes</Text>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() =>
						window.location.replace(
							"https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
						)
					}
					style={style.downloadbutton}
				>
					<Image source={AppStoreDownload} style={style.image} />
				</TouchableOpacity>
			</View>
		);
	}
};

export default React.memo(PurchaseRow);
