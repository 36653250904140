import React from "react";
import { View, Text } from "react-native"
import PropTypes from "prop-types";
import Linkify from "react-linkify";


import style from "./style";

const DescriptionRow = (props) => 
    <View style={style.container}>
        <Linkify>
            <Text style={style.body}>{props.description}</Text>
        </Linkify>
    </View>;

DescriptionRow.propTypes = {
    description: PropTypes.string.isRequired,
};

export default React.memo(DescriptionRow);
