import React from "react";
import {
    ScrollView,
    View,
    TouchableOpacity,
    Image,
    Linking,
    Alert,
    Text,
} from "react-native";
import PropTypes from "prop-types";

import {
    Gradient1,
    Gradient2,
    Gradient3,
    Gradient4,
    Gradient5,
} from "../../utils/images";

import {
    CrownIcon,
    WebIcon,
    VideoCameraIcon,
    LinkIcon,
    MerchandiseIcon,
} from "../../../../utils/icons";

import style from "./style";
import { getContainerStyle } from "../../../../utils/color";

const gradients = [Gradient1, Gradient2, Gradient3, Gradient4, Gradient5];

const getLinks = (links) => {
    const items = [];
    links.forEach((link) => {
        let icon = null;

        if (link.linkType == "Table") {
            icon = CrownIcon;
        } else if (link.linkType == "Merchandise") {
            icon = MerchandiseIcon;
        } else if (link.linkType == "Website") {
            icon = WebIcon;
        } else if (link.linkType == "Video") {
            icon = VideoCameraIcon;
        } else if (link.linkType == "Other") {
            icon = LinkIcon;
        }
        console.log("peecsture:", link.linkMedia);
        items.push({
            name: link.linkName,
            icon: icon,
            url: link.link,
            media: link.linkMedia,
            onPress: () => {
                if (
                    link.link.toLowerCase().startsWith("https://") ||
                    link.link.toLowerCase().startsWith("http://")
                ) {
                    // URL already has https:// or http:// prefix, open it directly
                    Linking.openURL(link.link);
                } else {
                    // URL doesn't have the prefix, add https:// and open it
                    Linking.openURL(`https://${link.link}`);
                }
            },
        });
    });
    return items;
};

const LinksRow = (props) => {
    const links = getLinks(props.links);
    return (
        <View style={getContainerStyle(style.body)}>
            <View style={style.textWrapper}>
                <img
                    src={require("../../../../assets/icons/icon-link2.png")}
                    style={{
                        ...style.image,
                        width: "25px",
                        height: "25px",
                        marginLeft: "15px",
                        marginRight: "5px",
                        marginBottom: "15px",
                    }}
                />
                <Text style={style.subtitle}>Links</Text>
            </View>
            <View style={style.container}>
                <ScrollView
                    horizontal
                    scrollEnabled={links.length > 3}
                    contentContainerStyle={style.scrollContainer}
                    showsHorizontalScrollIndicator={false}
                >
                    {Array.from({ length: links.length }).map((_, index) => (
                        <View key={index} style={style.itemContainer}>
                            <TouchableOpacity onPress={links[index].onPress}>
                                <View style={style.pill}>
                                    <Text style={style.linkCaption}>
                                        {links[index].name}
                                    </Text>
                                </View>
                                <View style={style.imageContainer}>
                                    <Image
                                        source={
                                            links[index].media &&
                                            links[index].media.length > 0
                                                ? links[index].media[0]
                                                : gradients[
                                                      Math.floor(
                                                          Math.random() * 5
                                                      )
                                                  ]
                                        }
                                        style={style.linkPic}
                                    />
                                </View>
                            </TouchableOpacity>
                        </View>
                    ))}
                </ScrollView>
            </View>
        </View>
    );
};

LinksRow.propTypes = {
    links: PropTypes.array.isRequired,
};

export default React.memo(LinksRow);
