import React, { useContext, useEffect } from "react";
import EventGuestsView from "../views/EventGuestsView";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../EventContext";
import { CreateEventContext } from "../CreateEventContext";
import { AuthContext } from "../../../auth/AuthContext";

const EventGuestsScreen = (props) => {
    const { eventGoingUsers } = useContext(EventContext);
    const { invitedGuests, setInvitedGuests, uiCompleted, setUiCompleted } =
        useContext(CreateEventContext);
    const { friends } = useContext(AuthContext);
    const navigate = useNavigate();
    return (
        <EventGuestsView
            eventGoingUsers={eventGoingUsers}
            invitedGuests={invitedGuests}
            setInvitedGuests={setInvitedGuests}
            uiCompleted={uiCompleted}
            setUiCompleted={setUiCompleted}
            friends={friends}
            navigate={navigate}
        />
    );
};

export default EventGuestsScreen;
