import React, { useContext, useEffect } from "react";
import RecurringEventDetailView from "../views/RecurringEventDetailView";
import { RecurringEventContext } from "../RecurringEventContext";
import { useNavigate } from "react-router-dom";

const RecurringEventDetailScreen = (props) => {
    const { 
        recurringEvent, 
        readRecurringEvent,
        eventHosts,
        eventGuests,
        nextEvent,
    } = useContext(RecurringEventContext);

   const navigate = useNavigate();

    useEffect(() => {
        readRecurringEvent();
    }, [])

    if (!recurringEvent) {
        return (<></>);
    }

    return (
        <RecurringEventDetailView 
            event={recurringEvent}
            eventHosts={eventHosts}
            eventGuests={eventGuests}
            nextEvent={nextEvent}
            navigate={navigate}
        />

    );
}

export default RecurringEventDetailScreen;