import text from "../../../../styles/text";
import { black, color, white } from "../../../../utils/color";

export default {
    button: {
        minWidth: 80,
        justifyContent: "center",
        alignItems: "center",

        borderRadius: 8,
        backgroundColor: "lightgray",
    },

    status: { backgroundColor: color },
    wrapper: {
        flexDirection: "row",
    },
    container: {
        paddingRight: 24,
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: black,
        width: "100%",
        paddingBottom: 19,
        borderRadius: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    row: {
        flexDirection: "row",
        marginLeft: 18,
    },

    emoji: {
        fontSize: 32,
        marginBottom: 12,
        marginLeft: 10,
        marginTop: 10,
    },

    subtitle: {
        fontWeight: "700",
        fontSize: 18,
        marginTop: 16,
        marginLeft: 20,
        color: white,
    },

    day: {
        fontSize: 16,
        color: white,
    },

    time: {
        fontSize: 16,
        color: white,
    },

    title: {
        margin: 12,
    },
};
