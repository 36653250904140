import React, { useEffect, useContext } from "react";

import { AuthContext } from "../../auth/AuthContext";
import { UiContext } from "../../../uiContext";
import { EventContext } from "../../events/EventContext";
import RegistrationView from "../views/RegistrationView";

import { useNavigate } from "react-router-dom";
const RegistrationScreen = (props) => {
    const navigate = useNavigate();
    const { user, userFound, userAuth, createUser, onLoginCallbackObj, readUserIfPresent } =
        useContext(AuthContext);

    const { url, setUrl } = useContext(UiContext);

    const { event, updateGoingUsers, buyFreeTicket } = useContext(EventContext);

    useEffect(() => {
        console.log("DEBUG userAUth", userAuth);
        if (userAuth && Object.keys(userAuth).length !== 0) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth]);

    useEffect(() => {
        console.log(
            "registration useeffect fired, user, userFound:",
            user,
            userFound
        );
        console.log("userAuth", userAuth);

        if (userFound === true) {
            console.log("DEBUG user fired");
            if (
                onLoginCallbackObj &&
                onLoginCallbackObj["func"] == buyFreeTicket
            ) {
                onLoginCallbackObj["func"](userAuth.user.uid, event);
                console.log("DEBUG fired onLoginCallbackObj");
                navigate(url);
                setUrl(null);
            } else if (onLoginCallbackObj &&
                onLoginCallbackObj["updateGoingUsers"] == updateGoingUsers
            ) {
                onLoginCallbackObj["updateGoingUsers"](userAuth.user.uid, event);
                onLoginCallbackObj["addEventCodeToUser"](userAuth.user.uid, event);
                console.log("DEBUG fired onLoginCallbackObj");
                navigate(url);
                setUrl(null);
            } else if (
                onLoginCallbackObj &&
                onLoginCallbackObj["name"] == "navToEventTicketBuy"
            ) {
                let navPath = onLoginCallbackObj["func"](
                    userAuth.user.uid,
                    event,
                    onLoginCallbackObj["eventId"],
                    user
                );
                console.log("DEBUG navpath fired", navPath);
                navigate(navPath);
            } else if (
                (onLoginCallbackObj &&
                    onLoginCallbackObj["name"] == "navToTickets") ||
                onLoginCallbackObj["name"] == "navToCreate" ||
                onLoginCallbackObj["name"] == "navToHost"
            ) {
                let navPath = onLoginCallbackObj["func"]();
                console.log("DEBUG navpath fired", navPath);
                navigate(navPath());
            } else {
                navigate(-3);
                console.log("2", onLoginCallbackObj);
            }
        } else {
            console.log(
                "DEBUG this code should not run... except when resetting",
                userFound,
                user
            );
        }
    }, [userFound]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <RegistrationView
            createProfile={(userData) => {
                console.log("DEBUG user auth", userAuth);
                createUser(userAuth.user.uid, userData);
            }}
            userAuth={userAuth}
        />
    );

};

export default RegistrationScreen;
