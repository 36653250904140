import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import { snow } from "../../../../utils/color";

export default StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: "hidden",
  },
  subtitle: {
    ...text.subtitleBold,
    fontSize: 20,
    marginLeft: 8,
    marginRight: 8,
    color: "white"
  },
  emoji: {
    ...text.subtitleBold,
    fontSize: 20,
    color: "white",

    marginRight: 7,
  },
  headerRow: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 15,
    marginTop: 15,
  },

  tooltipButton: {
    marginLeft: 2,
  },

  wrapper: {
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginLeft: 24,
    marginBottom: 18,
  },

  weekday: {
    flexDirection: "row",
    fontFamily: "barlow",
    color: snow,
    fontSize: 20,
    marginBottom: 10,
    marginRight: 10,
  },

  inProgress: {
    flexDirection: "row",
    fontFamily: "barlow",
    color: snow,
    fontStyle: "italic",
    fontSize: 15,
    marginRight: 10,
    marginTop: 3,
  },

  nextEvent: {
    flexDirection: "row",
  },

  calender: {
    marginRight: 16,
  },
});