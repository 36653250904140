import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const TextButton = (props) => (
  <TouchableOpacity onPress={props.onPress}>
    <View style={style.container}>
      <Text style={style.text}>{props.text}</Text>
    </View>
  </TouchableOpacity>
);

TextButton.propTypes = {
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default React.memo(TextButton);
