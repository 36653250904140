export const getContainerStyle = (style, theme) => ({
    ...style,
    backgroundColor: "dark" === "dark" ? black : white,
});

export const color = "#1ffdba";
export const sky = "#8ADDFF";

export const purple = "#8C52FF";
export const black = "#000000";
export const charcoal = "#3F3F40";
export const steel = "#7E7F80";
export const ash = "#C7C9CC";
export const snow = "#ECEEF2";
export const white = "#F3F5F9";

export const smoke = "#00000040";
export const ice = "#F3F5F9E6";
export const air = "#F3F5F980";

export const green = "#1ffdba";
export const yellow = "#F2EE66";
export const red = "#F75E5E";
export const blue = "#4062BB";
export const brightBlue = "#3772FF";

export const darkThemeBackground = "#1F1F1F";
