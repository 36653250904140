import React, { useEffect, useState } from "react";
import {
    View,
    FlatList,
    Text,
    TouchableOpacity,
    Image,
    ActivityIndicator,
} from "react-native-web";
import {
    collection,
    getDocs,
    limit,
    query,
    startAfter,
} from "firebase/firestore";
import db from "../../../../firebaseConfig.js";

import { DefaultProfileImage, BlueCheckmark } from "../../utils/images";

import style from "./style";

const renderItem = (item) => {
    let event = item.item;
    let displayTitle =
        event.eventTitle.length > 14
            ? event.eventTitle.slice(0, 14) + "..."
            : event.eventTitle;
    console.log(event.eventVerified);
    return (
        <TouchableOpacity
            onPress={() =>
                window.location.replace(
                    "https://apps.apple.com/us/app/avenu-events-with-friends/id1487333983"
                )
            }
            style={style.event}
        >
            <img
                key={event.eventId}
                src={
                    event.eventMedia.length !== 0
                        ? event.eventMedia[0]
                        : DefaultProfileImage
                }
                style={style.eventImg}
                alt=""
            />
            <Text style={style.eventTitle}>{displayTitle}</Text>
            {event.eventVerified ? (
                <Image style={style.icon} source={BlueCheckmark} />
            ) : null}
        </TouchableOpacity>
    );
};

const DiscoverEventsRow = (props) => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //default address is New York, if locationAddress is not provided
    let address = "New York";
    let state = "NY";
    if (
        props.locationAddress != undefined &&
        props.locationAddress != null &&
        props.locationAddress != "" &&
        props.locationAddress.includes(",")
    ) {
        const addressParts = props.locationAddress.split(",");
        address = addressParts[1].trim(); // Get the substring after the first comma and before the second comma
        state = addressParts[2].trim(); // Get the substring after the second comma
    }

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true);
            // Prepare to fetch events from Firestore
            const eventsRef = collection(db, "events");
            let q = query(eventsRef, limit(100)); // Fetch 10 events at a time

            const eventsFetch = [];
            let lastDoc;

            while (eventsFetch.length < 30) {
                if (lastDoc) {
                    q = query(eventsRef, startAfter(lastDoc), limit(100));
                }

                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    break; // No more events in Firestore
                }

                lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

                // Filter and collect events
                const filteredEvents = querySnapshot.docs
                    .filter((doc) => {
                        const event = doc.data();
                        return (
                            (event.eventLocation.locationAddress.includes(
                                address
                            ) ||
                                event.eventLocation.locationAddress.includes(
                                    state
                                )) &&
                            !event.eventTitle.toLowerCase().includes("test")
                        );
                    })
                    .slice(0, 30 - eventsFetch.length); // Only add up to needed amount

                eventsFetch.push(...filteredEvents.map((doc) => doc.data()));

                if (eventsFetch.length >= 30) {
                    break;
                }
            }

            const pushedEvents = [];
            const usedIndices = new Set();
            while (
                pushedEvents.length < 3 &&
                pushedEvents.length < eventsFetch.length
            ) {
                let randomIndex = Math.floor(
                    Math.random() * eventsFetch.length
                );
                if (!usedIndices.has(randomIndex)) {
                    pushedEvents.push(eventsFetch[randomIndex]);
                    usedIndices.add(randomIndex);
                }
            }

            setEvents(pushedEvents);
            setIsLoading(false);
        };

        fetchEvents();
    }, []);

    return (
        <TouchableOpacity
            onPress={() =>
                window.location.replace(
                    "https://apps.apple.com/us/app/avenu-events-with-friends/id1487333983"
                )
            }
        >
            <View style={style.body}>
                <View style={style.wrapper}>
                    <img
                        src={require("../../../../assets/icons/icon-confetti.png")}
                        style={{
                            ...style.image,
                            width: "35px",
                            height: "35px",
                            marginLeft: "20px",
                            marginTop: "10px",
                        }}
                    />
                    <Text style={style.subtitle}>
                        Discover more events nearby
                    </Text>
                </View>
                <View style={style.list}>
                    {isLoading ? (
                        <View style={{ marginTop: 10, marginBottom: 20 }}>
                            <ActivityIndicator size="large" color="#1ffdba" />
                        </View>
                    ) : (
                        <FlatList
                            style={style.flatlist}
                            contentContainerStyle={style.wrapper}
                            horizontal={true}
                            data={events}
                            renderItem={(item) => renderItem(item)}
                        />
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default React.memo(DiscoverEventsRow);
