import React from "react";
import { SafeAreaView, ScrollView, Text } from "react-native";


import { TERMS } from "../../utils/constants";

import style from "./style";

const LegalTermsView = (props) => {
  return (
    <SafeAreaView style={style.screen}>
      <ScrollView>
        <Text style={style.text}>{TERMS}</Text>
      </ScrollView>
    </SafeAreaView>
  );
};

LegalTermsView.propTypes = {};

export default LegalTermsView;
