import text from "../../../../styles/text";
import { black, color, white } from "../../../../utils/color"

export default {
  button: {
    minWidth: 80,
    justifyContent: "center",
    alignItems: "center",

    borderRadius: 8,
    backgroundColor: "lightgray",
  },

  status: { backgroundColor: color },

  container: {
    flexDirection: "row",
    width: "100%",
    borderRadius: 20,
    justifyContent: "space-between",
    backgroundColor: black,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.6,
    shadowRadius: 12,
    elevation: 12,
  },
  text: {
    fontSize: 26,
    marginTop: 15,
    marginBottom: 15,
    alignSelf: "center",
    color: white,
  },
  icon: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    fontSize: 26,
  },
  item: {
    marginRight: 20,
    marginTop: 18,
    tintColor: white,
  },

  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    width: "100%",
  },

  subtitle: {
    ...text.subtitleBold,

    marginBottom: 4,
  },

  day: { fontWeight: "bold" },

  title: {
    margin: 12,
  },
};
