import React from "react";
import { Text, SafeAreaView, View, TextInput } from "react-native";
import PropTypes from "prop-types";

import BubbleButton from "../../../../components/buttons/BubbleButton";
import RatioImage from "../../../../components/images/RatioImage";
import TextButton from "../../../../components/buttons/TextButton";

import { AvenuNameOld, AvenuArrow, AvenuNameNew } from "../../utils/images.js";

import style from "./style";
import { color } from "../../../../utils/color.js";

const AuthHomeView = (props) => {
    return (
        <SafeAreaView style={style.screen}>
            <View style={style.imageContainer}>
                <RatioImage image={AvenuNameNew} size={70} ratio={3.64} />
            </View>
            <BubbleButton
                title={"Login or Sign Up with Phone"}
                onPress={props.phoneAction}
                bubbleStyle={style.buttonBubble}
                color={"#8ADDFF"}
                titleStyle={style.buttonTitle}
            />
            <Text style={style.textMiddle}>Or</Text>
            <BubbleButton
                title={"Organization Login or Sign Up"}
                onPress={props.emailAction}
                bubbleStyle={style.buttonBubble2}
                color={color}
                titleStyle={style.buttonTitle}
            />
            <RatioImage
                image={AvenuArrow}
                size={75}
                ratio={1}
                style={style.image}
            />
            <TextButton
                text="By signing in, you agree to Avenu's Terms of Service & Privacy Policy"
                onPress={() => props.navigate("/auth/legal")}
            />
        </SafeAreaView>
    );
};

AuthHomeView.propTypes = {
    bubbleTitle: PropTypes.string.isRequired,
    bubbleAction: PropTypes.func.isRequired,
};

export default AuthHomeView;
