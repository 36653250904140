import React from "react";
import { View } from "react-native";

const getStyle = (size, style) => ({
  ...style,
  width: size,
  height: size,
  borderRadius: size / 2,
});

const ColorDot = (props) => {
  return <View style={getStyle(props.size, props.style)} />;
};

export default ColorDot;
