import React from "react";

const getStyle = (size, style) => ({
  ...style,
  width: size,
  height: size,
  borderRadius: size / 2,
  objectFit: "cover",
});

const CircularImage = (props) => (
  <img
    src={props.image ? URL.createObjectURL(props.image) : require("../../../assets/images/default-profile.jpeg")}
    style={getStyle(props.size, props.style)}
  />
);

export default React.memo(CircularImage);
