import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import CircularImage from "../../images/CircularImage";
import BubbleButton from "../../buttons/BubbleButton";

import style from "./style";
import { sky } from "../../../utils/color";

// const isLocal = (image) => {
//   return !image.startsWith("https://");
// };

const ProfileEditImage = (props) => {
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        console.log("FILE uploaded: ");
        console.log(fileUploaded);
        props.onPress([fileUploaded]);
    };

    return (
        <View style={style.container}>
            <TouchableOpacity style={style.image} onPress={handleClick}>
                <CircularImage image={props.image} size={96} />
            </TouchableOpacity>
            <BubbleButton
                title="Select Profile Picture (required) *"
                onPress={handleClick}
                buttonStyle={style.buttonStyle}
                bubbleStyle={style.buttonBubble}
                titleStyle={style.buttonTitle}
                color={sky}
                //   disabled={!isCompleted(profile)}
            />
            <input
                ref={hiddenFileInput}
                style={{ display: "none" }}
                type="file"
                name="myimage"
                onChange={handleChange}
            />
        </View>
    );
};

ProfileEditImage.defaultProps = {
    image: null,
};

ProfileEditImage.propTypes = {
    image: PropTypes.string,
    onPress: PropTypes.func.isRequired,
};

export default React.memo(ProfileEditImage);
