import { MINUTE, HOUR, DAY } from "./time";

export const currentCollectionVersions = {
  venue: "v1.3",
  venue_recommendations: "v1.3",
};

export const USE_FIREBASE_EMULATOR = false;

const TEST_ACCOUNT_IDS = [
  "AJJgQChGOId7GY0YTZpRpfZgWDU2",
  "5vv03GmtX1XC6xSMZLa0YdCQ1c82",
  "ay379hTnGhWYxksvPe1jlLqmZ8f1",
  "wt892olKiHWw0uqleoa1RgrOxlO2",
];

let testAccount = false;

export const setTestAccount = (userId) => {
  testAccount = TEST_ACCOUNT_IDS.includes(userId);
};

export const isTestAccount = () => testAccount;

export const MESSAGE_SIZE = 100;
export const COMMENT_SIZE = 1000;

const READ_TIME_URGENT = MINUTE;
const READ_TIME_MAJOR = HOUR * 2;
const READ_TIME_MINOR = DAY * 7;

export const READ_TIME_MINOR_DAYS = Math.round(READ_TIME_MINOR / DAY);

export const shouldReadUrgent = (lastRead) => {
  return !lastRead || new Date() - lastRead > READ_TIME_URGENT;
};

export const shouldReadMajor = (lastRead) => {
  return !lastRead || new Date() - lastRead > READ_TIME_MAJOR;
};

export const shouldReadMinor = (lastRead) => {
  return !lastRead || new Date() - lastRead > READ_TIME_MINOR;
};

export const geocodioConfig = {
  apiKey: "ee50566ce61565171fc4e17f6c70515e506e61f",
};

export const paymentsConfig = {
  apiUrl: "https://avenu-payment-processing.uc.r.appspot.com",
  checkoutUrl: `https://avenu-payment-processing.uc.r.appspot.com/checkout`,
  publishableKey:
    "pk_live_51Ib5C7IaujBsGoUYTbdmdX98vLRpK9P1L2GXo747443StsNj0rwyoHMXhgjYZqFCQsTXEsqZ8YgyzxLx0vo1QdJs00B2YgywEw",
  secretKey: "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"
};
