import React from "react";
import { Text, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const LabeledComponent =
    (Comp) =>
    ({ children, ...props }) =>
        (
            <View style={style.container}>
                <View style={style.header}>
                    <Text style={style.label}>{props.label}</Text>
                    <Text style={style.asterisk}>
                        {props.required ? " *" : ""}
                    </Text>
                    <Text style={style.left}>{props.leftLabel}</Text>
                    <Text style={style.right}>
                        {props.rightLabel ? `${props.rightLabel}` : ""}
                    </Text>
                </View>
                <View style={style.component}>
                    <Comp {...props}>{children}</Comp>
                </View>
            </View>
        );

LabeledComponent.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    rightLabel: PropTypes.string,
};

export default LabeledComponent;
