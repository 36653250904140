import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";

const onDisabled = () => {};

const getOnPress = (onPress) => onPress ?? onDisabled;
const getOpacity = (onPress) => (onPress ? 0.2 : 1.0);
const getIconStyle = (dark) => (dark ? [style.icon, style.iconDark] : style.icon);
const getTextStyle = (dark) => (dark ? [style.text, style.textDark] : style.text);

const EmptyRow = (props) => (
  <TouchableOpacity onPress={getOnPress(props.onPress)} activeOpacity={getOpacity(props.onPress)}>
    <View style={style.row}>
      <Image source={props.icon} style={getIconStyle(props.dark)} />
      <Text style={getTextStyle(props.dark)}>{props.title}</Text>
    </View>
  </TouchableOpacity>
);

EmptyRow.defaultProps = {
  onPress: null,
  dark: false,
};

EmptyRow.propTypes = {
  icon: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  dark: PropTypes.bool,
};

export default React.memo(EmptyRow);