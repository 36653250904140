import React from "react";
import { Platform, TextInput, View } from "react-native";
import PropTypes from "prop-types";

import { ash, charcoal, green, snow, steel } from "../../../utils/color";

import style from "./style";

const getLineStyle = (value, focused, error) =>
  error
    ? [style.line, style.lineError]
    : value !== "" || focused
      ? style.line
      : [style.line, style.lineDisabled];

const getTextStyle = (error, height) => (error ? [style.text, style.textError] : { ...style.text, height, color: snow});

const getFieldStyle = (height) => (height ? {...style.field, height: height} : style.field);

const TextField = (props) => (
  <View style={getFieldStyle(props.height)}>
    <View style={style.container}>
      <TextInput
        theme={props.theme}
        style={getTextStyle(props.error, props.height)}
        //style={{ ...style.text, color: props.theme === 'dark' ? snow : charcoal }}
        password={props.password}
        secureTextEntry={props.secureTextEntry}
        value={props.value}
        placeholder={props.placeholder}
        placeholderTextColor={steel}
        keyboardType={props.keyboardType}
        returnKeyType={props.returnKey}
        returnKeyLabel={props.returnKey}
        multiline={true}
        onChangeText={props.onChangeText}
        onChange={props.onChange}
        onContentSizeChange={props.onContentSizeChange}
        onKeyPress={props.onKeyPress}
        onSubmitEditing={props.onSubmitEditing}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        autoCapitalize={props.autoCapitalize}
        editable={props.editable}
        selectionColor={green}
        textAlign={props.textAlign}
        ref={props.setRef}
        maxLength={props.maxLength ? props.maxLength : null}
      />
    </View>
    <View style={getLineStyle(props.value, props.focused, props.error)} />
  </View>
);

TextField.defaultProps = {
  placeholder: "",
  returnKey: Platform.OS === "ios" ? "default" : "return",
  keyboardType: "default",
  onChangeText: () => { },
  onChange: () => { },
  onKeyPress: () => { },
  onSubmitEditing: () => { },
  onBlur: () => { },
  onFocus: () => { },
  autoCapitalize: "sentences",
  editable: true,
  focused: false,
  textAlign: "left",
  setRef: null,
  error: false,
};

TextField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  keyboardType: PropTypes.string,
  returnKey: PropTypes.string,
  onChangeText: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoCapitalize: PropTypes.string,
  editable: PropTypes.bool,
  focused: PropTypes.bool,
  textAlign: PropTypes.string,
  setRef: PropTypes.object,
  error: PropTypes.bool,
};

export default TextField;