import React, { useEffect, useState, useContext } from "react";

import db from "../../../firebaseConfig";
import { doc, setDoc, getDoc, getDocs, documentId, query, collection, where, orderBy, startAfter, limit } from "firebase/firestore";
import { AuthContext } from "../../auth/AuthContext";
import { EventContext } from "../../events/EventContext";

import TicketsView from "../views/TicketsView";

const TicketsScreen = (props) => {
    const [ticket, setTicket] = useState({ data: null, visible: false });
    const [userTickets, setUserTickets] = useState([])

    const {
        userAuth,
        user,
        readUserIfPresent,
    } = useContext(AuthContext);

    const {
        event,
    } = useContext(EventContext)

    const readUserTickets = async (cursorTickets) => {
        let q = query(collection(db, "tickets"), where("ticketUser", "==", userAuth.user.uid), orderBy("createdAt", "desc"), startAfter(cursorTickets ?? new Date()), limit(20))
        let querySnapshot = await getDocs(q);
        const localUserTickets = {};
        querySnapshot.forEach(async (documentSnapshot) => {
            localUserTickets[documentSnapshot.id] = documentSnapshot.data();
            let temp = localUserTickets[documentSnapshot.id].ticketEvent
            const docRef = doc(db, 'events', temp);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                let data = docSnapshot.data();
                localUserTickets[documentSnapshot.id].eventRefCode = data.eventRefCode;
            }
            localUserTickets[documentSnapshot.id].id = documentSnapshot.id;
        });
        if (userTickets.length > 0) {
            userTickets.forEach((ticket) => {
                localUserTickets[ticket.id] = ticket
            })
        }
        let userTicketsListUnsorted = Array.from(Object.values(localUserTickets));

        let userTicketsList = userTicketsListUnsorted.sort((a, b) => b["createdAt"]["seconds"] - a["createdAt"]["seconds"])
        setUserTickets(userTicketsList)
        return userTicketsList;
    }
    useEffect(() => {
        console.log("fired Tickets screen")
        readUserTickets()
        if (userAuth) {
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth])

    return (
        <TicketsView
            readUserTickets={readUserTickets}
            tickets={userTickets}
            userAuth={userAuth}
            user={user}
            event={event}
        />
    );
};

export default TicketsScreen;
