export const sendNotification = (userIds, messageNotification, messageData) => {
    const body = {
        userIds,
        messageNotification,
        messageData,
    };

    fetch(
        "https://us-central1-avenu-2bd9c.cloudfunctions.net/sendNotifications",
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        }
    ).then((res) => {
        console.log("sendNotifications response status:", res.status);
    });
};
