import text from "../../../../styles/text";
import { white } from "../../../../utils/color";

const styles = {
    body: {
        backgroundColor: "black",
        borderRadius: 20,
        marginTop: 20,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },
    wrapper: {
        flexDirection: "row",
        width: "100%",
    },
    eventImg: {
        marginRight: 20, // Increase the margin to spread the images apart
        height: 110, // Increase the size of the images
        width: 110, // Increase the size of the images
        borderRadius: 10, // This makes the image appear as a circle
        borderWidth: 20, // Add the border to the image
        borderColor: white, // Add the border to the image
        objectFit: 'cover', // This will prevent the image from being distorted
    },
    subtitle: {
        ...text.subtitleBold,
        color: white,
        fontSize: 20,
        marginBottom: 12,
        marginLeft: 20,
        marginTop: 20,
    },
    emoji: {
        fontSize: 32,
        marginBottom: 12,
        marginLeft: 10,
        marginTop: 10,
    },
    flatlist: {
        marginLeft: 15,
        marginBottom: 20,
        alignSelf: "center",
    },
    event: {
        height: 110, // Increase the size of the images
        width: 110, // Increase the size of the images
        borderRadius: 10, // This makes the image appear as a circle
        marginRight: 20, // Increase the margin to spread the images apart
    },
    icon: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 30,
        height: 30,
    },
    eventTitle: {
        position: 'absolute',
        bottom: -10, // move text lower
        color: 'white',
        width: '100%',
        paddingLeft: 5, // move text to the left
        paddingBottom: 15, // move text lower
        textAlign: 'left', // align text to the left
        fontSize: 12,
        textShadowColor: 'rgba(0, 0, 0, 1)', // black shadow
        textShadowOffset: { width: -1, height: -1 },
        textShadowRadius: 10,
    },
};

export default styles;