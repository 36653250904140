import React from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import PropTypes from "prop-types";

import style from "./style";
import { white } from "../../../utils/color";

const IconTextButton = (props) => (
  <TouchableOpacity onPress={props.onPress}>
    <View style={style.container}>
      <Text style={style.text}>{props.text}</Text>
      <Image source={props.icon} style={{ ...style.icon, tintColor: white }} />
    </View>
  </TouchableOpacity>
);

IconTextButton.propTypes = {
  icon: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default React.memo(IconTextButton);
