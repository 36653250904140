import React, { useEffect } from "react";

import LegalHomeView from "../views/LegalHomeView";

import { useNavigate } from "react-router-dom";

const LegalHomeScreen = (props) => {
  const navigate = useNavigate();
  return <LegalHomeView
    navigate={navigate}
  />;
};

export default LegalHomeScreen;
