import React from "react";
import { Image, TouchableOpacity, View, Text } from "react-native";
import DefaultImage from "../../images/DefaultImage";
import PropTypes from "prop-types";
import ColorDot from "../../images/ColorDot";


import style from "./style";
import { black, white } from "../../../utils/color";

const isLocal = (image) => {
  return image.length > 8 ? !image.startsWith("https://") : false;
};

const onDisabled = () => { };

const getOnPress = (onPress) => onPress ?? onDisabled;
const getOpacity = (onPress) => (onPress ? 0.2 : 1.0);

const getTitleStyle = (dark, titleColor) =>
  titleColor ? [{ color: titleColor }] : dark ? [style.title, style.titleDark] : style.title;
const getSubtitleStyle = (dark) => (dark ? [style.subtitle, style.subtitleDark] : style.subtitle);
const getImageStyle = (dark) => (dark ? { ...style.image, ...style.imageDark } : style.image);

const getIconStyle = (tintColor, dark, theme) => ({
  ...style.icon,
  ...(dark && style.iconDark),
  tintColor: tintColor || (theme === "dark" ? white : black),
});

const getLineStyle = (dark) => (dark ? [style.line, style.lineDark] : style.line);
const getRowStyle = (small, background, theme) =>
  small
    ? [style.row, style.rowSmall]
    : background
      ? theme === "dark"
        ? { ...style.row, backgroundColor: "#1F1F1F" }
        : { ...style.row, backgroundColor: "#F3F5F9" }
      : style.row;

const ItemRow = (props) => (
  <View theme={props.theme} style={getRowStyle(props.small, props.background, props.theme)}>
    <TouchableOpacity
      theme={props.theme}
      style={style.wrapper}
      onPress={getOnPress(props.onPress)}
      activeOpacity={getOpacity(props.onPress)}
    >
      <View style={{...style.container, width: props.width ?? "80vw"}}>
        {props.image && isLocal(props.image) ? (
          <DefaultImage
            source={props.image}
            style={getImageStyle(props.dark)}
            defaultImage={props.default}
          />
        ) : props.hideImage ? null : (
          <DefaultImage
            source={props.image}
            style={getImageStyle(props.dark)}
            defaultImage={props.default}
          />
        )}
        <View>
          <Text style={getTitleStyle(props.dark, props.titleColor)}>{props.title}</Text>
          {props.subtitle && (
            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
              {typeof props.subtitle === 'object' && props.subtitle.icon && (
                <Image source={props.subtitle.icon} style={{ marginRight: 5, width: 18, height: 18, tintColor: 'grey', }} />
              )}
              <Text style={getSubtitleStyle(props.theme, props.dark)}>
                {typeof props.subtitle === 'object' ? props.subtitle.text : props.subtitle}
              </Text>
              {props.showFee && (
          <Text style={style.littleFee}>
            {props.feeText}
          </Text>)}
            </View>
          )}
        </View>
        {props.dotStyle ? <ColorDot size={14} style={props.dotStyle} /> : null}
        <View style={style.spacer} />
        {props.icons.map((icon) => (
          <TouchableOpacity
            theme={props.theme}
            key={icon.icon}
            onPress={getOnPress(icon.onPress)}
            activeOpacity={getOpacity(icon.onPress)}
          >
            <Image
              source={icon.icon}
              theme={props.theme}
              style={getIconStyle(icon.tintColor, props.dark, props.theme)}
            />
          </TouchableOpacity>
        ))}
      </View>
    </TouchableOpacity>
    <View style={{...style.line, width: props.width ?? "90%"}} />
  </View>
);

ItemRow.defaultProps = {
  subtitle: null,
  image: null,
  images: null,
  default: null,
  onPress: null,
  dark: false,
  small: false,
  dotStyle: {},
};

ItemRow.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.any,
  image: PropTypes.string,
  images: PropTypes.array,
  default: PropTypes.number,
  icons: PropTypes.array.isRequired,
  onPress: PropTypes.func,
  dark: PropTypes.bool,
  small: PropTypes.bool,
  dotStyle: PropTypes.object,
  showFee: PropTypes.bool,
  feeText: PropTypes.string
};

export default React.memo(ItemRow);
