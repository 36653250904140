import { Dimensions, StyleSheet } from "react-native";

import { white, air } from "../../../../utils/color";

import text from "../../../../styles/text";

export default StyleSheet.create({
  body: {
    ...text.body,

    marginLeft: 24,
    marginRight: 24,
    marginBottom: 10,

    color: air,
  },

  bottom: {
    marginBottom: 0,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",

  },

  card: {
    width: "calc(20rem + 15vw)",
    height: "calc(20rem + 15vw)",

    borderRadius: 8,
    marginVertical: 6,
  },

  container: {
    position: "absolute",
    justifyContent: "space-between",
    alignItems: "flex-start",

    width: "100%",
    height: "100%",
  },

  icon: {
    width: 24,
    height: 24,

    tintColor: white,
  },

  image: {
    width: "100%",
    height: "100%",

    borderRadius: 8,
  },

  overlay: {
    position: "absolute",
    width: "100%",
    height: "40%",
    bottom: 0,
    resizeMode: "stretch",
    borderRadius: 8,
  },

  title: {
    ...text.titleBold,

    marginLeft: 24,
    marginRight: 24,
    marginBottom: 2,
    color: white,
  },

  wrapper: {
    alignSelf: "flex-end",

    marginRight: 12,
    marginTop: 10,
  },

  guests: { 
    marginRight: 18,
    marginBottom: 10, 
  },

  invitedByRow: {
    flexDirection: "row",
    alignSelf: "flex-end"
  },

  invitedByText: {
    ...text.body,
    fontSize: 12,
    color: white,
    marginTop: 18,
    marginLeft: 10,
    marginRight: 15
  },
  hostImage: {
    marginTop: 16,
    marginBottom: 24,

    width: 20,
    height: 20,
    borderRadius: 20/2
  },
  
});