import { darkThemeBackground, sky } from "../../../../utils/color";
import { header } from "../../../../utils/font";
import { grey } from "@mui/material/colors";
import { Dimensions } from "react-native-web";


const width = Dimensions.get("window").width;

export default {
    container: {
        flex: 1,
        backgroundColor: darkThemeBackground,
        padding: 10,
        height: "100vh",
    },
    contentContainer: {
        flex: 1,
        flexDirection: Dimensions.get("window").width < 768 ? "column" : "row",
        alignItems: "stretch",
    },
    nonHiddenTicketsContainer: {
        flex: 1,
        marginBottom: "5rem",
    },
    rightContainer: {
        flex: 1,
        marginLeft: 10,
        position: "fixed",
        bottom: 10,
        left: 10,
        right: 10,
        justifyContent: "center",
    },
    unlockTicketContainer: {
        marginBottom: 10,
    },
    unlockedTicketsContainer: {
        flex: 1,
        marginBottom: 10,
    },
    header: {
        color: "white",
        fontWeight: "bold",
        fontSize: 24,
        marginTop: 40,
        alignSelf: "center",
        marginBottom: 24,
    },
    subheader: {
        color: "white",
        fontSize: 20,
        marginBottom: 24,
        alignSelf: "center",
    },
    list: {
        flex: 1,
    },
    bottom: {
        // fontWeight: 700,
        fontSize: 16,
        color: "white",
        marginVertical: 10,
    },
    confirm: {
        backgroundColor: sky,
        flexDirection: "row",
        justifyContent: "center",
        borderRadius: 12,
        borderColor: "white",
        borderWidth: 2,
        paddingVertical: 12,
    },
    input: {
        height: 40,
        borderWidth: 1,
        color: "white",
        borderColor: "white",
        borderRadius: 8,
        width: "20rem",
        textAlign: "center",
    },
    confirmText: {
        fontWeight: 700,
        fontSize: 14,
    },
    footer: {
        position: "fixed",
        bottom: 10,
        left: 10,
        right: 10,
        justifyContent: "center",
        backgroundColor: darkThemeBackground,
    },
    unlockButton: {
        color: "red",
        height: 40,
        width: "20rem",
    },
    inputContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 16,
        // marginLeft: "2.2rem",
        // marginHorizontal: "2rem",
    },
    appText: {
        color: "white",
        fontSize: 16,
        textAlign: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    appTextContainer: {
        alignItems: "center", // Center the content horizontally
        justifyContent: "center", // Center the content vertically
    },
    image: {
        width: "8rem",
        height: "8rem",
    },
    "@media (max-width: 7px)": {
        contentContainer: {
            flexDirection: "column",
        },
        nonHiddenTicketsContainer: {
            width: "100%",
        },
        rightContainer: {
            marginLeft: 0,
            marginTop: 10,
        },
    },
};
