import { StyleSheet } from "react-native";

import text from "../../../../styles/text";
import views from "../../../../styles/views";
import { darkThemeBackground, white } from "../../../../utils/color";

export default StyleSheet.create({
    screen: {
        ...views.screen,
    },
    container: {
        width: "100%",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: darkThemeBackground,

        paddingLeft: 32,
        paddingRight: 32,

        paddingTop: 45,
        paddingBottom: 32,
    },

    header: {
        ...text.headerBold,
        color: white,

        marginBottom: 24,
    },

    description: {
        marginLeft: -40,
        marginRight: -32,
    },

    spacer: {
        flex: 0.1,
    },

    subtitle: {
        color: white,
        ...text.subtitleBold,
    },

    buttonsRow: {
        position: "fixed",
        bottom: 30,
        left: 30,
        right: 30,
        justifyContent: "center",
    },

    totalRow: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",

        marginBottom: 24,
    },

    actionTouchable: { flex: 1 },

    applePay: {
        flex: 1,
        height: 40,
    },
});
