import React, { useState, useEffect } from "react";
import { TouchableOpacity, Text, View, Alert } from "react-native-web";
import { black } from "../../../../utils/color";
import {
    PaymentRequestButtonElement,
    useStripe,
    useElements,
    CardElement,
} from "@stripe/react-stripe-js";

import { paymentsConfig } from "../../../../utils/config";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import style from "./style";
const CheckoutForm = (props) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentRequest, setPaymentRequest] = useState();
    const [paymentLocked, setPaymentLocked] = useState(false);
    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }
        const pr = stripe.paymentRequest({
            country: "US",
            currency: "usd",
            total: {
                label: "ARROSOCIAL INC. (Avenu)",
                amount: props.total,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then((result) => {
            if (result) {
                console.log("DEBUG set payment request");
                setPaymentRequest(pr);
            }
        });
    }, [stripe, elements]);

    useEffect(() => {
        if (paymentRequest) {
            console.log("DEBUG reached");
            paymentRequest.on("paymentmethod", async (e) => {
                console.log("DEBUG fired pr");
                // Create a payment intent on the server
                const { paymentIntent } = await fetch(
                    paymentsConfig.checkoutUrl,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ price: props.total }),
                    }
                ).then((r) => r.json());
                // Confirm the PaymentIntent without handling potential next actions (yet).

                const { error, truePaymentIntent } = stripe.confirmCardPayment(
                    paymentIntent,
                    { payment_method: e.paymentMethod.id },
                    { handleActions: false }
                );

                if (error) {
                    e.complete("fail");
                    return;
                }

                const { ticketsAvailable, eventData } =
                    await props.checkTicketLimits();

                if (!ticketsAvailable) {
                    Swal.fire({
                        icon: "error",
                        title: "Sold Out!",
                        text: `Sorry, but tickets are selling fast and some of the ones you selected are already sold out. You might need to refresh the event page to see what’s still available.`,
                        confirmButtonColor: black,
                    });
                    return;
                } else {
                    const queryParams = new URLSearchParams(
                        window.location.search
                    );
                    const eventId = queryParams.get("eventId");
                    e.complete("success");

                    props.buyTicketPrepared(eventData);

                    navigate("/event?eventId=" + eventId);
                }
            });
        }
    }, [paymentRequest]);

    const handleSubmit = async (e) => {
        try {
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            e.preventDefault();

            const { ticketsAvailable, eventData } =
                await props.checkTicketLimits();

            if (!ticketsAvailable) {
                Swal.fire({
                    icon: "error",
                    title: "Sold Out!",
                    text: `Sorry, but tickets are selling fast and some of the ones you selected are already sold out. You might need to refresh the event page to see what’s still available.`,
                    confirmButtonColor: black,
                });
                return;
            }

            if (!paymentLocked) {
                setPaymentLocked(true);
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }

                const { paymentIntent } = await fetch(
                    paymentsConfig.checkoutUrl,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ price: props.total }),
                    }
                ).then((r) => r.json());

                const { error: stripeError, truePaymentIntent } =
                    await stripe.confirmCardPayment(paymentIntent, {
                        payment_method: {
                            card: elements.getElement(CardElement),
                        },
                    });

                // if (true) {
                if (!stripeError) {
                    const queryParams = new URLSearchParams(
                        window.location.search
                    );
                    const eventId = queryParams.get("eventId");

                    props.buyTicketPrepared(eventData);
                    setPaymentLocked(false);
                    navigate("/event?eventId=" + eventId);
                } else {
                    setPaymentLocked(false);
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: stripeError.message,
                        confirmButtonColor: black,
                    });
                }
            }
        } catch (error) {
            console.error("DEBUG Error getting event document:", error);
            setPaymentLocked(false);
            Swal.fire({
                icon: "error",
                title: "Network Error",
                text: "Failed to establish network connection. Please try again with more reliable service of WiFi. Thank you!",
                confirmButtonColor: black,
            });
        }
    };

    if (props.total === 0) {
        return (
            <TouchableOpacity
                onPress={() => {
                    props
                        .checkTicketLimits()
                        .then(({ ticketsAvailable, eventData }) => {
                            if (ticketsAvailable) {
                                props.buyTicketPrepared(eventData);
                                const queryParams = new URLSearchParams(
                                    window.location.search
                                );
                                const eventId = queryParams.get("eventId");
                                navigate("/event?eventId=" + eventId);
                            } else {
                                Swal.fire({
                                    icon: "error",
                                    title: "Sold Out!",
                                    text: `Sorry, but tickets are selling fast and some of the ones you selected are already sold out. You might need to refresh the event page to see what’s still available.`,
                                    confirmButtonColor: black,
                                });
                            }
                        });
                }}
                style={style.cardPayButton}
            >
                <Text style={style.cardPayButtonText}>Claim</Text>
            </TouchableOpacity>
        );
    }

    if (paymentRequest) {
        return (
            <View>
                <View style={style.cardField}>
                    <CardElement />
                </View>
                <TouchableOpacity
                    onPress={handleSubmit}
                    style={style.cardPayButton}
                >
                    <Text style={style.cardPayButtonText}>Pay with Card</Text>
                </TouchableOpacity>
                <PaymentRequestButtonElement options={{ paymentRequest }} />
            </View>
        );
    }

    // Use a traditional checkout form.
    return (
        <View>
            <View style={style.cardField}>
                <CardElement />
            </View>
            <TouchableOpacity
                onPress={handleSubmit}
                style={style.cardPayButton}
            >
                <Text style={style.cardPayButtonText}>Pay with Card</Text>
            </TouchableOpacity>
        </View>
    );
};

export default CheckoutForm;
