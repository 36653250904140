import { Dimensions, StyleSheet } from "react-native";

import { color, darkThemeBackground } from "../../../../utils/color";
import viewStyles from "../../../../styles/viewStyles";

const { width: screenWidth } = Dimensions.get("window");

export default StyleSheet.create({
    screen: {
        ...viewStyles.screen,
        backgroundColor: darkThemeBackground,
        height: "100vh",
    },

    imageContainer: {
        margin: 32,
        marginBottom: 150,
        borderRadius: 10,

        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    password: {
        width: screenWidth * 0.85,
    },

    buttonBubble: {
        width: screenWidth * 0.85,
        backgroundColor: color,
    },

    buttonTitle: {
        color: "black",
    },

    image: {
        marginTop: 50,
        tintColor: "white",
    },

    spacer: {
        flex: 1,
    },

    errorText: {
        color: "white",
        textAlign: "center",
        marginVertical: 5,
        marginHorizontal: 24,
    },
});
