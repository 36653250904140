import React, { useState, useEffect } from "react";
import {
    FlatList,
    SectionList,
    View,
    SafeAreaView,
    Text,
    Image,
    TouchableOpacity,
} from "react-native";
import PropTypes from "prop-types";

import CircleButton from "../../../../../components/buttons/CircleButton";
import NavHeader from "../../../../../components/custom/NavHeader";
import ItemRow from "../../../../../components/rows/ItemRow";
import TextIconField from "../../../../../components/fields/TextIconField";
import IconButton from "../../../../../components/buttons/IconButton";

import { red } from "../../../../../utils/color";
import { AppStoreDownload } from "../../../utils/images";
import {
    CancelIcon,
    MoreIcon,
    PlusIcon,
    SearchIcon,
    Cancel,
} from "../../../../../utils/icons";
import { Check, DefaultProfileImage } from "../../../../../utils/images";
import { Box, Modal } from "@mui/material";

import style from "./style";
import ExternalLinkRow from "../../../components/ExternalLinkRow";

const updateInvitees = (
    event,
    updateEventInvitees,
    invitedGuests,
    userFirst,
    addEventCodeToUser
) => {
    const newInvites = [];
    const allInvited = [...event.eventInvitedUsers];
    const invitedIds = [];
    invitedGuests.forEach((user) => {
        const userId = user.id;
        invitedIds.push(userId);
        if (!event.eventInvitedUsers.includes(userId)) {
            newInvites.push(userId);
            allInvited.push(userId);
        }
        addEventCodeToUser(userId, event.id);
    });
    const fcmData = {
        userIds: newInvites,
        userNotification: {
            body: `${userFirst} has invited you to ${event.eventTitle}`,
        },
    };

    updateEventInvitees(event.id, invitedIds, fcmData);
};

const renderItem = (item, invitedGuests, setInvitedGuests, theme) => (
    <ItemRow
        theme={"dark"}
        title={`${item.userFirst} ${item.userLast}`}
        image={item.userMedia[0]}
        icons={[
            { icon: MoreIcon, onPress: () => {} },
            {
                icon: CancelIcon,
                tintColor: red,
                onPress: () => {
                    const index = invitedGuests.indexOf(item);
                    invitedGuests.splice(index, 1);
                    setInvitedGuests([...invitedGuests]);
                },
            },
        ]}
        default={DefaultProfileImage}
        onPress={() => {}}
    />
);

const renderSearchItem = (item, invitedGuests, setInvitedGuests, theme) => {
    const addFriend = () => {
        setInvitedGuests([...invitedGuests, item]);
    };
    const removeFriend = () => {
        const index = invitedGuests.indexOf(item);
        invitedGuests.splice(index, 1);
        setInvitedGuests([...invitedGuests]);
    };

    const addFriendIcon = {
        icon: PlusIcon,
        tintColor: "white",
        onPress: addFriend,
    };
    const removeFriendIcon = {
        icon: CancelIcon,
        tintColor: red,
        onPress: removeFriend,
    };

    return (
        <ItemRow
            theme={theme}
            title={`${item.userFirst} ${item.userLast}`}
            image={item.userMedia[0]}
            default={DefaultProfileImage}
            icons={[
                invitedGuests.includes(item) ? removeFriendIcon : addFriendIcon,
            ]}
            onPress={invitedGuests.includes(item) ? removeFriend : addFriend}
            width={"60vw"}
        />
    );
};

const renderEmpty = () => <></>;

const keyExtractor = (item) => (item.id ? item.id : item.phoneNumber);

const EventGuestsView = (props) => {
    const [invitedGuests, setInvitedGuests] = useState(props.invitedGuests);
    const [limitModalOpen, setLimitModalOpen] = useState(false);
    const [numEventUsers, setNumEventUsers] = useState(
        props.eventGoingUsers.length + props.invitedGuests.length
        // 9999
    );
    const [search, onChangeSearch] = useState("");
    const [focused, setFocused] = useState("");
    const [guestOpen, setGuestOpen] = useState(false);

    const friendData = [
        {
            title: "Invite Friends",
            data: invitedGuests,
        },
    ];

    return (
        <SafeAreaView style={style.screen}>
            <NavHeader
                onPress={() => {
                    props.navigate(-1);
                }}
            />
            <View style={style.container}>
                <Text style={style.header}>Guest List</Text>
                <SectionList
                    sections={friendData}
                    renderItem={({ item }) =>
                        renderItem(
                            item,
                            invitedGuests,
                            setInvitedGuests,
                            props.theme
                        )
                    }
                    renderSectionHeader={({ section: { title } }) => (
                        <ItemRow
                            theme={"dark"}
                            title={title}
                            background={true}
                            hideImage={true}
                            icons={
                                title === " "
                                    ? []
                                    : [
                                          {
                                              icon: PlusIcon,
                                              onPress: () =>
                                                  numEventUsers < 9999
                                                      ? setGuestOpen(true)
                                                      : setLimitModalOpen(true),
                                          },
                                      ]
                            }
                            onPress={() =>
                                numEventUsers < 9999
                                    ? setGuestOpen(true)
                                    : setLimitModalOpen(true)
                            }
                        />
                    )}
                    ListEmptyComponent={renderEmpty}
                    keyExtractor={keyExtractor}
                    showsVerticalScrollIndicator={false}
                />
                <Text style={style.text}>
                    Download the mobile app to invite contacts to your event!
                </Text>
                <TouchableOpacity
                    onPress={() =>
                        window.location.replace(
                            "https://apps.apple.com/us/app/avenu-social-event-discovery/id1487333983"
                        )
                    }
                    style={style.downloadbutton}
                >
                    <Image source={AppStoreDownload} style={style.image} />
                </TouchableOpacity>
                <Modal
                    open={guestOpen}
                    onClose={() => setGuestOpen(false)}
                    style={style.modal}
                >
                    <Box style={style.modalCard}>
                        <View style={{ height: "100%" }}>
                            <FlatList
                                style={style.list}
                                data={props.friends.filter((friend) =>
                                    friend.userSearch.startsWith(
                                        search.toLowerCase()
                                    )
                                )}
                                renderItem={({ item }) =>
                                    renderSearchItem(
                                        item,
                                        invitedGuests,
                                        setInvitedGuests,
                                        props.theme
                                    )
                                }
                                contentContainerStyle={style.flatlistContent}
                                keyExtractor={keyExtractor}
                                ListHeaderComponent={
                                    <View style={{ paddingLeft: 20 }}>
                                        <TextIconField
                                            theme={props.theme}
                                            value={search}
                                            placeholder="Search"
                                            onChangeText={onChangeSearch}
                                            onBlur={() => setFocused("")}
                                            onFocus={() => setFocused("SEARCH")}
                                            autoCapitalize="none"
                                            focused={focused === "SEARCH"}
                                            icon={SearchIcon}
                                        />
                                    </View>
                                }
                                ListEmptyComponent={
                                    <Text style={style.empty}>No friends</Text>
                                }
                                onRefresh={props.onRefresh}
                                refreshing={false}
                            />
                            <View style={style.sheetFooter}>
                                <CircleButton
                                    icon={Check}
                                    onPress={() => {
                                        setGuestOpen(false);
                                    }}
                                />
                            </View>
                        </View>
                    </Box>
                </Modal>
                <View style={style.footer}>
                    <CircleButton
                        icon={Check}
                        onPress={() => {
                            props.setInvitedGuests(invitedGuests);
                            const temp = props.uiCompleted;
                            temp.guests = true;
                            props.setUiCompleted(temp);
                            props.navigate(-1);
                        }}
                    />
                </View>
            </View>
            <Modal
                open={limitModalOpen}
                onClose={() => setLimitModalOpen(false)}
                style={style.modal}
            >
                <Box style={style.limitModalCard}>
                    <IconButton
                        icon={Cancel}
                        tintColor={"white"}
                        size={20}
                        style={style.modalClose}
                        onPress={() => setLimitModalOpen(false)}
                    />
                    <Text style={style.modalTitle}>Event at Capacity</Text>
                    <Text style={style.modalText}>
                        This event has 9,999 guests and is therefore at its
                        limit. Create a second, concurrent event to handle
                        larger event capacities.
                    </Text>
                </Box>
            </Modal>
        </SafeAreaView>
    );
};

EventGuestsView.propTypes = {
    navigation: PropTypes.object.isRequired,
};

export default EventGuestsView;
