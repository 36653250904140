import React from "react";
import { Text } from "react-native";
import PropTypes from "prop-types";

import Section from "../../hoc/Section";

import style from "./style";

const getTextStyleTheme = (key, selected, small) => ({
  ...style.text,
  color: "gray",
  ...(key === selected && (style.textSelectedDarktheme)),
  ...(small && style.textSmall),
});

const TextButtonSection = Section((props) => (
  <Text
    style={getTextStyleTheme(props.section.key, props.selected, props.small)}
  >
    {props.section.title}
  </Text>
));

TextButtonSection.defaultProps = {
  small: false,
  dark: false,
};

TextButtonSection.propTypes = {
  sections: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  small: PropTypes.bool,
  dark: PropTypes.bool,
};

export default React.memo(TextButtonSection);