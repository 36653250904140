import { StyleSheet } from "react-native";

import text from "../../../../../styles/text";
import views from "../../../../../styles/views";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingLeft: 32,
    paddingRight: 32,
    justifyContent: "flex-end",
  },

  wrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },

  footer: {
    alignSelf: "flex-end",
    marginBottom: 24,
  },

  header: {
    ...text.headerBold,

    marginTop: 40,
    marginBottom: 24,
    color: "white"
  },

  row: {
    marginBottom: 24,
  },

  screen: {
    ...views.screen,
  },

  spacer: {
    flex: 0.5,
  },
});
