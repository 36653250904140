import React, { useEffect, useState } from "react";
import { View, SafeAreaView } from "react-native";
import PropTypes from "prop-types";

import { Plus } from "../../../../utils/images";
import CircleButton from "../../../../components/buttons/CircleButton";
import TextButtonSection from "../../../../components/buttons/TextButtonSection";
import EventsListView from "../EventsListView";

import style from "./style";

const EventsHostingView = (props) => {
  const [section, setSection] = useState("UPCOMING");

  return (
    <SafeAreaView style={style.screen}>
      <TextButtonSection
        sections={[
          { title: "Upcoming", key: "UPCOMING", onPress: () => setSection("UPCOMING") },
          { title: "Recurring", key: "RECURRING", onPress: () => setSection("RECURRING") },
          { title: "Past", key: "PAST", onPress: () => setSection("PAST") },
        ]}
        selected={section}
        theme={"dark"}
      />
      <View style={style.container}>
        {section === "UPCOMING" && (
          <EventsListView
            data={props.events.Upcoming}
            navigate={props.navigate}
            setCurrEventId={props.setCurrEventId}
            upcoming={true}
            // onRefresh={props.onRefresh.Upcoming}
            route="EventsHosting"
          />
        )}
        {section === "RECURRING" && (
          <EventsListView
            data={props.events.Recurring}
            navigate={props.navigate}
            // onRefresh={props.onRefresh.Recurring}
            route="EventsHosting"
            recurring={true}
          />
        )}
        {section === "PAST" && (
          <EventsListView
            data={props.events.Past}
            navigate={props.navigate}
            setCurrEventId={props.setCurrEventId}
            // onRefresh={props.onRefresh.Past}
            route="EventsHosting"
          />
        )}
        <View style={style.icon}>
          <CircleButton
            icon={Plus}
            onPress={() => props.navigate("/event/create")}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};


EventsHostingView.propTypes = {
  events: PropTypes.object.isRequired,
  readSearchedEvents: PropTypes.func.isRequired,
  clearSearchedEvents: PropTypes.func.isRequired,
  onRefresh: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
};

export default React.memo(EventsHostingView);
