import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";

import style from "./style";
import { charcoal, white, black } from "../../../../utils/color";

const HideHostsTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>Hide Hosts</Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      Hosts will only be visible to event co-hosts.
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

HideHostsTooltip.defaultProps = {};

HideHostsTooltip.propTypes = {
  theme: PropTypes.string,
};

export default React.memo(HideHostsTooltip);
