import React, { useState, useEffect, useContext } from "react";
import db from "../../firebaseConfig";

import {
    doc,
    setDoc,
    getDoc,
    updateDoc,
    getDocs,
    documentId,
    query,
    collection,
    where,
    orderBy,
    arrayUnion,
    Timestamp,
    writeBatch,
    increment,
} from "firebase/firestore";
import { generateId } from "../../utils/ids";
import { AuthContext } from "../auth/AuthContext";

const EventContext = React.createContext();

function EventProvider(props) {
    const [event, setEvent] = useState(null);
    const [currEventId, setCurrEventId] = useState(null);
    const [eventHostsUsers, setEventHostsUsers] = useState([]);
    const [eventArtistsUsers, setEventArtistsUsers] = useState([]);
    const [eventTicketScanners, setEventTicketScanners] = useState([]);
    const [eventGoingUsers, setEventGoingUsers] = useState([]);
    const [eventInvitedUsers, setEventInvitedUsers] = useState([]);
    const [eventGuestsUsers, setEventGuestsUsers] = useState([]);
    const [eventContacts, setEventContacts] = useState([]);
    const [fetchEvent, setFetchEvent] = useState(true);
    const [eventTicketSkuCounts, setEventTicketSkuCounts] = useState({});
    const [perGuestTicketCounts, setPerGuestTicketCounts] = useState({});

    const [hostedEvents, setHostedEvents] = useState({
        Upcoming: [],
        Recurring: [],
        Past: [],
    });
    const [fetchHosted, setFetchHosted] = useState(true);

    useEffect(() => {
        if (fetchEvent) {
            console.log("DEBUG fired event context");
            const queryParams = new URLSearchParams(window.location.search);
            const eventId = queryParams.get("eventId");
            let mounted = true;

            readEvent(eventId);
        }
    }, [fetchEvent]);

    async function fetchTicketSkuCounts(eventTicketTypes, userId) {
        let eventTicketSkuMap = {};
        let perGuestTicketMap = {};

        eventTicketTypes.forEach((type) => {
            if (!eventTicketSkuMap[type.ticketSku]) {
                eventTicketSkuMap[type.ticketSku] = 0;
            }
            if (!perGuestTicketMap[type.ticketSku]) {
                perGuestTicketMap[type.ticketSku] = 0;
            }
        });

        let q = query(
            collection(db, "tickets"),
            where("ticketEvent", "==", event.id)
        );

        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((documentSnapshot) => {
            let ticketData = documentSnapshot.data();
            if (ticketData["ticketSku"] in eventTicketSkuMap) {
                eventTicketSkuMap[ticketData["ticketSku"]] +=
                    ticketData["ticketQuantity"];
                if (ticketData["ticketUser"] === userId) {
                    perGuestTicketMap[ticketData["ticketSku"]] +=
                        ticketData["ticketQuantity"];
                }
            } else {
                eventTicketSkuMap[ticketData["ticketSku"]] =
                    ticketData["ticketQuantity"];
                if (ticketData["ticketUser"] === userId) {
                    perGuestTicketMap[ticketData["ticketSku"]] =
                        ticketData["ticketQuantity"];
                }
            }
        });
        setEventTicketSkuCounts(eventTicketSkuMap);
        setPerGuestTicketCounts(perGuestTicketMap);
    }

    async function readEvent(eventId, navigate, navPath) {
        const docSnap = await getDoc(doc(db, "events", eventId));
        if (docSnap.exists()) {
            const docData = docSnap.data();
            docData.id = eventId;
            setCurrEventId(eventId);

            // Get eventHosts user docs
            let hostUserIdBatches = [];
            for (let i = 0; i < docData["eventHosts"].length; i += 10) {
                hostUserIdBatches.push(docData["eventHosts"].slice(i, i + 10));
            }
            let hostBatchRefs = hostUserIdBatches.map(async (batchIds) => {
                return await getDocs(
                    query(
                        collection(db, "users"),
                        where(documentId(), "in", batchIds)
                    )
                );
            });

            let tempEventHostsUsers = [];
            await Promise.all(hostBatchRefs).then((querySnapshots) => {
                querySnapshots.forEach((querySnapshot) =>
                    querySnapshot.forEach((documentSnapshot) => {
                        const user = documentSnapshot.data();
                        user.id = documentSnapshot.id;

                        tempEventHostsUsers.push(user);
                    })
                );
            });

            // Get eventArtists user docs
            let artistUserIdBatches = [];
            if (docData["eventArtists"]) {
                for (let i = 0; i < docData["eventArtists"].length; i += 10) {
                    artistUserIdBatches.push(
                        docData["eventArtists"].slice(i, i + 10)
                    );
                }
            }
            let artistBatchRefs = artistUserIdBatches.map(async (batchIds) => {
                return await getDocs(
                    query(
                        collection(db, "users"),
                        where(documentId(), "in", batchIds)
                    )
                );
            });

            let tempEventArtistsUsers = [];
            await Promise.all(artistBatchRefs).then((querySnapshots) => {
                querySnapshots.forEach((querySnapshot) =>
                    querySnapshot.forEach((documentSnapshot) => {
                        const user = documentSnapshot.data();
                        user.id = documentSnapshot.id;

                        tempEventArtistsUsers.push(user);
                    })
                );
            });

            let ticketScannerUserIdBatches = [];
            if (docData.eventTicketScanners) {
                for (
                    let i = 0;
                    i < docData.eventTicketScanners.length;
                    i += 10
                ) {
                    ticketScannerUserIdBatches.push(
                        docData.eventTicketScanners.slice(i, i + 10)
                    );
                }
            }

            let ticketScannerBatchRefs = ticketScannerUserIdBatches.map(
                async (batchIds) => {
                    return await getDocs(
                        query(
                            collection(db, "users"),
                            where(documentId(), "in", batchIds)
                        )
                    );
                }
            );

            let tempEventTicketScannerUsers = [];
            await Promise.all(ticketScannerBatchRefs).then((querySnapshots) => {
                querySnapshots.forEach((querySnapshot) => {
                    querySnapshot.forEach((documentSnapshot) => {
                        const user = documentSnapshot.data();
                        user.id = documentSnapshot.id;

                        tempEventTicketScannerUsers.push(user);
                    });
                });
            });

            let tempEventGoingUsers = [];
            let tempEventInvitedUsers = [];
            let tempEventGuestsUsers = [];
            let tempEventGuestsUsersIds = Array.from(
                new Set([
                    ...docData["eventGoingUsers"],
                    ...docData["eventInvitedUsers"],
                ])
            );
            let tempEventContacts = docData["eventContacts"];
            if (tempEventContacts) {
                const hexCodes = [
                    "#8C52FF",
                    "#4285F4",
                    "#FF5757",
                    "#FFDE59",
                    "#1FFDBA",
                ];
                tempEventContacts.forEach((contact, index) => {
                    tempEventContacts[index] = {
                        ...contact,
                        id: "contact",
                        backgroundColor:
                            hexCodes[
                                Math.floor(Math.random() * hexCodes.length)
                            ],
                    };
                });
            }

            if (tempEventGuestsUsersIds.length > 0) {
                let userIdBatches = [];
                for (let i = 0; i < tempEventGuestsUsersIds.length; i += 10) {
                    userIdBatches.push(
                        tempEventGuestsUsersIds.slice(i, i + 10)
                    );
                }
                let batchRefs = userIdBatches.map(async (batchIds) => {
                    return await getDocs(
                        query(
                            collection(db, "users"),
                            where(documentId(), "in", batchIds)
                        )
                    );
                });
                await Promise.all(batchRefs).then((querySnapshots) => {
                    querySnapshots.forEach((querySnapshot) =>
                        querySnapshot.forEach((documentSnapshot) => {
                            let userDocData = documentSnapshot.data();
                            let id = documentSnapshot.id;
                            userDocData.id = id;
                            if (userDocData) {
                                tempEventGuestsUsers.push(userDocData);
                                if (docData["eventInvitedUsers"].includes(id)) {
                                    tempEventInvitedUsers.push(userDocData);
                                }
                                if (docData["eventGoingUsers"].includes(id)) {
                                    tempEventGoingUsers.push(userDocData);
                                }
                            }
                        })
                    );
                });
            }
            setEventInvitedUsers(tempEventInvitedUsers);
            setEventGoingUsers(tempEventGoingUsers);
            setEventGuestsUsers(tempEventGuestsUsers);
            setEventHostsUsers(tempEventHostsUsers);
            setEventArtistsUsers(tempEventArtistsUsers);
            setEventTicketScanners(tempEventTicketScannerUsers);
            setEventContacts(tempEventContacts);
            console.log("doc data:", docData);
            setEvent(docData);

            if (navigate) {
                if (navPath === "/event/edit") {
                    navigate(navPath, { state: { editEvent: docData } });
                } else {
                    navigate(`/event?eventId=${eventId}`);
                }
            }
        } else {
            console.log("No doc ;-;");
        }
        setFetchEvent(false);
    }

    const updateGoingUsers = (userId, event) => {
        if (!event["eventGoingUsers"].includes(userId)) {
            const eventRef = doc(db, "events", event.id);
            updateDoc(eventRef, {
                eventGoingUsers: arrayUnion(userId),
                eventRecommendedIds: arrayUnion(userId),
            }).then(() => {
                setFetchEvent(true);
            });
        }
    };

    const addEventCodeToUser = (userId, event) => {
        const userRef = doc(db, "users", userId);
        updateDoc(userRef, {
            userEventsAttended: arrayUnion(event.id),
        }).then(() => {
            setFetchEvent(true);
        });
    };

    const readHostingEvents = async (userId) => {
        const eventHostingQuery = query(
            collection(db, "events"),
            orderBy("createdAt", "desc"),
            where("eventHosts", "array-contains", userId)
        );

        let querySnapshot = await getDocs(eventHostingQuery);

        const hostedEvents = { Upcoming: [], Recurring: [], Past: [] };

        querySnapshot.forEach((documentSnapshot) => {
            const event = documentSnapshot.data();
            event.id = documentSnapshot.id;

            const eventEnd = new Timestamp(
                event.eventEnd.seconds,
                event.eventEnd.nanoseconds
            );
            if (eventEnd.toDate() >= new Date()) {
                hostedEvents.Upcoming.push(event);
            } else {
                hostedEvents.Past.push(event);
            }
        });

        const recurringQuery = query(
            collection(db, "recurring_events"),
            where("eventHosts", "array-contains", userId)
        );

        let recurringSnapshot = await getDocs(recurringQuery);

        recurringSnapshot.forEach((documentSnapshot) => {
            const event = documentSnapshot.data();
            event.id = documentSnapshot.id;
            hostedEvents.Recurring.push(event);
        });

        hostedEvents.Upcoming.sort((eventA, eventB) => {
            return eventB.eventEnd - eventA.eventEnd;
        });

        hostedEvents.Recurring.sort((eventA, eventB) => {
            return eventB.createdAt - eventA.createdAt;
        });

        hostedEvents.Past.sort((eventA, eventB) => {
            return eventB.eventEnd - eventA.eventEnd;
        });

        console.log(hostedEvents);

        setHostedEvents(hostedEvents);
        setFetchHosted(false);
    };

    const buyFreeTicket = (userId, event) => {
        const eventRef = doc(db, "events", event.id);
        getDoc(eventRef).then((eventSnapshot) => {
            if (eventSnapshot.exists()) {
                console.log("DEBUG event exists");
                const eventData = eventSnapshot.data();
                if (!event["eventGoingUsers"].includes(userId)) {
                    console.log(
                        "DEBUG freeTicketBuy triggered eventid:",
                        event.id
                    );
                    console.log("DEBUG event", event);
                    addEventCodeToUser(userId, event);
                    const type = event.eventTicketTypes[0];
                    // Generate ticket
                    const ticketData = {
                        createdAt: new Date(),
                        ticketSku: type.ticketSku,
                        ticketType: type.ticketName,
                        ticketQuantity: 1,
                        ticketState: "PURCHASED",
                        ticketTitle: event.eventTitle,
                        ticketMedia: event.eventMedia[0] ?? "",
                        ticketEvent: event.id,
                        ticketUser: userId,
                    };
                    const ticketId = generateId();
                    // Update firestore: add ticket to tickets collection & to event doc's eventTickets/eventGoingUsers fields
                    const ticketRef = doc(db, "tickets", ticketId);
                    setDoc(ticketRef, ticketData).then(() => {
                        const updatedEvent = { ...eventData };
                        if (
                            !updatedEvent.eventTicketTypes[0].hasOwnProperty(
                                "ticketsPurchased"
                            )
                        ) {
                            updatedEvent.eventTicketTypes[0].ticketsPurchased = 0;
                        }
                        updatedEvent.eventTicketTypes[0].ticketsPurchased++;

                        const updateObj = {
                            eventTickets: arrayUnion(ticketId),
                            eventGoingUsers: arrayUnion(ticketData.ticketUser),
                            eventRecommendedIds: arrayUnion(
                                ticketData.ticketUser
                            ),
                            eventTicketTypes: updatedEvent.eventTicketTypes,
                        };

                        updateDoc(eventRef, updateObj).then(() => {
                            setFetchEvent(true);
                        });
                    });
                } else {
                    console.log("Event already has user!");
                }
            }
        });
    };

    const checkTicketLimitsAndFetchEvent = async (eventId, ticketDetails) => {
        try {
            const eventRef = doc(db, "events", eventId);
            const eventSnapshot = await getDoc(eventRef);

            if (!eventSnapshot.exists()) {
                throw new Error("Event document does not exist.");
            }

            console.log("DEBUG event exists");

            const eventData = eventSnapshot.data();
            eventData.id = eventId;
            console.log("DEBUG event", eventData);

            let ticketsAvailable = true;

            Object.entries(ticketDetails).forEach(([index, quantity]) => {
                const ticketType = eventData.eventTicketTypes[index];
                if (
                    ticketType.ticketLimit !== "" &&
                    ticketType.ticketsPurchased + quantity >
                        ticketType.ticketLimit
                ) {
                    ticketsAvailable = false;
                }
            });
            return { ticketsAvailable, eventData };
        } catch (error) {
            throw new Error(`Failed to fetch event data: ${error}`);
        }
    };

    const buyTicket = (
        userId,
        event,
        ticketDetails,
        setTicketDetails,
        referralCode
    ) => {
        console.log("DEBUG eventid:", event.id);
        console.log("DEBUG event", event);
        addEventCodeToUser(userId, event);

        const batch = writeBatch(db);

        const eventRef = doc(db, "events", event.id);

        Object.entries(ticketDetails).map(([index, quantity]) => {
            const ticketType = event["eventTicketTypes"][index];
            // Generate ticket
            const ticketData = {
                createdAt: new Date(),
                ticketSku: ticketType.ticketSku,
                ticketType: ticketType.ticketName,
                ticketQuantity: quantity,
                ticketState: "PURCHASED",
                ticketTitle: event.eventTitle,
                ticketMedia: event.eventMedia[0] ?? "",
                ticketEvent: event.id,
                ticketUser: userId,
                ticketReferralCode: referralCode ? referralCode : "",
            };
            const ticketId = generateId();
            const ticketRef = doc(db, "tickets", ticketId);
            batch.set(ticketRef, ticketData);

            const eventRef = doc(db, "events", ticketData.ticketEvent);

            batch.update(eventRef, {
                eventTickets: arrayUnion(ticketId),
                eventGoingUsers: arrayUnion(ticketData.ticketUser),
                eventRecommendedIds: arrayUnion(ticketData.ticketUser),
            });

            if (referralCode) {
                const referralRef = doc(db, "miscellaneous", "referral_codes");
                batch.update(referralRef, {
                    [referralCode]: arrayUnion({
                        purchaseDate: new Date(),
                        ticketId: ticketId,
                    }),
                });
            }

            // Update eventTicketTypes in memory
            if (
                event.eventTicketTypes[index] &&
                event.eventTicketTypes[index].ticketsPurchased
            ) {
                event.eventTicketTypes[index].ticketsPurchased += quantity;
            } else {
                event.eventTicketTypes[index].ticketsPurchased = quantity;
            }
        });

        batch.update(eventRef, {
            eventTicketTypes: event.eventTicketTypes,
        });

        batch
            .commit()
            .then(() => {
                console.log("Batched write successful");
                setFetchEvent(true);
                setTicketDetails({});
            })
            .catch((error) => {
                console.error("Error performing batched write:", error);
            });
    };

    return (
        <EventContext.Provider
            value={{
                event: event,
                currEventId: currEventId,
                setCurrEventId,
                eventHostsUsers: eventHostsUsers,
                eventArtistsUsers: eventArtistsUsers,
                eventTicketScanners,
                eventGoingUsers: eventGoingUsers,
                eventInvitedUsers: eventInvitedUsers,
                eventGuestsUsers: eventGuestsUsers,
                eventContacts: eventContacts,
                buyFreeTicket: buyFreeTicket,
                checkTicketLimitsAndFetchEvent: checkTicketLimitsAndFetchEvent,
                buyTicket: buyTicket,
                fetchEvent: fetchEvent,
                eventTicketSkuCounts: eventTicketSkuCounts,
                perGuestTicketCounts: perGuestTicketCounts,
                updateGoingUsers: updateGoingUsers,
                addEventCodeToUser: addEventCodeToUser,
                hostedEvents,
                readHostingEvents,
                fetchHosted,
                readEvent,
                fetchTicketSkuCounts,
            }}
        >
            {props.children}
        </EventContext.Provider>
    );
}

export { EventContext, EventProvider };
