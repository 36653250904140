import React, { useEffect } from "react";
import EventPersonnelView from "../views/EventPersonnelView";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CreateEventContext } from "../CreateEventContext";
import { AuthContext } from "../../../auth/AuthContext";

const EventPersonnelScreen = (props) => {
    const { 
        personnel, setPersonnel,
         artists, setArtists, 
         ticketScanners, setTicketScanners,
         uiCompleted, setUiCompleted 
        } = useContext(CreateEventContext);
    const { user, userAuth, readUserIfPresent, friends } = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (userAuth && !user) {
            console.log("fired EventPersonnelScreen")
            readUserIfPresent(userAuth.user.uid);
        }
    }, [userAuth])

    // if user data is not fetched, do not display 
    if (!user) {
        return <></>
    }

    return (
        <EventPersonnelView 
            user={user}
            userId={userAuth.user.uid}
            personnel={personnel}
            setPersonnel={setPersonnel}
            friends={friends}
            artists={artists}
            setArtists={setArtists}
            ticketScanners={ticketScanners}
            setTicketScanners={setTicketScanners}
            uiCompleted={uiCompleted}
            setUiCompleted={setUiCompleted}
            navigate={navigate}
        />
    );
}

export default EventPersonnelScreen;