import React, { useState, useEffect } from "react";
import db from "../../firebaseConfig";
import { isToday } from "../../utils/time";
import { doc, setDoc, getDoc, updateDoc, getDocs, documentId, query, collection, where } from "firebase/firestore";

const VenueContext = React.createContext();

function VenueProvider(props) {
  const [venue, setVenue] = useState(null);
  const [venueGoing, setVenueGoing] = useState(null)
  const [fetchVenue, setFetchVenue] = useState(true)

  useEffect(() => {
    if (fetchVenue) {
        console.log("DEBUG fired venue context")
        const queryParams = new URLSearchParams(window.location.search);
        const venueId = queryParams.get('venueId');
        let mounted = true;

        async function readVenue() {
            const docSnap = await getDoc(doc(db, "venues_v1.3", venueId))
            if (docSnap.exists()) {
                const docData = docSnap.data()
                console.log("DEBUG - VenueDoc:", docData)

                // Get venueGoing user docs
                let venueGoing = [];
                let listVenueGoing = [];
                for (let userId in docData["venueGoing"]) {
                    if (isToday(docData["venueGoing"][userId])) {
                        listVenueGoing.push(userId);
                    }
                }
                if (listVenueGoing.length > 0) {
                    const q = query(collection(db, "users"), where(documentId(), "in", listVenueGoing));
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        venueGoing.push(doc.data());
                    })
                }
                setVenueGoing(venueGoing);
                setVenue(docData)
            }
            else {
                console.log("No doc ;-;")
            }
            setFetchVenue(false);
        }
    readVenue();

    return () => (mounted = false);
}
  }, [fetchVenue]);

  const updateVenueGoingList = (userId, venue) => {
    const venueGoing = venue.venueGoing;
    if (venueGoing[userId] && isToday(venueGoing[userId])) {
        delete venueGoing[userId];
        const venueRef = doc(db, "venues_v1.3", venue.id);
        updateDoc(venueRef, {
            venueGoing: venueGoing, 
         }).then(() => {
           setFetchVenue(true);
         })
  }
    else {
        const venueRef = doc(db, "venues_v1.3", venue.id);
        updateDoc(venueRef, {
             venueGoing: {
                ...venueGoing,
                [userId]: new Date()
            }
          }).then(() => {
            setFetchVenue(true);
          })
    }
  }

  return (
    <VenueContext.Provider
      value={{
        venue: venue,
        venueGoing: venueGoing,
        updateVenueGoingList: updateVenueGoingList,
      }}
    >
      {props.children}
    </VenueContext.Provider>
  );
}

export { VenueContext, VenueProvider };
