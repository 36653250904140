import { StyleSheet } from "react-native";

export default StyleSheet.create({
  bubble: {
    flexDirection: "row",
    alignItems: "center",

    paddingLeft: 24,
    paddingRight: 24,

    marginLeft: 24,
    marginRight: 24,
    marginTop: 8,
    marginBottom: 8,

    borderRadius: 32,
    backgroundColor: "white",
  },

  textfield: {
    flex: 1,
    height: 64,
  },
});
