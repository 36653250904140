import { StyleSheet } from "react-native";

import { white, air, snow, steel, black } from "../../../utils/color";
import { body } from "../../../utils/font";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "80vw",
    flexDirection: "row",
    alignItems: "center",
  },

  icon: {
    width: 24,
    height: 24,

    marginLeft: 16,
  },

  iconDark: {
    tintColor: white,
  },

  image: {
    width: 48,
    height: 48,

    marginRight: 12,
    borderRadius: 24,
  },

  imageLeft: {
    width: 40,
    height: 40,

    borderRadius: 20,
    borderWidth: 2,
    borderColor: white,
  },

  imageRight: {
    width: 40,
    height: 40,

    marginLeft: 10,
    marginRight: 10,
    marginTop: -28,

    borderRadius: 20,
    borderWidth: 2,
    borderColor: white,
  },

  imageDark: {
    borderWidth: 1,
    borderColor: white,
  },

  line: {
    width: "90%",
    height: 1,
    marginTop: 10,
    backgroundColor: "gray",
  },

  lineDark: {
    backgroundColor: "transparent",
    backgroundColor: white,
  },

  row: {
    justifyContent: "center",
    alignItems: "center",
    height: 81,
  },

  rowSmall: {
    height: 57,
  },

  spacer: {
    flex: 1,
  },

  subtitle: {
    fontSize: body,
    color: "white",
  },

  subtitleDark: {
    color: "gray",
  },

  title: {
    marginBottom: 2,
    fontSize: body,
    color: "white",
  },

  titleDark: {
    color: "white",
  },

  wrapper: {
    justifyContent: "center",
    alignItems: "center",
  },

  littleFee: {
    marginLeft: 5,
    fontStyle: "italic",
    color: "gray",
    fontSize: 11,
  },
});
