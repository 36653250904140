import React from "react";
import { Platform, TextInput, View } from "react-native";
import PropTypes from "prop-types";

import { ash, charcoal, green, snow, steel } from "../../../utils/color";

import style from "./style";

const getFieldStyle = (height) => {
  const maxHeight = 300; // Adjust to your needs
  const finalHeight = height > maxHeight ? maxHeight : height;
  return finalHeight ? { ...style.field, height: finalHeight } : style.field;
};

const TextSmallField = (props) => {

  const textInputStyle = {
    ...style.text, 
    color: props.theme === 'dark' ? snow : charcoal,
    ...props.style
  };
  
  return (
    <View style={getFieldStyle(props.height)}>
      <View style={style.container}>
        <TextInput
          theme={props.theme}
          style={textInputStyle}
          value={props.value}
          placeholder={props.placeholder}
          placeholderTextColor={props.theme === 'dark' ? steel : ash}
          keyboardType={props.keyboardType}
          returnKeyType={props.returnKey}
          returnKeyLabel={props.returnKey}
          onChangeText={props.onChangeText}
          onSubmitEditing={props.onSubmitEditing}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          autoCapitalize={props.autoCapitalize}
          editable={props.editable}
          selectionColor={green}
          textAlign={props.textAlign}
          ref={props.setRef}
          multiline={props.multiline}
          onContentSizeChange={props.onContentSizeChange}
          maxLength={props.maxLength ?? null}
        />
      </View>
    </View>
  );
};

TextSmallField.defaultProps = {
  placeholder: "",
  returnKey: Platform.OS === "ios" ? "default" : "return",
  keyboardType: "default",
  onChangeText: () => { },
  onSubmitEditing: () => { },
  onBlur: () => { },
  onFocus: () => { },
  autoCapitalize: "sentences",
  editable: true,
  textAlign: "left",
  setRef: null,
  style: {},
};

TextSmallField.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  keyboardType: PropTypes.string,
  returnKey: PropTypes.string,
  onChangeText: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoCapitalize: PropTypes.string,
  editable: PropTypes.bool,
  textAlign: PropTypes.string,
  setRef: PropTypes.object,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default React.memo(TextSmallField);