import React from "react";
import { useState } from "react";

import db from "../../firebaseConfig";
import { getDoc, doc, getDocs, query, collection, documentId, where } from "firebase/firestore";

const RecurringEventContext = React.createContext();

function RecurringEventProvider(props) {
    const [recurringEvent, setRecurringEvent] = useState(null);
    const [eventGuests, setEventGuests] = useState([]);
    const [eventHosts, setEventHosts] = useState([]);
    const [nextEvent, setNextEvent] = useState(null);

    const readRecurringEvent = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const eventId = queryParams.get("eventId");
        const docSnapshot = await getDoc(doc(db, "recurring_events", eventId));

        if (!docSnapshot.exists()) {
            return;
        }
        const recurringEvent = docSnapshot.data();
        recurringEvent.id = docSnapshot.id;
        setRecurringEvent(recurringEvent);

        const hostBatchUsers = [];
        for (let i = 0; i < recurringEvent.eventHosts.length; i+= 10) {
            hostBatchUsers.push(recurringEvent.eventHosts.slice(i, i + 10));
        }

        const hostBatchRefs = hostBatchUsers.map(async (batch) => {
            return await getDocs(
                query(
                    collection(db, "users"),
                    where(documentId(), "in", batch)
                )
            );
        });

        await Promise.all(hostBatchRefs)
            .then((querySnapshots) => {
                const hosts = [];
                querySnapshots.forEach((querySnapshot) => {
                    querySnapshot.forEach((documentSnapshot) => {
                        const user = documentSnapshot.data();
                        user.id = documentSnapshot.id;

                        hosts.push(user);
                    })
                })
                setEventHosts(hosts);
            })

        const guests = recurringEvent.eventInvitedUsers.concat(recurringEvent.eventGoingUsers);
        const guestBatchUsers = [];
        for (let i = 0; i < guests.length; i+= 10) {
            guestBatchUsers.push(guests.slice(i, i + 10));
        }

        const guestBatchRefs = guestBatchUsers.map( async (batch) => {
            return await getDocs(
                query(
                    collection(db, "users"),
                    where(documentId(), "in", batch)
                )
            );
        });

        await Promise.all(guestBatchRefs)
            .then((querySnapshots) => {
                querySnapshots.forEach((querySnapshot) => {
                    const guests = [];
                    querySnapshot.forEach((documentSnapshot) => {
                        const user = documentSnapshot.data();
                        user.id = documentSnapshot.id;

                        guests.push(user);
                    })
                });
                setEventGuests(guests);
            })

        const nextDocSnapshot = 
            await getDoc(doc(db, "events", recurringEvent.nextEventId));
        
        const nextEvent = nextDocSnapshot.data();
        nextEvent.id = nextDocSnapshot.id;

        setNextEvent(nextEvent);
    }

    return (
        <RecurringEventContext.Provider
            value={{
                recurringEvent,
                readRecurringEvent,
                eventGuests,
                eventHosts,
                nextEvent
            }}
        >
            {props.children}
        </RecurringEventContext.Provider>
    );
}

export { RecurringEventContext, RecurringEventProvider};