import React from "react";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";
import { charcoal, white, black } from "../../../../utils/color";

import style from "./style";

const HiddenTicketTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>
      Hide Tickets
    </Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
    Hide tickets and create an unlock code for certain guests! Share the unlock code for promotions, discounts, and specific groups.
    {"\n\n"}Tickets can be unhidden. Unlock codes are 5-12 characters and cannot be changed.
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

HiddenTicketTooltip.defaultProps = {};

HiddenTicketTooltip.propTypes = {};

export default React.memo(HiddenTicketTooltip);
