import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    height: 48,

    paddingLeft: 16,
    paddingRight: 16,
    margin: 8,
  },

  text: {
    textAlign: "center",
    color: "white",
  },
});
