import React from "react";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";
import { charcoal, white, black } from "../../../../utils/color";

import style from "./style";

const ExternalTicketTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>Ticket Links</Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      If you’re using an outside ticketing service, toggle this switch on and paste the link here
      for your guests to easily access through Avenu.
      {"\n\n"}If you choose to use an outside ticketing service, you cannot later edit this event to
      sell tickets through Avenu.
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

ExternalTicketTooltip.defaultProps = {};

ExternalTicketTooltip.propTypes = {};

export default React.memo(ExternalTicketTooltip);
