import { StyleSheet } from "react-native";
import { snow, steel } from "../../../utils/color";
import text from "../../../styles/text";

export default StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "flex-start",

    paddingLeft: 16,
    paddingRight: 16,
    margin: 16,

    minHeight: 48,
    borderRadius: 8,
    backgroundColor: "whitesmoke",
  },

  picker: {
    flex: 1,
    width: "100%",
    color: snow,
  },

  text: {
    marginTop: 16,
    marginBottom: 16,
    color: steel,
    ...text.body,
  },
});
