import { Dimensions, StyleSheet } from "react-native";
import { color, darkThemeBackground } from "../../../../utils/color";
import viewStyles from "../../../../styles/viewStyles";

const { width: screenWidth } = Dimensions.get("window");

export default StyleSheet.create({
    screen: {
        ...viewStyles.screen,
        backgroundColor: darkThemeBackground,
        height: "100vh",
    },

    imageContainer: {
        margin: 32,
        marginBottom: 150,
        borderRadius: 10,

        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    buttonBubble: {
        height: 70,
        width: screenWidth * 0.9,
        borderRadius: 40,
        backgroundColor: color,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    buttonBubble2: {
        height: 70,
        width: screenWidth * 0.9,
        borderRadius: 40,
        transform: [{ scale: 0.85 }],
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.6,
        shadowRadius: 12,
        elevation: 12,
    },

    buttonTitle: {
        color: "black",
        fontSize: 19,
    },

    text: {
        color: "white",
        textAlign: "center",
        marginTop: 32,
    },

    textMiddle: {
        color: "white",
        fontSize: 20,
        textAlign: "center",
        marginTop: 16,
        marginBottom: 16,
    },

    image: {
        tintColor: "white",
        margin: 20,
    },

    // wrapper: {
    //     flex: 1,
    // },

    spacer: {
        flex: 1,
    },

    version: {
        color: "grey",
        marginTop: -70,
        marginLeft: 310,
    },
});
