import React from "react";
import PropTypes from "prop-types";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";

import style from "./style";
import { charcoal, white, black } from "../../../../utils/color";

const HideGuestsTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>Hide Guests</Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      Hidden guest lists will only be visible to event co-hosts.
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

HideGuestsTooltip.defaultProps = {};

HideGuestsTooltip.propTypes = {
  theme: PropTypes.string,
};

export default React.memo(HideGuestsTooltip);
