import { StyleSheet, Dimensions } from "react-native";

import views from "../../../../styles/views";
import { darkThemeBackground } from "../../../../utils/color";

export default StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: darkThemeBackground
  },

  footer: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 12,
  },

  list: {
    ...views.list,
  },

  pill: {
    ...views.pill,
  },

  screen: {
    // ...views.screen,
  },

  sheet: {
    ...views.sheet,
  },

  spacer: {
    flex: 1,
  },
  button: {
    position: "absolute",
    width: "100%",
    bottom: 3,
    left: 7,
  },

  tooltipButton: {
    paddingHorizontal: 8,
    paddingVertical: 6,
  },
});