import React, { useState, useEffect } from "react";
import db from "../../../firebaseConfig";

import {
    doc,
    setDoc,
    getDoc,
    updateDoc,
    getDocs,
    documentId,
    query,
    collection,
    where,
    arrayUnion,
    FieldValue,
    Timestamp,
    writeBatch,
} from "firebase/firestore";

import {
    getStorage,
    ref,
    uploadBytes,
    getDownloadURL,
    uploadString,
} from "firebase/storage";
import { generateId, generateEventRef } from "../../../utils/ids";
import { sendNotification } from "../../../utils/notifications";

const CreateEventContext = React.createContext();

function CreateEventProvider(props) {
    const [eventRef, setEventRef] = useState(generateEventRef());
    const [eventId, setEventId] = useState(generateId());
    const [recurringEventId, setRecurringEventId] = useState(generateId());

    const [media, setMedia] = useState([]);
    const [description, onChangeDescription] = useState("");
    const [name, onChangeName] = useState("");
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [recurringInterval, setRecurringInterval] = useState("Not recurring");

    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({
        latitude: 37.4275,
        longitude: -122.1697,
    });

    const [personnel, setPersonnel] = useState([]);
    const [artists, setArtists] = useState([]);
    const [ticketScanners, setTicketScanners] = useState([]);

    const [tickets, setTickets] = useState([
        {
            ticketSku: generateId(),
            ticketName: "General Admission",
            ticketPrice: 0,
            ticketPerGuestLimit: 2,
            ticketCode: "",
            pastTicketCode: "",
            ticketsPurchased: 0,
        },
    ]);
    const [link, setLink] = useState("");
    const [eventOtherLinks, setEventOtherLinks] = useState([]);

    const [invitedGuests, setInvitedGuests] = useState([]);

    const [privacy, onChangePrivacy] = useState(true);
    const [canInvite, setCanInvite] = useState(false);
    const [hideGuests, setHideGuests] = useState(false);
    const [hideHosts, setHideHosts] = useState(false);
    const [announce, onChangeAnnounce] = useState(false);
    const [gradientIndex, setGradientIndex] = useState(null);

    const [uiCompleted, setUiCompleted] = useState({
        personnel: false,
        tickets: false,
        guests: false,
    });
    const [editEventSet, setEditEventSet] = useState(false);

    const setEvent = (
        editEvent,
        eventHosts,
        eventArtists,
        eventInvitedUsers,
        eventTicketScanners
    ) => {
        const eventStart = new Timestamp(
            editEvent.eventStart.seconds,
            editEvent.eventStart.nanoseconds
        );
        const eventEnd = new Timestamp(
            editEvent.eventEnd.seconds,
            editEvent.eventEnd.nanoseconds
        );
        setEventRef(editEvent.eventRefCode);
        setEventId(editEvent.id);
        setRecurringEventId(editEvent.recurringEventid);
        setMedia(editEvent.eventMedia);
        onChangeDescription(editEvent.eventDescription);
        onChangeName(editEvent.eventTitle);
        setStart(eventStart.toDate());
        setEnd(eventEnd.toDate());
        setRecurringInterval(editEvent.recurringInterval ?? "Not recurring");
        setAddress(editEvent.eventLocation.locationAddress);
        setCoordinates(editEvent.eventLocation.locationCoordinates);
        setPersonnel(eventHosts);
        setArtists(eventArtists);
        setTicketScanners(eventTicketScanners);
        setTickets(editEvent.eventTicketTypes);
        setLink(editEvent.eventExternalLink);
        setEventOtherLinks(editEvent.eventOtherLinks);
        setInvitedGuests(eventInvitedUsers);
        onChangePrivacy(editEvent.eventPrivacy);
        setCanInvite(editEvent.eventGuestsCanInvite);
        setHideGuests(editEvent.eventHideGuests);
        setHideHosts(editEvent.eventHideHosts);
        onChangeAnnounce(editEvent.eventAnnounce);
        setGradientIndex(editEvent.eventGradientIndex);
        setEditEventSet(true);
    };

    const clear = () => {
        setEventRef(generateEventRef());
        setEventId(generateId());
        setRecurringEventId(generateId());
        setMedia([]);
        onChangeDescription("");
        onChangeName("");
        setStart();
        setEnd();
        setRecurringInterval("Not recurring");
        setAddress("");
        setCoordinates({ latitude: 37.4275, longitude: -122.1697 });
        setPersonnel([]);
        setArtists([]);
        setTicketScanners([]);
        setTickets([
            {
                ticketSku: generateId(),
                ticketName: "General Admission",
                ticketPrice: 0,
                ticketCode: "",
                pastTicketCode: "",
            },
        ]);
        setLink("");
        setEventOtherLinks([]);
        setInvitedGuests([]);
        onChangePrivacy(true);
        setCanInvite(false);
        setHideGuests(false);
        setHideHosts(false);
        onChangeAnnounce(false);
        setGradientIndex(null);
        setUiCompleted({ personnel: false, tickets: false, guests: false });
        setEditEventSet(false);
    };

    const createEvent = async (
        eventId,
        recurringEventId,
        eventData,
        fcmData,
        setCurrEventId,
        readEvent,
        navigate
    ) => {
        console.log("DEBUG createEvent", eventData);

        const eventMedia = [];
        const storage = getStorage();
        let mediaUrl = null;
        if (eventData.eventMedia.length > 0) {
            await new Promise((resolve, reject) => {
                eventData.eventMedia.forEach(async (media, index) => {
                    const storageRef = ref(
                        storage,
                        `events/${eventId}-${index}.jpg`
                    );

                    if (media instanceof File || media instanceof Blob) {
                        await uploadBytes(storageRef, media).then(
                            (snapshot) => {
                                console.log("Uploaded a blob or file!");
                                getDownloadURL(snapshot.ref).then(
                                    (downloadURL) => {
                                        mediaUrl = downloadURL;

                                        console.log("DEBUG mediaUrl", mediaUrl);
                                        if (mediaUrl === null) {
                                            throw new Error();
                                        }

                                        eventMedia.push(mediaUrl);

                                        if (
                                            eventMedia.length ===
                                            eventData.eventMedia.length
                                        )
                                            resolve();
                                    }
                                );
                            }
                        );
                    } else {
                        await uploadString(storageRef, media, "data_url").then(
                            (snapshot) => {
                                console.log("Uploaded data url!");
                                getDownloadURL(snapshot.ref).then(
                                    (downloadURL) => {
                                        mediaUrl = downloadURL;

                                        console.log("DEBUG mediaUrl", mediaUrl);
                                        if (mediaUrl === null) {
                                            throw new Error();
                                        }

                                        eventMedia.push(mediaUrl);

                                        if (
                                            eventMedia.length ===
                                            eventData.eventMedia.length
                                        )
                                            resolve();
                                    }
                                );
                            }
                        );
                    }
                });
            });
        }

        if (eventData.eventRecurring === "Weekly") {
            const docSnap = await getDoc(
                doc(db, "recurring_events", recurringEventId)
            );
            if (!docSnap.exists()) {
                const dateString = eventData.eventStart.toLocaleString(
                    "en-us",
                    { weekday: "long" }
                );
                const dayOfWeek = dateString.split(",")[0];

                const { eventArtist, ...eventDataWithoutArtist } = eventData;

                setDoc(doc(db, "recurring_events", recurringEventId), {
                    ...eventDataWithoutArtist,
                    eventMedia,
                    nextEventId: eventId,
                    nextEventCreateTime: eventData.eventEnd,
                    dayOfWeek,
                    createdAt: new Date(),
                })
                    .then(() => {
                        console.log("Debug create recurringEvent FIRED");
                    })
                    .catch((error) => {
                        console.log(
                            `createUser = (${eventId}, ${eventData}) => ${error}`
                        );
                    });
            }
        }

        const newEvent = {
            ...eventData,
            eventMedia,
            createdAt: new Date(),
        };

        if (eventData.eventRecurring === "Weekly") {
            newEvent.eventRecurringId = recurringEventId;
        }

        console.log("DEBUG event id:", eventId, "eventData", eventData);

        // Update firestore: add events to events collection
        const eventRef = doc(db, "events", eventId);
        setDoc(eventRef, newEvent)
            .then(() => {
                // send notifications
                sendNotification(fcmData.userIds, fcmData.userNotification, {
                    type: "EVENT",
                    eventId,
                });
                sendNotification(fcmData.hostIds, fcmData.hostNotification, {
                    type: "HOST",
                    eventId,
                });
                if (fcmData.friendIds && fcmData.friendNotification) {
                    sendNotification(
                        fcmData.friendIds,
                        fcmData.friendNotification,
                        {
                            type: "EVENT",
                            eventId,
                        }
                    );
                }
                setCurrEventId(eventId);
                readEvent(eventId, navigate);
                clear();
            })
            .catch((error) => {
                console.log(
                    `createEvent = (${eventId}, ${eventData}) => ${error}`
                );
            });
    };

    const updateEvent = async (
        eventId,
        eventData,
        fcmData,
        recurringId,
        readEvent,
        navigate
    ) => {
        console.log("updateEvent fired with ", eventData);

        const event = { ...eventData };
        const eventMedia = [];
        const storage = getStorage();
        let mediaUrl = null;

        if (eventData.eventMedia) {
            // if event cover photo submitted, then upload to storage
            if (eventData.eventMedia.length > 0) {
                await new Promise((resolve, reject) => {
                    eventData.eventMedia.forEach(async (media, index) => {
                        const storageRef = ref(
                            storage,
                            `events/${eventId}-${index}.jpg`
                        );

                        if (media instanceof File || media instanceof Blob) {
                            await uploadBytes(storageRef, media).then(
                                (snapshot) => {
                                    console.log("Uploaded a blob or file!");
                                    getDownloadURL(snapshot.ref).then(
                                        (downloadURL) => {
                                            mediaUrl = downloadURL;

                                            console.log(
                                                "DEBUG mediaUrl",
                                                mediaUrl
                                            );
                                            if (mediaUrl === null) {
                                                throw new Error();
                                            }

                                            eventMedia.push(mediaUrl);

                                            if (
                                                eventMedia.length ===
                                                eventData.eventMedia.length
                                            )
                                                resolve();
                                        }
                                    );
                                }
                            );
                        } else {
                            await uploadString(
                                storageRef,
                                media,
                                "data_url"
                            ).then((snapshot) => {
                                console.log("Uploaded data url!");
                                getDownloadURL(snapshot.ref).then(
                                    (downloadURL) => {
                                        mediaUrl = downloadURL;

                                        console.log("DEBUG mediaUrl", mediaUrl);
                                        if (mediaUrl === null) {
                                            throw new Error();
                                        }

                                        eventMedia.push(mediaUrl);

                                        if (
                                            eventMedia.length ===
                                            eventData.eventMedia.length
                                        )
                                            resolve();
                                    }
                                );
                            });
                        }
                    });
                });
            }
            event.eventMedia = eventMedia;
        }

        updateDoc(doc(db, "events", eventId), event).then(() => {
            sendNotification(fcmData.userIds, fcmData.userNotification, {
                type: "EVENT",
                eventId,
            });
            sendNotification(fcmData.hostIds, fcmData.hostNotification, {
                type: "HOST",
                eventId,
            });
            if ("userChangeIds" in fcmData && "eventNotification" in fcmData) {
                sendNotification(
                    fcmData.userChangeIds,
                    fcmData.eventNotification,
                    {
                        type: "EVENT",
                        eventId,
                    }
                );
            }

            if (recurringId && recurringId !== "" && eventData.eventEnd) {
                updateDoc(doc(db, "recurring_events", recurringId), {
                    nextEventCreateTime: event.eventEnd,
                });
            }

            readEvent(eventId, navigate);
            clear();
        });

        if (eventData.eventTitle || eventData.eventMedia) {
            const eventTickets = {};
            if (eventData.eventTitle)
                eventTickets.ticketTitle = event.eventTitle;
            if (eventData.eventMedia && eventData.eventMedia.length > 0)
                eventTickets.ticketMedia = event.eventMedia[0];

            getDocs(
                query(
                    collection(db, "tickets"),
                    where("ticketEvent", "==", eventId)
                )
            ).then((querySnapshot) => {
                const batch = writeBatch(db);
                querySnapshot.forEach((documentSnapshot) => {
                    batch.update(
                        doc(db, "tickets", documentSnapshot.id),
                        eventTickets
                    );
                });
                batch.commit();
            });
        }
        console.log("DEBUG edit event COMPLETE");
    };

    const addEventCodeToUser = (userId, eventId) => {
        const userRef = doc(db, "users", userId);
        updateDoc(userRef, { userEventsAttended: arrayUnion(eventId) })
            .then(() => {})
            .catch((error) => {
                console.log(
                    `addEventCodeToUser = (${userId}, ${eventId} => ${error})`
                );
            });
    };

    return (
        <CreateEventContext.Provider
            value={{
                createEvent,
                addEventCodeToUser,
                eventId,
                eventRef,
                recurringEventId,
                media,
                setMedia,
                description,
                onChangeDescription,
                name,
                onChangeName,
                start,
                setStart,
                end,
                setEnd,
                recurringInterval,
                setRecurringInterval,
                address,
                setAddress,
                coordinates,
                setCoordinates,
                personnel,
                setPersonnel,
                artists,
                setArtists,
                ticketScanners,
                setTicketScanners,
                invitedGuests,
                setInvitedGuests,
                tickets,
                setTickets,
                link,
                setLink,
                eventOtherLinks,
                setEventOtherLinks,
                privacy,
                onChangePrivacy,
                canInvite,
                setCanInvite,
                hideGuests,
                setHideGuests,
                hideHosts,
                setHideHosts,
                announce,
                onChangeAnnounce,
                gradientIndex,
                setGradientIndex,
                uiCompleted,
                setUiCompleted,
                clear,
                setEvent,
                editEventSet,
                updateEvent,
            }}
        >
            {props.children}
        </CreateEventContext.Provider>
    );
}

export { CreateEventContext, CreateEventProvider };
