// GuestToGuestTooltip.js
import React from "react";
import { View, Text } from "react-native";
import DisabledSwitch from "../../../../components/buttons/DisabledSwitch";
import { charcoal, white, black } from "../../../../utils/color";

import style from "./style";

const GuestToGuestTooltip = ({ theme }) => (
  <View style={{ backgroundColor: theme === "dark" ? charcoal : white }}>
    <Text style={[style.header, { color: theme === "dark" ? white : black }]}>
      Guest-to-Guest Invitations
    </Text>
    <Text style={[style.text, { color: theme === "dark" ? white : black }]}>
      Allows guests to invite their friends in-app, with event link, or with event code
    </Text>
    <View style={[style.collection, { marginTop: 25 }]}>
      <DisabledSwitch />
    </View>
  </View>
);

GuestToGuestTooltip.defaultProps = {};

GuestToGuestTooltip.propTypes = {};

export default React.memo(GuestToGuestTooltip);
